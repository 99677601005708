import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { fetchData } from './../requests/api_calls';
import format from 'string-format';
import { DataLoadingSpinner } from './../components/spinners';
import * as settings from './../scripts/settings';
import E911Modal from './profile/modals/e911Modal';

class ProfileInfo extends Component {

  constructor(props) {
    super(props);
    this.state = this.initialState(props)
  }

  initialState = (props) => {
    return {
      fetch_error: "",
      loading: true,
      profile: {},
      isIpTrunking: props && props.isIpTrunking ? props.isIpTrunking : false,
      hasE911: true
    };
  }
  componentDidMount() {
    this.fetchProfileInfo(this.getProfileApiUrl(), settings.noResultsMsg);
  }

  componentDidUpdate(prevProps) {
    if (this.props.ServiceNumber !== prevProps.ServiceNumber ||
      this.props.PBXUserID !== prevProps.PBXUserID) {
      this.fetchProfileInfo(this.getProfileApiUrl(prevProps), settings.noResultsMsg);
    }
  }

  fetchProfileInfo = (apiPath, errorMsg) => {
    this.setState(this.initialState(this.props));
    fetchData(apiPath).then((data) => {
      if (data !== null && data !== undefined) {
        this.loadData(data);
        this.setState({ loading: false });
      }
      else {
        this.setState({ profile: {}, loading: false });
      }
    }).catch((message) => {
      this.setState({ ...{ loading: false, fetch_error: message.toString() } });
    });
  }

  loadData = (data) => {
    let b911 = false;
    if (data.HasE911 && data.HasE911.toString() === 'true')
      b911 = true;
    this.setState({ ...{ profile: data, loading: false, hasE911: b911 } });
  }

  render() {
    let profileURL = '/profile/';
    if (this.state.profile.ServiceNumber && this.state.profile.ServiceNumber !== '')
      profileURL += this.state.profile.ServiceNumber;
    else
      profileURL += "%20";

    if (this.state.profile.PBXUserId && this.state.profile.PBXUserId !== '')
      profileURL += '/' + this.state.profile.PBXUserId;

    if (this.state.loading) {
      return (
        <div className="profile card">
          <div className="card-header">
            <div className='card-actions'><div className='move'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m512 256c0 5-2 9-5 13l-74 73c-3 4-7 5-12 5-5 0-10-1-13-5-4-4-6-8-6-13l0-36-109 0 0 109 36 0c5 0 9 2 13 6 4 3 5 8 5 13 0 5-1 9-5 12l-73 74c-4 3-8 5-13 5-5 0-9-2-13-5l-73-74c-4-3-5-7-5-12 0-5 1-10 5-13 4-4 8-6 13-6l36 0 0-109-109 0 0 36c0 5-2 9-6 13-3 4-8 5-13 5-5 0-9-1-12-5l-74-73c-3-4-5-8-5-13 0-5 2-9 5-13l74-73c3-4 7-5 12-5 5 0 10 1 13 5 4 4 6 8 6 13l0 36 109 0 0-109-36 0c-5 0-9-2-13-6-4-3-5-8-5-13 0-5 1-9 5-12l73-74c4-3 8-5 13-5 5 0 9 2 13 5l73 74c4 3 5 7 5 12 0 5-1 10-5 13-4 4-8 6-13 6l-36 0 0 109 109 0 0-36c0-5 2-9 6-13 3-4 8-5 13-5 5 0 9 1 12 5l74 73c3 4 5 8 5 13z' transform='scale(0.046875 0.046875)'></path></svg></div><div className='expand'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m26 344l-26 158c0 2 1 5 3 7c0 0 0 0 0 0c2 2 5 3 7 3l158-27c3 0 6-3 7-6c1-3 0-7-3-9l-30-31l82-82c4-4 4-9 0-13l-57-57c-3-3-9-3-12 0l-83 83l-31-31c-2-2-5-3-9-2c-3 1-5 4-6 7z m460-176l26-158c0-2-1-5-3-7c0 0 0 0 0 0c-2-2-5-3-7-3l-158 27c-3 0-6 3-7 6c-1 3 0 7 3 9l30 31l-82 82c-4 4-4 9 0 13l57 57c3 3 9 3 12 0l83-83l31 31c2 2 5 3 9 2c3-1 5-4 6-7z' transform='scale(0.046875 0.046875)'></path></svg></div></div>
            <h2 className="move">Profile</h2>
          </div>
          <div className="vm-actions card-content">
            <DataLoadingSpinner className='load spinner pull-left' />
          </div>
        </div>);
    }
    return (
      <div className="profile card">
        <div className="card-header">
          <div className='card-actions'>
            <div className='move'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m512 256c0 5-2 9-5 13l-74 73c-3 4-7 5-12 5-5 0-10-1-13-5-4-4-6-8-6-13l0-36-109 0 0 109 36 0c5 0 9 2 13 6 4 3 5 8 5 13 0 5-1 9-5 12l-73 74c-4 3-8 5-13 5-5 0-9-2-13-5l-73-74c-4-3-5-7-5-12 0-5 1-10 5-13 4-4 8-6 13-6l36 0 0-109-109 0 0 36c0 5-2 9-6 13-3 4-8 5-13 5-5 0-9-1-12-5l-74-73c-3-4-5-8-5-13 0-5 2-9 5-13l74-73c3-4 7-5 12-5 5 0 10 1 13 5 4 4 6 8 6 13l0 36 109 0 0-109-36 0c-5 0-9-2-13-6-4-3-5-8-5-13 0-5 1-9 5-12l73-74c4-3 8-5 13-5 5 0 9 2 13 5l73 74c4 3 5 7 5 12 0 5-1 10-5 13-4 4-8 6-13 6l-36 0 0 109 109 0 0-36c0-5 2-9 6-13 3-4 8-5 13-5 5 0 9 1 12 5l74 73c3 4 5 8 5 13z' transform='scale(0.046875 0.046875)'></path></svg></div><div className='expand'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m26 344l-26 158c0 2 1 5 3 7c0 0 0 0 0 0c2 2 5 3 7 3l158-27c3 0 6-3 7-6c1-3 0-7-3-9l-30-31l82-82c4-4 4-9 0-13l-57-57c-3-3-9-3-12 0l-83 83l-31-31c-2-2-5-3-9-2c-3 1-5 4-6 7z m460-176l26-158c0-2-1-5-3-7c0 0 0 0 0 0c-2-2-5-3-7-3l-158 27c-3 0-6 3-7 6c-1 3 0 7 3 9l30 31l-82 82c-4 4-4 9 0 13l57 57c3 3 9 3 12 0l83-83l31 31c2 2 5 3 9 2c3-1 5-4 6-7z' transform='scale(0.046875 0.046875)'></path></svg></div></div>
          {/* {<TC_MODAL/>} */}
          <h2 className="move name">{this.getName()}</h2>
        </div>
        <div className="user-info card-content">

          <div className="card-content">

            {this.props.IsAdmin ? this.getAdminProfile() : this.getRegularProfile()}

            <hr />
            <div className="actions">
              <Link to={profileURL} className="btn">View Profile</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getRegularProfile() {
    let profile = "";
    profile = (<div className="item">
      <div className="company">{this.getCompany()}</div>
      <div className="title">{this.state.profile.Designation}</div>
      <div className="address">
        <address>{this.state.profile.E911Street1} {this.state.profile.E911Street2} <br /> {this.state.profile.E911City}, {this.state.profile.E911State} {this.state.profile.E911Zip}</address>
      </div>
    </div>);

    let department = "";
    department = (
      <div className="item">
        <div className="department form-group">
          <b>Department</b>
          <div className="value">{this.state.profile.Department}</div>
        </div>
      </div>);

    let serviceNumber = "";
    serviceNumber = (
      <div className="item">
        <div className="service-number form-group">
          <b>Service Number:</b>
          <div className="value">{this.state.profile.ServiceNumber}<span className="ext">Ext. {this.state.profile.Extension}</span></div>
        </div>
      </div>);

    let email = "";
    email = (
      <div className="item">
        <div className="email form-group">
          <b>Email:</b>
          <div className="value">{this.state.profile.Email}</div>
        </div>
      </div>);

    let userid = "";
    // if(this.state.profile.PBXUserId)
    userid = (
      <div className="item">
        <div className="user-id form-group">
          <b>User ID:</b>
          <div className="value">{this.state.profile.PBXUserId}</div>
        </div>
      </div>);

    let enterpriseID = ""
    // if(this.state.profile.ParentAccountNumber)
    enterpriseID = (
      <div className="item">
        <div className="enterprise-id form-group">
          <b>Enterprise ID:</b>
          <div className="value green">{this.state.profile.ParentAccountNumber}</div>
        </div>
      </div>);

    let groupID = ""
    // if(this.state.profile.LocationAccountNumber)
    groupID = (
      <div className="item">
        <div className="group-id form-group">
          <b>Group ID:</b>
          <div className="value green">{this.state.profile.LocationAccountNumber}</div>
        </div>
      </div>);

    let emapID = ""
    // if(this.state.profile.ImapID)
    if (this.state.isIpTrunking === false)
      emapID = (
        <div className="item">
          <div className="imap-id form-group">
            <b>IMAP ID:</b>
            <div className="value green">{this.state.profile.ImapID}</div>
          </div>
        </div>);


    let networkClass = ""
    // if(this.state.profile.ImapID)
    if (this.state.isIpTrunking === false)
      networkClass = (
        <div className="item">
          <div className="network-class-id form-group">
            <b>Network Class of Service:</b>
            <div className="value green">{this.state.profile.NetworkCOS}</div>
          </div>
        </div>);

    let portalLoginUsername = "";
    // if(this.state.profile.PortalUsername)
    portalLoginUsername = (
      <div className="item">
        <div className="portal-login-username form-group">
          <b>Portal Login Username:</b>
          <div className="value">{this.state.profile.PortalUsername}</div>
        </div>
      </div>);

    let groupEmailMobilePagerYahooId = "";
    // if(this.state.profile.Email ||
    //  this.state.profile.Mobile  ||
    //  this.state.profile.Pager ||
    //  this.state.profile.YahooId ){
    if (this.state.isIpTrunking === false)
      groupEmailMobilePagerYahooId = (
        <div className="item">
          <div className="form-group">
            <b>Email:</b>
            <div className="value">{this.state.profile.Email}</div>
            <b>Mobile:</b>
            <div className="value">{this.state.profile.Mobile}</div>
            <b>Pager:</b>
            <div className="value">{this.state.profile.Pager}</div>
            <b>YahooId:</b>
            <div className="value">{this.state.profile.YahooId}</div>
          </div>
        </div>);
    // }


    let e911AddressSection = ""
    // if(this.state.profile.E911Street1 ||
    //  this.state.profile.E911Street2 ||
    //  this.state.profile.E911City ||
    //  this.state.profile.E911State ||
    //  this.state.profile.E911Zip)
    e911AddressSection = (
      <div className="item" style={{ display: this.state.hasE911 ? 'block' : 'none' }}>
        <div className="e911-address form-group">
          <b>E911 Address:</b>
          <div className="value green">{this.state.profile.E911Street1} {this.state.profile.E911Street2} <br />
            {this.state.profile.E911City}, {this.state.profile.E911State} {this.state.profile.E911Zip}
          </div>
        </div>
      </div>);


    let location = "";
    // if(this.state.profile.Location)
    location = (
      <div className="item">
        <div className="location form-group">
          <b>Location:</b>
          <div className="value">{this.state.profile.Location}</div>
        </div>
      </div>);


    let phoneModel = "";
    // if(this.state.profile.Location)
    phoneModel = (
      <div className="item">
        <div className="phone-model form-group">
          <b>Phone Model:</b>
          <div className="value">{this.state.profile.DeviceModel}</div>
        </div>
      </div>);


    let macAddress = "";
    // if(this.state.profile.DeviceMAC)
    macAddress = (
      <div className="item">
        <div className="mac-address form-group">
          <b>Mac Address:</b>
          <div className="value green">{this.state.profile.DeviceMAC}</div>
        </div>
      </div>);

    let language = "";
    // if(this.state.profile.Language)
    if (this.state.isIpTrunking === false)
      language = (
        <div className="item">
          <div className="mac-address form-group">
            <b>Language:</b>
            <div className="value green">{this.state.profile.Language}</div>
          </div>
        </div>);

    let timezone = "";
    // if(this.state.profile.TimeZone)
    timezone = (
      <div className="item">
        <div className="mac-address form-group">
          <b>TimeZone:</b>
          <div className="value green">{this.state.profile.TimeZone}</div>
        </div>
      </div>);

    let temporarilyUpdate911ServiceAddress = "";
    // if(this.state.profile.Email)
    temporarilyUpdate911ServiceAddress = (
      <div className="item">
        <div className="update-911-service-address form-group">
          <E911Modal showUser911={true} ServiceNumber={this.state.profile.ServiceNumber} AccountNumber={this.state.profile.LocationAccountNumber} labelClassName="font-bold" />
        </div>
      </div>);

    let passwordRecovery = "";
    passwordRecovery = (
      <div className="item">
        <div className="mac-address form-group">
          <b>Password Recovery Email:</b>
          <div className="value green">{this.state.profile.Email}</div>
          {/* <div className="value green"><a href="#">Change Recovery Email</a></div>
          <div className="value green"><a href="#">Change Password:</a></div> */}
        </div>
      </div>);

    return (
      <div className="items">
        {profile}
        {userid}
        {enterpriseID}
        {department}
        {groupID}
        {serviceNumber}
        {emapID}
        {portalLoginUsername}
        {networkClass}
        {groupEmailMobilePagerYahooId}
        {e911AddressSection}
        {location}
        {phoneModel}
        {macAddress}
        {language}
        {timezone}
        {temporarilyUpdate911ServiceAddress}
        {passwordRecovery}
      </div>);
  }

  getRegularProfile_bk() {


    let department = "";
    // if(this.state.profile.Location)
    department = (
      <div className="item">
        <div className="location form-group">
          <b>Department :</b>
          <div className="value">{this.state.profile.Department}</div>
        </div>
      </div>);

    let location = "";
    // if(this.state.profile.Location)
    location = (
      <div className="item">
        <div className="location form-group">
          <b>Location:</b>
          <div className="value">{this.state.profile.Location}</div>
        </div>
      </div>);

    let serviceNumber = "";
    // if(this.state.profile.ServiceNumber)
    serviceNumber = (
      <div className="item">
        <div className="service-number form-group">
          <b>Service Number:</b>
          <div className="value">{this.state.profile.ServiceNumber}<span className="ext">Ext. {this.state.profile.Extension}</span></div>
        </div>
      </div>);

    let userID = "";
    // if(this.state.profile.ServiceNumber)
    userID = (
      <div className="item">
        <div className="service-number form-group">
          <b>User ID:</b>
          <div className="value"></div>
        </div>
      </div>);


    let portalLoginUsername = "";
    // if(this.state.profile.PortalUsername)
    portalLoginUsername = (
      <div className="item">
        <div className="portal-login-username form-group">
          <b>Portal Login Username:</b>
          <div className="value">{this.state.profile.PortalUsername}</div>
        </div>
      </div>);

    let phoneModel = "";
    // if(this.state.profile.Location)
    phoneModel = (
      <div className="item">
        <div className="phone-model form-group">
          <b>Phone Model:</b>
          <div className="value">{this.state.profile.DeviceModel}</div>
        </div>
      </div>);


    let macAddress = "";
    // if(this.state.profile.DeviceMAC)
    macAddress = (
      <div className="item">
        <div className="mac-address form-group">
          <b>Mac Address:</b>
          <div className="value green">{this.state.profile.DeviceMAC}</div>
        </div>
      </div>);

    let timezone = "";
    // if(this.state.profile.TimeZone)
    timezone = (
      <div className="item">
        <div className="mac-address form-group">
          <b>TimeZone:</b>
          <div className="value green">{this.state.profile.TimeZone}</div>
        </div>
      </div>);

    let passwordRecovery = "";
    passwordRecovery = (
      <div className="item">
        <div className="mac-address form-group">
          <b>Password Recovery Email:</b>
          <div className="value green">{this.state.profile.Email}</div>
        </div>
      </div>);

    return (
      <div className="items">
        {department}
        {location}
        {serviceNumber}
        {userID}
        {portalLoginUsername}
        {phoneModel}
        {macAddress}
        {timezone}
        {passwordRecovery}
      </div>);
  }


  getAdminProfile() {
    let profile = "";
    profile = (<div className="item">
      <div className="company">{this.getCompany()}</div>
      <div className="title">{this.state.profile.Designation}</div>
      <div className="address">
        <address>{this.state.profile.SVCStreet1} {this.state.profile.SVCStreet2} <br /> {this.state.profile.SVCCity}, {this.state.profile.SVCState} {this.state.profile.SVCZip}</address>
      </div>
    </div>);

    let portalLoginUsername = "";
    portalLoginUsername = (
      <div className="item">
        <div className="portal-login-username form-group">
          <b>Portal Login Username:</b>
          <div className="value">{this.state.profile.PortalUsername}</div>
        </div>
      </div>);

    let e911AddressSection = ""
    e911AddressSection = (
      <div className="item">
        <div className="e911-address form-group" style={{ display: this.state.hasE911 ? 'block' : 'none' }}>
          <b>E911 Address:</b>
          <div className="value green">{this.state.profile.E911Street1} {this.state.profile.E911Street2} <br />
            {this.state.profile.E911City}, {this.state.profile.E911State} {this.state.profile.E911Zip}
          </div>
        </div>
      </div>);

    let passwordRecovery = "";
    passwordRecovery = (
      <div className="item">
        <div className="mac-address form-group">
          <b>Password Recovery Email:</b>
          <div className="value green">{this.state.profile.Email}</div>
        </div>
      </div>);

    return (
      <div className="items">
        {profile}
        {portalLoginUsername}
        {e911AddressSection}
        {/* {passwordRecovery} */}
      </div>)
  }

  getName() {
    if (this.state.loading)
      return "";

    if (this.props.IsAdmin) {
      if (this.state.profile.FirstName || this.state.profile.LastName)
        return this.state.profile.FirstName + " " + this.state.profile.LastName;
      else
        return "Admin";
    }
    else {
      if (this.state.profile.FirstName || this.state.profile.LastName)
        return (
          <span>
            {this.state.profile.FirstName + " " + this.state.profile.LastName}&nbsp;
            <small style={{ display: this.state.isIpTrunking === true ? 'none' : 'inline-block' }}>Calling Line ID: {this.state.profile.CallingLineFirstName + " " + this.state.profile.CallingLineLastName}</small>
          </span>
        );
      else {
        return (<span>Not Available</span>)
      }
    }
  }
  getProfileApiUrl = () => {
    let apiUrl = 'usr/UserProfile';

    if (this.props.ServiceNumber && this.props.PBXUserID) {
      apiUrl += "?ServiceNumber={ServiceNumber}&PBXUserID={PBXUserID}";
    }
    else if (this.props.ServiceNumber) {
      apiUrl += "?ServiceNumber={ServiceNumber}";
    }
    else if (this.props.PBXUserID) {
      apiUrl += "?PBXUserID={PBXUserID}";
    }
    return format(apiUrl, this.props);
  }

  getCompany() {
    if (this.props.IsAdmin)
      return (
        <span>
          <div> {this.state.profile.CustomerName}</div>
          <small>Enterprise ID: {this.state.profile.ParentAccountNumber}</small>
          <div>&nbsp;</div>
        </span>
      )
    else {
      return (<span>
        {this.state.profile.CustomerName}
      </span>
      )
    }
  }
}
export default ProfileInfo;