import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AffectedDevicesModal from './components/affeced_devices_modal';
import AffectedServicesModal from './components/affected_services_modal';
import * as apiCalls from '../../requests/api_calls';
import { DataLoadingSpinner } from '../../components/spinners';
import * as helper from './helper/ticket_helper';
import { Modal } from 'react-bootstrap';
import { userIsAdmin } from '../../scripts/helper';

const accountID = '0010h00001XhZxcAAF';

class NewTicket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      TicketSubject: '',
      TicketDescription: '',
      SelectedLocation: '',
      SelectedContact: '',
      SelectedPriority: 'Minor',
      SelectedCategory: '',
      SelectedSubCategory: '',
      SelectedOtherCategory: '',
      SelectedCommMethod: '',
      submittedTicket: false,
      ticketSubFailed: false,
      AffectedDevices: [],
      AffectedServices: [],
      AttachmentList: [],
      fileIndex: 0,
      showConfirmationModal: false
    }
    this.handleCloseConfirmation = this.handleCloseConfirmation.bind(this);
    this.handleCancelTicketYes = this.handleCancelTicketYes.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.hasTicketing !== 'FULL') {
      this.props.history.push('/');
    }
    sessionStorage.removeItem('serviceList');
    sessionStorage.removeItem('deviceList');
    this.fetchTicketData();
  }

  fetchTicketData = () => {
    let requests = [
      this.fetchContactList(),
      this.getCategoryList(),
      this.fetchLocations(),
      this.getPriorityList(),
      this.getCommMethodList(),
    ];
    Promise.all(requests).catch(err => console.error(err));
  }

  getCommMethodList = () => {
    let apiPath = `admin/GetPicklistValues?RecordTypeId=${helper.retailTrouble}&Picklist=COMM_METHOD&ControllerValue=`;
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        let options = data.ValueList.map((item, idx) => {
          return <option key={idx} value={item}>{item}</option>
        });
        this.setState({ CommMethodOptions: options });
      } else {
        this.setState({ CommMethodOptions: [] });
      }
    }).catch(err => console.error(err));
  }

  getPriorityList = () => {
    let apiPath = `admin/GetPicklistValues?RecordTypeId=${helper.retailTrouble}&Picklist=PRIORITY&ControllerValue=`;
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        let options = data.ValueList.map((item, idx) => {
          return <option key={idx} value={item}>{item}</option>
        });
        this.setState({ PriorityOptions: options });
      } else {
        this.setState({ PriorityOptions: [] });
      }
    }).catch(err => console.error(err));
  }

  getCategoryList = () => {
    let apiPath = `admin/GetPicklistValues?RecordTypeId=${helper.retailTrouble}&Picklist=CATEGORY&ControllerValue=`;
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        let options = data.ValueList.filter(option => option !== "Other").map((item, idx) => {
          return <option key={idx} value={item}>{item}</option>
        });
        this.setState({ CategoryOptions: options });
      } else {
        this.setState({ CategoryOptions: [] });
      }
    }).catch(err => console.error(err));
  }

  getSubCategoryList = (categoryValue) => {
    if (categoryValue === '' || categoryValue === 'Other') {
      return;
    } else {
      let apiPath = `admin/GetPicklistValues?RecordTypeId=${helper.retailTrouble}&Picklist=SUB_CATEGORY&ControllerValue=${categoryValue}`;
      apiCalls.fetchData(apiPath).then(data => {
        if (data) {
          let options = data.ValueList.map((item, idx) => {
            return <option key={idx} value={item}>{item}</option>
          });
          this.setState({ SubCategoryOptions: options });
        } else {
          this.setState({ SubCategoryOptions: [] });
        }
      }).catch(err => console.error(err));
    }
  }

  fetchLocations = () => {
    let apiPath = '';
    if (userIsAdmin())
      apiPath = 'admin/Locations';
    else
      apiPath = 'usr/GetUserLocation';
    
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        let options = data.map((location, idx) => {
          return <option key={idx} value={location.LocationAccountNumber}>{location.LocationName}</option>
        });
        if (data.length === 1)
          this.setState({ SelectedLocation: data[0].LocationAccountNumber, LocationOptions: options });
        else
        this.setState({ LocationOptions: options });
      } else {
        this.setState({ LocationOptions: [] });
      }
    }).catch(err => console.error(err));
    
  }

  fetchContactList = () => {
    let apiPath = 'admin/GetContactList';
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        let options = data.map((contact, idx) => {
          return <option key={idx} value={contact.ContactId}>{contact.Name}</option>
        });
        this.setState({ ContactOptions: options });
      } else {
        this.setState({ ContactOptions: [] });
      }
    }).catch(err => console.error(err));
  }

  formGroupDropDown = (labelFor, labelName, val, options) => {
    let setOptions = (options) => {
      if (options) {
        return (
          <select id={labelFor} className='form-control' value={val}
            onChange={(e) => this.handleInputChange(e, labelFor)}
            style={{
              width: '40%',
              display: 'inline-block',
            }}
          >
            <option value=''>Select</option>
            {options}
          </select>
        )
      } else {
        return <DataLoadingSpinner
          style={{
            position: 'relative',
            height: '0px',
            bottom: '20px',
          }}
          className='load spinner'
        />
      }
    }
    return (
      <div className='form-group-wrapper'
        style={{ paddingBottom: '15px' }}
      >
        <label style={{
          width: '45%',
          fontSize: '100%'
        }} htmlFor={labelFor}>{labelName} {labelFor !== 'Priority' && labelFor !== 'CommunicationMethod' ? <span style={{ color: 'red', fontSize: '18px' }}>*</span> : null}:</label>
        {setOptions(options)}
      </div>
    )
  }

  formGroupInput = (labelFor, labelName, val, type = 'text') => {
    return (
      <div className='form-group-wrapper'
        style={{ paddingBottom: '15px' }}
      >
        <label style={{
          width: '45%',
          fontSize: '100%'
        }} htmlFor={labelFor}>{labelName}:</label>
        <input className='form-control'
          style={{
            width: '40%',
            display: 'inline-block'
          }}
          type={type} id={labelFor} name='' value={val}
          onChange={(e) => this.handleInputChange(e, labelFor)}
        />
      </div>
    )
  }

  selectOptions = (options) => {
    return options.map((option, index) => {
      return <option key={index} value={option}>{option}</option>
    })
  }

  handleInputChange = (e, labelFor) => {
    let { value } = e.target;
    switch (labelFor) {
      case 'Location':
        this.setState({ SelectedLocation: value });
        return;
      case 'Contact':
        this.setState({ SelectedContact: value });
        return;
      case 'Priority':
        this.setState({ SelectedPriority: value });
        return;
      case 'Category':
        this.setState({ SubCategoryOptions: undefined })
        this.getSubCategoryList(value);
        this.setState({ SelectedCategory: value });
        return;
      case 'SubCategory':
        this.setState({ SelectedSubCategory: value });
        return;
      // case 'OtherCategory':
      //   this.setState({ SelectedOtherCategory: value });
      //   return;
      case 'CommunicationMethod':
        this.setState({ SelectedCommMethod: value });
      default:
        return;
    }
  }

  handleTicketSubject = (e) => {
    let { value } = e.target;
    this.setState({ TicketSubject: value });
  }

  handleTicketDescription = (e) => {
    let { value } = e.target;
    this.setState({ TicketDescription: value });
  }

  newTicketContent = () => {
    if (this.state.submittedTicket) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      return (
        <div className='main-container'>
          <div className='flex-parent-wrapper'>
            <div className='flex-container-one'>
              {this.formGroupDropDown('Category', 'Category', this.state.SelectedCategory, this.state.CategoryOptions)}
              {(this.state.SelectedCategory === '' || this.state.SelectedCategory === 'Other')
                ? null
                : this.formGroupDropDown(
                  'SubCategory',
                  'Sub Category',
                  this.state.SelectedSubCategory,
                  this.state.SubCategoryOptions
                )}
              {this.formGroupDropDown('Location', 'Location', this.state.SelectedLocation, this.state.LocationOptions)}
              {this.formGroupDropDown('Contact', 'Contact', this.state.SelectedContact, this.state.ContactOptions)}
              {this.formGroupDropDown('Priority', 'Priority', this.state.SelectedPriority, this.state.PriorityOptions)}
              {this.formGroupDropDown('CommunicationMethod', 'Preferred Method of Communication', this.state.SelectedCommMethod, this.state.CommMethodOptions)}
            </div>
            <div className='flex-container-two'>
              {this.state.AffectedDevices.length > 0
                ? <div>
                  <small>{`Affected Devices: ${this.state.AffectedDevices.length}`}</small>
                  <br />
                </div> : null}
              {this.state.AffectedServices.length > 0
                ? <div>
                  <small>{`Affected Services & Users: ${this.state.AffectedServices.length}`}</small>
                  <br />
                </div> : null}
              {this.state.AttachmentList.length > 0
                ? <div>
                  <small>{`Attachments: ${this.state.AttachmentList.length}`}</small>
                  <br />
                </div> : null}
              <h5
                style={{
                  fontWeight: '550'
                }}
              >Subject <span style={{ color: 'red', fontSize: '18px' }}>*</span>:</h5>
              <input
                style={{
                  width: '75%',
                  marginBottom: '5px'
                }}
                htmlFor='ticket-subject'
                onChange={this.handleTicketSubject}
                value={this.state.TicketSubject}
              />
              <h5
                style={{
                  fontWeight: '550'
                }}
              >Description <span style={{ color: 'red', fontSize: '18px' }}>*</span>:</h5>
              <textarea
                style={{
                  display: 'block',
                  width: '75%',
                  height: '250px',
                  resize: 'none'
                }}
                placeholder='Enter ticket description here...'
                onChange={this.handleTicketDescription}
                value={this.state.TicketDescription}
              ></textarea>
              <div className='new-ticket-btns'>
                <button className='btn' style={helper.btnStyles}
                  onClick={this.submitNewTicket}
                >Submit</button>
                <AffectedServicesModal
                  affectedServices={this.storeAffectedServices}
                  btnStyles={helper.btnStyles}
                  saveSelections={true}
                />
                <AffectedDevicesModal
                  affectedDevices={this.storeAffectedDevices}
                  btnStyles={helper.btnStyles}
                  saveSelections={true}
                />
                <button className='btn' style={helper.btnStyles}
                  onClick={this.openAttachments}
                >Add Attachments
                <input
                    type="file"
                    id="file"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    ref='fileUploader'
                    style={{ display: "none" }}
                    onChange={this.addAttachment}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                </button>
                <button className='btn' style={helper.btnStyles}
                  onClick={this.cancelNewTicket}
                >Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  addAttachment = (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    let newFileIndex = this.state.fileIndex + 1;
    reader.addEventListener('loadend', async () => {
      let fileParams = {
        id: newFileIndex,
        body: reader.result.split(',')[1],
        name: file.name
      };
      this.state.AttachmentList.push(fileParams);
      await this.setState({ AttachmentList: this.state.AttachmentList, fileIndex: newFileIndex });
    }, false);
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  createAttachment = async (body, name, ticketId) => {
    let apiPath = 'admin/CreateAttachment';
    let attachmentDTO = {
      TicketId: ticketId,
      AttachmentBody: body,
      FileName: name,
    };
    let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(attachmentDTO)).then(res => res).catch(err => console.error(err));
    if (response.ok) {
      //console.log('res', response);
    } else {
      let msg = JSON.parse(response.message);
      console.log('res error', msg);
    }
  }

  openAttachments = () => {
    this.refs.fileUploader.click();
  }

  storeAffectedDevices = (data) => {
    let devices = data.map(itm => {
      return `MAC: ${itm.MacAddress || ''}\nMake/Model: ${itm.MakeModel || ''}\nAssignedTo: ${itm.AssignedTo || ''}\n\n`;
    });
    this.setState({ AffectedDevices: devices });
  }

  storeAffectedServices = (data) => {
    let services = data.map(itm => {
      let extension = ''
      if (itm.Extension)
        extension = "Extension: " + itm.Extension + '\n';
      else if (itm.Username && itm.ServiceNumber !== itm.Username)
        extension = "Username: " + itm.Username + '\n';
      let serviceID = itm.ServiceNumber ? "ServiceID: " + itm.ServiceNumber + '\n' : ''
      return `ServiceType: ${itm.ServiceType || itm.UserType}\n${serviceID}${extension}\n`;
    });
    this.setState({ AffectedServices: services });
  }

  submitNewTicketComment = async (ticketId) => {
    let apiPath = 'admin/CreateComment';
    let string = '';
    if (this.state.AffectedDevices.length > 0 && this.state.AffectedServices.length > 0) {
      string = `AFFECTED DEVICES COUNT: ${this.state.AffectedDevices.length}\n${this.state.AffectedDevices.join().replace(/,/g, '')} AFFECTED SERVICES & USERS COUNT: ${this.state.AffectedServices.length}\n${this.state.AffectedServices.join().replace(/,/g, '')}`;
    } else if (this.state.AffectedDevices.length > 0 && this.state.AffectedServices.length < 1) {
      string = `AFFECTED DEVICES COUNT: ${this.state.AffectedDevices.length}\n${this.state.AffectedDevices.join().replace(/,/g, '')}`;
    } else {
      string = `AFFECTED SERVICES & USERS COUNT: ${this.state.AffectedServices.length}\n${this.state.AffectedServices.join().replace(/,/g, '')}`
    }
    let commentDTO = {
      CommentId: '',
      TicketId: ticketId,
      CommentBody: string,
    };
    if (this.state.AffectedDevices.length > 0 || this.state.AffectedServices.length > 0) {
      await apiCalls.post(apiPath, 'POST', JSON.stringify(commentDTO)).then(res => res).catch(err => console.error(err));
    }
  }

  submitNewTicket = async () => {
    let {
      TicketSubject,
      TicketDescription,
      SelectedLocation,
      SelectedContact,
      SelectedPriority,
      SelectedCategory,
      SelectedSubCategory,
      SelectedOtherCategory,
      SelectedCommMethod,
      AttachmentList
    } = this.state;
    let apiPath = 'admin/CreateTicket';
    let ticketDTO = {
      AccountId: accountID,
      LocationAccountNumber: SelectedLocation,
      Subject: TicketSubject,
      Description: TicketDescription,
      Category: SelectedCategory,
      SubCategory: SelectedSubCategory,
      OtherCategory: SelectedOtherCategory,
      Priority: SelectedPriority,
      ContactId: SelectedContact,
      PreferredCommunication: SelectedCommMethod,
    };
    await this.setState({ submittedTicket: true, ticketSubFailed: false });
    let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(ticketDTO)).then(res => res).catch(err => console.error(err));
    if (response.ok) {
      let newTicketID = JSON.parse(response.message).ObjectId;
      await this.submitNewTicketComment(newTicketID);
      await AttachmentList.forEach((itm) => {
        this.createAttachment(itm.body, itm.name, newTicketID);
      });
      this.props.history.push(`/ticket/${newTicketID}`);
    } else {
      this.setState({ submittedTicket: false, ticketSubFailed: true });
    }
  }

  cancelNewTicket = () => {
    // let { TicketSubject, TicketDescription, SelectedLocation, SelectedContact,
    //   SelectedPriority, SelectedCategory, SelectedSubCategory, SelectedOtherCategory,
    //   SelectedCommMethod, submittedTicket, ticketSubFailed, AffectedDevices, AffectedServices,
    //   AttachmentList } = this.state;

    // if (TicketSubject === '' && TicketDescription === '' && SelectedLocation === '' && SelectedContact === '' &&
    //   SelectedPriority === '' && SelectedCategory === '' && SelectedSubCategory === '' && SelectedOtherCategory === '' &&
    //   SelectedCommMethod === '' && submittedTicket === '' && ticketSubFailed === '' && AffectedDevices.length < 1 &&
    //   AffectedServices.length < 1 && AttachmentList.length < 1
    // ) {
    //   return;
    // } else {
    //   sessionStorage.removeItem('serviceList');
    //   sessionStorage.removeItem('deviceList');
    //   this.setState({
    //     TicketSubject: '',
    //     TicketDescription: '',
    //     SelectedLocation: '',
    //     SelectedContact: '',
    //     SelectedPriority: 'Minor',
    //     SelectedCategory: '',
    //     SelectedSubCategory: '',
    //     SelectedOtherCategory: '',
    //     SelectedCommMethod: '',
    //     AffectedDevices: [],
    //     AffectedServices: [],
    //     AttachmentList: [],
    //     submittedTicket: false,
    //     ticketSubFailed: false,
    //   });
    // }
    this.setState({showConfirmationModal: true});
  }

  handleCancelTicketYes = () => {
    this.props.history.push('/tickets_all')
  }

  handleCloseConfirmation = () => {
    this.setState({ showConfirmationModal: false });
  }

  attachmentDropdown = () => {
    return (
      <div id="dropdown" className="dropdown">
        <a className="menubtn dropdown-toggle" type="button" id="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          View Attachments
          <span className="caret"></span>
        </a>
        <ul className="dropdown-menu" aria-labelledby="" style={{
          minWidth: '200px',
          fontSize: '12px',
          padding: '10px',
        }}>
          {this.state.AttachmentList.map((file, idx) => {
            return (
              <li id="attachment-list" key={idx}>
                <div>
                  <a
                    tabIndex="0"
                  >{file.name}</a>
                  <a style={{
                    float: 'right',
                    color: 'red',
                  }}
                    onClick={() => this.removeAttachment(file.id)}
                  >x</a>
                </div>
                <hr />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  removeAttachment = (id) => {
    let { AttachmentList } = this.state;
    let filteredList = AttachmentList.filter(itm => itm.id !== id);
    this.setState({ AttachmentList: filteredList });
  }

  render() {
    return (
      <div className='new-ticket-page'>
        <div className="container full-card">
          <div className="ticketing card">
            <div className="card-header">
              <h1>New Ticket <small>Type: Retail Trouble</small> {this.state.ticketSubFailed ?
                <small style={{ color: 'red', fontSize: '50%' }}>Submission Error: Confirm All Required Fields</small>
                : null}</h1>
            </div>
            <div className="card-actions">
              {this.state.AttachmentList.length > 0 ? this.attachmentDropdown() : null}
            </div>
            <div className="ticketing-row"></div>
            <br />
            <div className="card-content">
              <Modal show={this.state.showConfirmationModal} onHide={this.handleClose} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Are you sure you want to cancel this ticket?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <hr />
                    <div className="btns">
                      <span>
                        <button type="button" className="btn" onClick={this.handleCancelTicketYes}>Yes</button>
                        <button className="btn" onClick={this.handleCloseConfirmation}>No</button>
                      </span>
                    </div>
                </Modal.Footer>
              </Modal>
              {this.newTicketContent()}
            </div>
          </div>
        </div>
      </div>
    )
  }
};
export default NewTicket;