import React, { Component } from 'react';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { DataLoadingSpinner } from '../../../components/spinners';
import _ from 'lodash';
import { orderBy } from '@progress/kendo-data-query';
import * as apiCalls from '../../../requests/api_calls';
import { filterBy } from '@progress/kendo-data-query';
import { userIsAdmin } from '../../../scripts/helper';

export default class AffectedServicesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      Services: [],
      FullServices: [],
      showModal: false,
      Sort: [{ field: 'CombinedTypes', dir: "asc" }],
    }

    const selectService = this.selectService.bind(this);


    class SelectServiceCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        let service = this.props.dataItem;
        let { Selected } = service;
        return (
          <td>
            <input type='checkbox' checked={Selected === true ? true : false} onChange={(e) => selectService(e, service)} />
          </td>
        )
      }
    }

    class ExtensionCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        let service = this.props.dataItem
        let { Extension, Username } = service;
        return (
          <td>
            {Extension ? Extension : Username}
          </td>
        )
      }
    }
    this.SelectServiceCell = SelectServiceCell;
    this.ExtensionCell = ExtensionCell;
  }

  fetchServiceList = async () => {
    await this.setState({ loading: true });
    let apiPath = 'admin/Services';
    apiCalls.fetchData(apiPath).then(async data => {
      if (data) {
        data.forEach((itm) => {
          itm.CombinedTypes = itm.ServiceType ? itm.ServiceType : itm.UserType;
        });
        this.setState({
          Services: data,
          FullServices: data,
          loading: false
        });
      } else {
        this.setState({ loading: false });
      }
    }).catch(err => console.error(err));
  }

  handleClose = () => {
    this.setState({ showModal: false });
  }

  handleShow = async () => {
    await this.setState({ showModal: true });
    let { serviceList } = sessionStorage;
    if (serviceList) {
      let savedServices = JSON.parse(serviceList);
      this.setState({ Services: savedServices, FullServices: savedServices });
    } else {
      this.fetchServiceList();
    }
  }

  selectService = async (e, productItem) => {
    let selectedValue = e.target.checked;
    let serviceList = this.state.Services.slice();
    productItem.Selected = selectedValue;
    let item = serviceList.filter(itm => {
      if (itm.ServiceType) {
        return (itm.ServiceNumber === productItem.ServiceNumber && itm.ServiceType === productItem.ServiceType && itm.ServiceId === productItem.ServiceId);
      } else {
        return (itm.UserId === productItem.UserId && itm.UserType === productItem.UserType);
      }
    })[0];
    let idx = _.indexOf(serviceList, item);
    serviceList[idx] = productItem;
    await this.setState({ Services: serviceList });
  }

  sortChange = (event) => {
    this.setState({
      Services: orderBy(this.state.Services, event.sort),
      Sort: event.sort
    });
  }

  gridContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      if (this.state.Services && this.state.Services.length > 0) {
        return (
          <Grid
            style={{
              maxHeight: '400px',
              overflow: 'auto'
            }}
            data={orderBy(this.state.Services, this.state.Sort)}
            sortable={{ allowUnsort: true, mode: 'single' }}
            sort={this.state.Sort}
            onSortChange={this.sortChange}
          >
            <Column width='80px' field='Selected' title='Select' cell={this.SelectServiceCell} filterable={false} />
            <Column width='200px' field='CombinedTypes' title='Service Type' />
            <Column width='200px' field='ServiceNumber' title='Service ID' />
            <Column width='200px' field='Extension' title='Extension' cell={this.ExtensionCell} />
            <Column title=' ' field='filler' sortable={false} filterable={false} />
          </Grid>
        )
      }
      if (this.state.Services && this.state.Services.length === 0) {
        return <p style={{ textAlign: 'center' }}>No Available Services & Users</p>
      }
    }
  }

  assignServices = () => {
    let serviceList = this.state.Services.filter(itm => itm.Selected === true);
    this.props.affectedServices(serviceList);
    if (this.props.saveSelections) {
      sessionStorage.setItem('serviceList', JSON.stringify(this.state.Services));
    }
    this.handleClose();
  }

  searchListings = (event) => {
    let searchText = event.target.value.trim();
    let fullDetails = this.state.FullServices;
    if (searchText !== '') {
      searchText = event.target.value;
      let services = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'CombinedTypes', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'ServiceNumber', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Extension', operator: 'contains', value: searchText, ignoreCase: true },
        ]
      });
      this.setState({ Services: services })
    }
    else {
      this.setState({ Services: fullDetails });
    }
  }

  render() {
    if (userIsAdmin())
    {
      return (
        <span>
          <button className='btn' onClick={this.handleShow} style={this.props.btnStyles}>Add Affected Services & Users</button>

          <Modal
            dialogClassName="modal-class"
            show={this.state.showModal}
            onHide={this.handleClose}
            bsSize="large"
          >
            <Modal.Header closeButton closeLabel="test test test test est test ets estse">
              <Modal.Title>Add Affected Services & Users</Modal.Title>
              {this.state.loading ? null
                : <div className="search">
                  <label htmlFor="fav_services_srch_tb" className="hidden">favorite services search</label>
                  <input id="fav_services_srch_tb" type="text" autoComplete={"off"} className="form-control" onChange={this.searchListings} />
                  <svg width="12" height="12" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
                </div>}
            </Modal.Header>
            <Modal.Body>
              <div>
                {this.gridContent()}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <hr />
              {!this.state.loading ? <div className="btns">
                <Button className="btn"
                  click={this.assignServices}
                >Add as Comment</Button>
                <Button className="btn"
                  click={this.handleClose}
                >Close</Button>
              </div> : null}
            </Modal.Footer>
          </Modal>
        </span>
      )
    }
    return null;
  }
};