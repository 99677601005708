import * as config from "./config";
import * as settings from "./../scripts/settings";
import * as actions from "./../store/actions";
import { store } from '../store/store';
import * as session from '../scripts/session';

export const customFetch = async (apiPath, customHeaders, isJson = false ) => 
{
  
  return await fetch(apiPath, {method: "GET", headers: customHeaders})
    .then(response => {
      if (response.ok) {
        return isJson ? response.json() : response.text();
      } else {
        logResponseToConsole(response, apiPath);
        return null;
      }
    })
    .catch(error => {
      console.log("error: " + error);
      return Promise.reject(error);
    });
}

export const fetchData = async (apiPath, useHeaderWithTokenPolicy = false, autoRetry = true) => {
 
 
  if (!session.tokenIsValid()) {
    store.dispatch({type: actions.UPDATE_TOKEN_STATUS, tokenIsValid: false});
    return;
  }
  if (useHeaderWithTokenPolicy) {
    
    const response = await checkForToken();
    
    if (response === "timeout") {
      return null;
    }
  }

  return await fetch(config.baseApiUrl + apiPath, {
    headers: useHeaderWithTokenPolicy
      ? headersWithTokenAndPolicy()
      : config.headers()
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else if (response.status === settings.unauthorized) {
        return autoRetry ? 
          refreshTokenAndRetry(apiPath) :
          null;
      } else {
        logResponseToConsole(response, apiPath);
        return null;
      }
    })
    .catch(error => {
      console.log("error: " + error);
      return Promise.reject(error);
    });
};

const logResponseToConsole = (response, apiPath) => {
  if (response.status !== 404) {
    console.log("response: " + response.status);
    console.log("API path: " + apiPath);
    console.log(response);
  }
};

const headersWithTokenAndPolicy = () => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.storedAccessToken(),
      'X-Policy': config.storedXPolicy()
  };
};

export const postData = async (apiPath, body, headers = {
    'Content-Type': 'application/x-www-form-urlencoded', 
    'Accept': 'application/json'
  } ) => {
  if (!session.tokenIsValid()) {
    store.dispatch({type: actions.UPDATE_TOKEN_STATUS, tokenIsValid: false});
    return;
  }
  return await fetch(config.baseApiUrl + apiPath, {
    method: "POST",
    body: JSONToFormUrlEndcodedString(body),
    headers: headersWithTokenAndPolicy()
  })
    .then(response => {
      return response.text().then(e => {
        return Promise.resolve({ ok: response.ok, message: e });
      });
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const postDataWithCustomHeader = async (apiPath, body, headers = null ) => {
  if (!session.tokenIsValid()) {
    store.dispatch({type: actions.UPDATE_TOKEN_STATUS, tokenIsValid: false});
    return;
  }
  return await fetch(config.baseApiUrl + apiPath, {
    method: "POST",
    body: JSONToFormUrlEndcodedString(body),
    headers: headers ? headers : headersWithTokenAndPolicy()
  }).then( response => {
    return response.text().then(e=>{
      return Promise.resolve({ok: response.ok, message: e});
    });
  }).catch(error => {
    return Promise.reject(error);
  });
}

export const post = async (apiPath, method, data) => {
  //return await fetch("https://localhost:44330/api/" + apiPath, {
  if (!session.tokenIsValid()) {
    store.dispatch({type: actions.UPDATE_TOKEN_STATUS, tokenIsValid: false});
    return;
  }
  return await fetch(config.baseApiUrl + apiPath, {
    method: method,
    body: data,
    headers: headersWithTokenAndPolicy()
  }).then( response => {
    return response.text().then(e=>{
      return Promise.resolve({ok: response.ok, message: e});
    });
  }).catch(error => {
    return Promise.reject(error);
  });
}

export const postSubmit = async (apiPath, body, includeToken = true, headers = null ) => {
  let defaultSubmitHeader = {
    'Content-Type': 'application/x-www-form-urlencoded', 
    'Accept': 'text/html'
  };
  if (!session.tokenIsValid()) {
    store.dispatch({type: actions.UPDATE_TOKEN_STATUS, tokenIsValid: false});
    return;
  }
  let postHeader = {};  
  let formHeader = headers ? headers : defaultSubmitHeader;
  if (includeToken)
    postHeader = {
      "Accept": formHeader.Accept,
      "Content-Type": formHeader["Content-Type"],
      Authorization: "Bearer " + config.storedAccessToken(),
      'X-Policy': config.storedXPolicy()
    };
  else
    postHeader = {
      Accept: formHeader.Accept,
      "Content-Type": formHeader["Content-Type"]
    };

  //postHeader = JSON.stringify(postHeader);
  return await fetch(config.baseApiUrl + apiPath, {
    method: "POST",
    body: JSONToFormUrlEndcodedString(body),
    headers: postHeader
  })
    .then(response => {
      return response.text().then(e => {
        return Promise.resolve({ ok: response.ok, message: e });
      });
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const send = async (apiPath, method, data) => {
  if (!session.tokenIsValid()) {
    store.dispatch({type: actions.UPDATE_TOKEN_STATUS, tokenIsValid: false});
    return;
  }
  return await fetch(config.baseApiUrl + apiPath, {
    method: method,
    body: data,
    headers: headersWithTokenAndPolicy()
  })
    .then(response => {
      return response.text().then(e => {
        return Promise.resolve({ ok: response.ok, message: e });
      });
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const JSONToFormUrlEndcodedString = obj => {
  let formBody = [];
  for (let property in obj) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(obj[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  return formBody.join("&");
};

export const saveFavoriteService = async item => {
  if (!session.tokenIsValid()) {
    store.dispatch({type: actions.UPDATE_TOKEN_STATUS, tokenIsValid: false});
    return;
  }
  let favService = JSON.stringify({ ServiceId: item.ServiceId });
  return await fetch(config.baseApiUrl + "admin/FavoriteServices", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + config.storedAccessToken(),
      'X-Policy': config.storedXPolicy(),
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: favService
  }).then(response => {
    if (response.ok) {
      return response.json();
    } else {
      console.log("statusText", response.statusText);
    }
  });
};

export const unfavoriteService = async item => {
  if (!session.tokenIsValid()) {
    store.dispatch({type: actions.UPDATE_TOKEN_STATUS, tokenIsValid: false});
    return;
  }
  let params = "?id=" + item.FavoriteServiceId;
  return await fetch(config.baseApiUrl + "admin/FavoriteServices" + params, {
    method: "DELETE",
    headers: config.headers()
  }).then(response => {
    if (response.ok) {
      return response.json();
    } else {
      console.log("statusText", response.statusText);
    }
  });
};

export const refreshRCListForMACDConfig = async (gridItem, selectedState) => {
  if (!session.tokenIsValid()) {
    store.dispatch({type: actions.UPDATE_TOKEN_STATUS, tokenIsValid: false});
    return;
  }
  return await fetch(
    config.baseApiUrl + "admin/macd/GetRCListByState?RCState=" + selectedState,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + config.storedAccessToken(),
        'X-Policy': config.storedXPolicy(),
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    }
  ).then(response => {
    if (response.ok) {
      return response.json();
    } else {
      console.log("statusText", response.statusText);
    }
  });
};

const refreshTokenAndRetry = async apiPath => {
  requestNewToken();
  return checkForNewToken()
    .then(response => {})
    .then(data => {
      return fetchData(apiPath);
    });
};

const requestNewToken = () => {
  // update redux store to show a token request is pending
  let action = { type: actions.REFRESH_TOKEN };
  store.dispatch(action);
};

const checkForNewToken = apiPath => {
  return new Promise(resolve => tokenCheckInterval(resolve));
};

const tokenCheckInterval = resolve => {
  let i = 0;
  let expiredToken = sessionStorage.accessToken;
  let interval = setInterval(() => {
    i++;
    if (expiredToken !== sessionStorage.accessToken) {
      console.log("token updated");
      clearInterval(interval);
      resolve("ok");
    } else if (i > 60) {
      // timeout after 1 minute
      // console.log('token request timed out');
      clearInterval(interval);
      resolve("abort");
    }
  }, 1000);
};

export const checkForToken = (token = 'accessToken') => {
  const accessTokenPromise = new Promise(async resolve => {
    // await sessionStorage.accessToken;
    await sessionStorage[token];
    resolve("ok");
  });
  const timeout = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve("timeout");
    }, 1000 * 60);
  });

  return Promise.race([accessTokenPromise, timeout]);
};
