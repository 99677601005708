import React, { Component, Fragment } from 'react';
import * as icons from '../../scripts/icons';
import * as helper from '../../scripts/helper';
import * as apiCalls from '../../requests/api_calls';
import * as config from '../../requests/config';
import * as settings from '../../scripts/settings';
import { DataLoadingSpinner } from '../../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import { filtercell } from '../../components/dropdownFilter';
import _ from 'lodash';
import { filterList } from '../../constants/filters';
import { headerToolTip } from '../../components/ellipsisHeaderTooltip';

export default class AllTickets extends Component {
  constructor() {
    super();

    this.state = {
      gridHeight: (window.innerHeight - 210),
      loading: false,
      Listings: [],
      FullListings: [],
      Sort: [{ field: "TicketNumber", dir: "desc" }],
      filter: { logic: "and", filters: [] },
      LocationAccountNumber: '',
      ContactId: '',
      Filter: 'NONE',
      Term: '',
      QueryLocator: ''
    }

    const transitionToDetailPage = this.transitionToDetailPage.bind(this);
    const favoriteTicket = this.favoriteTicket.bind(this);
    const starClass = this.starClass.bind(this);
    const handleClearFilter = this.handleClearFilter.bind(this);

    class FavoriteButton extends GridCell {
      render() {
        let ticket = this.props.dataItem;
        let cannotFavorite = ticket.TicketId == "" && ticket.TicketId == "";
        if (cannotFavorite) {
          return <td></td>
        } else {
          const cursorType = ticket.isTogglingStatus === true ? 'wait' : 'pointer';
          return (
            <td>
              <a
                className={starClass(ticket)}
                key={ticket.TicketId}
                onClick={(e) => favoriteTicket(ticket)}
                style={{ cursor: cursorType }}
              >
                <svg tabIndex="0" width="15" height="15" viewBox="0 0 24 24"
                >
                  <path d="m480 192l-157-20-67-140-67 140-157 20 115 104-29 152 138-74 139 74-30-152z" transform="scale(0.046875 0.046875)"></path>
                </svg>
              </a>
            </td>
          )
        }
      }
    }

    class ClearFilterCell extends React.Component {
      constructor(props) {
        super(props);
      }
      render() {
        return (
          <a onClick={handleClearFilter} className="no-btn">Clear Filters</a>
        );
      }
    };

    class DataLink extends GridCell {

      render() {
        let { TicketNumber, TicketId } = this.props.dataItem;
        return (
          <td>
            <a
              className='no-btn'
              onClick={() => transitionToDetailPage(TicketId)}
            >{TicketNumber}</a>
          </td>
        )

      }
    }

    class IconCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        let { TicketId } = this.props.dataItem;
        return (
          <td>
            <a
              onClick={() => transitionToDetailPage(TicketId)}
              className="icon btn">
              <svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg>
            </a>
          </td>
        )
      }
    }
    this.IconCell = IconCell;
    this.favBtn = FavoriteButton;
    this.ticketNumberCell = DataLink;
    this.ClearFilterCell = ClearFilterCell;
  }

  componentDidMount() {
    if (sessionStorage.hasTicketing === 'NONE') {
      this.props.history.push('/');
    }
    this.fetchTicketList();
    setTimeout(() => {
      sessionStorage.removeItem('ticketView');
    }, 2000);
  }

  applyTicketViewFilter = () => {
    let { ticketView } = sessionStorage;
    if (ticketView) {
      switch (ticketView) {
        case 'escalated':
          this.setState({ Filter: 'ESCALATED' });
          return;
        case 'pendingCustomer':
          this.setState({ Filter: 'PENDING_CUSTOMER' });
          return;
        case 'recentClosed':
          this.setState({ Filter: 'CLOSED' });
          return;
        case 'recentUpdated':
          this.setState({ Filter: 'UPDATED' });
          return;
        case 'open':
          this.setState({ Filter: 'OPEN' });
          return;
        case 'all':
          this.setState({ Filter: 'NONE' });
          return;
      }
    }
  }

  starClass = (service) => service.Starred ? 'star' : 'dim-star';

  favoriteTicket = async (ticket) => {
    if (ticket) {
      //show waiting cursor on change
      ticket.isTogglingStatus = true;
      let newTickets = this.state.Listings.slice();
      let index = _.findIndex(newTickets, s => s.TicketId == ticket.TicketId);
      newTickets[index] = ticket;
      this.setState({ Listings: newTickets });

      let apiPath = `admin/UpdateTicket`;
      let dto = {
        TicketId: ticket.TicketId,
        Starred: ticket.Starred === false ? true : false,
      };
      let response = await apiCalls.post(apiPath, 'PATCH', JSON.stringify(dto)).then(res => res).catch(err => console.error(err));
      if (response.ok) {
        this.updateStarredTicket(ticket);
      } else {
        //restore normal cursor on change
        ticket.isTogglingStatus = false;
        newTickets[index] = ticket;
        this.setState({ Listings: newTickets });
      }
    }
  }

  updateStarredTicket = (item, newFavServiceId = null) => {
    //restore normal cursor on change
    item.isTogglingStatus = false;

    let newIsFavState = item.Starred ? false : true;
    let updatedItem = new Object(item);
    updatedItem.Starred = newIsFavState;
    if (newFavServiceId) updatedItem.FavoriteServiceId = newFavServiceId;
    let newTickets = this.state.Listings.slice();
    let index = _.findIndex(newTickets, s => s.TicketId == updatedItem.TicketId);
    newTickets[index] = updatedItem;
    this.setState({ Listings: newTickets });
  }

  fetchTicketList = async () => {
    await this.setState({ loading: true });
    await this.applyTicketViewFilter();
    let apiPath = `admin/GetTicketList`;
    apiPath += `?LocationAccountNumber=${this.state.LocationAccountNumber}&ContactId=${this.state.ContactId}&filter=${this.state.Filter}&term=${this.state.Term}&QueryLocator=${this.state.QueryLocator}`;
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        let { QueryLocator, TicketList } = data;
        TicketList.forEach(ticket => { // convert Escalated boolean to string in order to filter
          ticket.isTogglingStatus = false;
          if (ticket.CreatedDate) {
            ticket.CreatedDate += ' ' + sessionStorage.userTimeZone;
          }
          if (ticket.ClosedDate) {
            ticket.ClosedDate += ' ' + sessionStorage.userTimeZone;
          }
          if (ticket.Escalated === true) {
            ticket.Escalated = 'yes';
          } else {
            ticket.Escalated = 'no';
          }
        });
        this.setState({ Listings: TicketList, FullListings: TicketList, QueryLocator: QueryLocator, loading: false });
      } else {
        this.setState({ Listings: [], FullListings: [], loading: false });
      }

    }).catch(err => console.error(err));
  }

  transitionToDetailPage = (ticketId) => {
    this.props.history.push(`/ticket/${ticketId}`);
  }

  sortChange = (event) => {
    this.setState({
      Listings: orderBy(this.state.Listings, event.sort),
      Sort: event.sort
    });
  }

  handleFilterChange = async (event) => {
    await this.setState({
      filter: event.filter
    });
  }

  gridContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      if (this.state.Listings && this.state.Listings.length > 0) {
        let columnWidth = '140px';
        return (
          <Grid
            data={this.getTicketListings(this.state.filter)}
            sortable={{ allowUnsort: true, mode: 'single' }}
            sort={this.state.Sort}
            onSortChange={this.sortChange}
            filterable
            filter={this.state.filter}
            onFilterChange={this.handleFilterChange}
          >
            <Column width='40px' field='Starred' title={icons.star} cell={this.favBtn} filterable={false} />
            <Column width={columnWidth} field='TicketNumber' title={headerToolTip('Ticket')} cell={this.ticketNumberCell} filterCell={filtercell(this.dropDownOptions('TicketNumber'))} />
            <Column width={columnWidth} field='Status' title={headerToolTip('Status')} filterCell={filtercell(this.dropDownOptions('Status'))} />
            <Column width={columnWidth} field='Subject' title={headerToolTip('Subject')} filterCell={filtercell(this.dropDownOptions('Subject'))} />
            <Column width={columnWidth} field='Contact.Name' title={headerToolTip('Contact')}
              filterCell={this.contactFilterCell('Contact.Name', 'Name')}
              filterable={this.contactFilterCell('Contact.Name', 'Name') === undefined ? false : true}
            />
            <Column width={columnWidth} field='Priority' title={headerToolTip('Priority')} filterCell={filtercell(this.dropDownOptions('Priority'))} />
            <Column width={columnWidth} field='CreatedDate' title={headerToolTip('Opened')} filterable={false} />
            <Column width={columnWidth} field='ClosedDate' title={headerToolTip('Closed')} filterable={false} />
            <Column width={columnWidth} field='Escalated' title={headerToolTip('Escalated')} filterCell={filtercell(this.dropDownOptions('Escalated'))} />
            {/* <Column width={columnWidth} field='LocationAccountNumber' title='Location' filterable={false} /> */}
            <Column width={columnWidth} field='Category' title={headerToolTip('Category')} filterCell={filtercell(this.dropDownOptions('Category'))} />
            <Column width={columnWidth} field='PreferredCommunication' title={headerToolTip('Communication Method')} filterCell={filtercell(this.dropDownOptions('PreferredCommunication'))} />
            <Column width="90px" cell={this.IconCell} sortable={false} filterCell={this.ClearFilterCell} />
            <Column title=' ' field='filler' sortable={false} filterable={false} />
          </Grid>
        )
      }
      if (this.state.Listings && this.state.Listings.length === 0) {
        return <p style={{ textAlign: 'center' }}>No Available Tickets</p>
      }
    }
  }

  handleClearFilter = () => {
    let newFilter = {
      logic: 'and',
      filters: []
    };
    this.setState({ filter: newFilter });
  }

  contactFilterCell = (sortKey, nestedKey) => {
    const options = () => {
      let sort = [{ field: sortKey, dir: "asc" }];
      let listings = orderBy(this.state.Listings.slice(), sort);
      return [...listings.map(item => item && item.Contact ? item.Contact[nestedKey] : '').filter(helper.onlyUnique)].filter(nonEmpty => nonEmpty);
    }
    if (options() && options().length > 0) {
      return filtercell(options());
    }
  }

  searchListings = (event) => {
    if (this.state.FullListings && this.state.FullListings.length > 0) {
      let searchText = event.target.value.trim();
      let fullDetails = this.state.FullListings;
      if (searchText !== '') {
        searchText = event.target.value;
        let services = filterBy(fullDetails, {
          logic: 'or',
          filters: [
            { field: 'TicketNumber', operator: 'contains', value: searchText, ignoreCase: true },
            { field: 'Status', operator: 'contains', value: searchText, ignoreCase: true },
            { field: 'Subject', operator: 'contains', value: searchText, ignoreCase: true },
            { field: 'Contact.Name', operator: 'contains', value: searchText, ignoreCase: true },
            { field: 'Priority', operator: 'contains', value: searchText, ignoreCase: true },
            { field: 'CreatedDate', operator: 'contains', value: searchText, ignoreCase: true },
            { field: 'ClosedDate', operator: 'contains', value: searchText, ignoreCase: true },
            { field: 'Escalated', operator: 'contains', value: searchText, ignoreCase: true },
            { field: 'Category', operator: 'contains', value: searchText, ignoreCase: true },
            { field: 'Contact.PreferredCommunication', operator: 'contains', value: searchText, ignoreCase: true },
          ]
        });
        this.delayedSearch(services);
      }
      else {
        this.delayedSearch(fullDetails);
      }
    }
  }

  delayedSearch = async (services) => {
    await this.setState({ loading: true });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ Listings: services, loading: false });
    }, settings.searchDelay);
  }

  dropDownOptions = (key) => {
    let sort = [{ field: key, dir: "asc" }];
    let listings = orderBy(this.state.Listings.slice(), sort);
    let info = [...listings.map(item => item[key]).filter(helper.onlyUnique)].filter(nonEmpty => nonEmpty);
    return info;
  }

  getTicketListings = (filter) => {
    return filterBy(orderBy(this.state.Listings, this.state.Sort), filter);
  }

  activeFilters = () => {
    let { Filter } = this.state;
    let possibleFilters = [Filter];
    let filters = possibleFilters.filter((item) => item); // removed empty filters
    if (Filter && Filter !== 'NONE') {
      return (
        <Fragment>
          Filters: <span id='active-filters'>{filterList(filters)}</span>
          <span className='btn text-danger clear-filter' onClick={this.clearFilter}>&times;</span>
        </Fragment>
      )
    }
  }

  clearFilter = () => {
    this.setState({ Filter: 'NONE' });
    this.fetchTicketList();
  }

  exportBtn = () => {
    if (this.state.Listings.length > 0) {
      return (
        <div id="ticketing-csv" className='pull-right'>
          <form ref='downloadCsv' target="iFrameSave" action={config.baseApiUrl + 'admin/GetTicketListCSV'} method="POST">
            <input type="hidden" name="LocationAccountNumber" value={this.state.LocationAccountNumber}></input>
            <input type="hidden" name="ContactId" value={this.state.ContactId} />
            <input type="hidden" name="filter" value={this.state.Filter}></input>
            <input type="hidden" name="term" value={this.state.Term}></input>
            <input type="hidden" name="access_token" value={config.storedAccessToken()} className='hidden'></input>
            <input type="hidden" name="x_policy" value={config.storedXPolicy()} className='hidden'></input>
          </form>
          <iframe name="iFrameSave" height="0" width="0" className='hidden pull-right'></iframe>
          <a tabIndex="0" className='no-btn' ref='exportBtn' onClick={(e) => this.exportTickets()} >Export Tickets</a>
        </div>
      )
    } else return null;
  }

  exportTickets = () => {
    this.refs.downloadCsv.submit();
    this.refs.exportBtn.text = 'Downloading...';
    setTimeout(() => {
      this.refs.exportBtn.text = 'Export Tickets';
    }, 2000);
  }

  render() {
    return (
      <div className='ticketing-summary-page'>
        <div className="container full-card">
          <div className="ticketing card">
            <div className="card-header">
              <h1>Support Tickets
                <small>&nbsp;{!this.state.loading ? this.getTicketListings(this.state.filter).length : null}&nbsp;</small>
                <small className='filters'>
                  <span className='text-info'>
                    {this.activeFilters()}
                  </span>
                </small>
              </h1>
              {this.state.Filter === 'NONE' ? <small>* List reflecting all open tickets & tickets closed within last 7 days</small> : null}
            </div>
            <div className="card-actions">
              <div className="search">
                <label htmlFor="fav_services_srch_tb" className="hidden">favorite services search</label>
                <input id="fav_services_srch_tb" type="text" autoComplete={"off"} className="form-control search-control" onChange={this.searchListings} />
                <svg width="12" height="12" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
              </div>
            </div>
            <div className="ticketing-row">
              <div className='ticketing-item'>
                {sessionStorage.hasTicketing === 'FULL' ? <a
                  onClick={() => this.props.history.push('/new_ticket')}
                  className="no-btn">Create New Ticket</a> : null}
                {this.exportBtn()}
              </div>
            </div>
            <br />
            <div className="card-content">
              {this.gridContent()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}