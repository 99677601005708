import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import AudioUpload from './../../components/audioUpload';


class AnnoucementsModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false, selected: 0 };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {   
  }

  handleSelect = (e) => {
    this.setState({selected: e.selected});
  }

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Announcements</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal dialogClassName="announcementsModal" show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Announcements Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Announcements allows you to customize the Contact Center voice prompts that are played to callers while waiting in queue. </p>
              
              {this.getConfig()}
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  getConfig() {
    return (
      <div className="">
        <PanelBar onSelect={this.handleSelect} expandMode="single">
          <PanelBarItem title={"Entrance Message"} expanded focused selected>
            <div className="form-group">
              <label className="right switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              Play entrance message
            </div>
            <div className="form-group">
              <label className="right switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              Entrance message is mandatory when played
            </div>
            <AudioUpload label="Audio" />
            <hr />
            <div className="btns">
              <a href="" className="btn">Save</a>
              <a href="" className="btn">Cancel</a>
            </div>
          </PanelBarItem>
          <PanelBarItem title={"Estimated Wait Message"}>
            <div className="form-group">
              <label className="right switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              Enable estimates message for queued calls
            </div>
            <div className="form-group">
              <div className="form-control">
                <label className="custom-control">
                <input id="radio1" name="radio" type="radio"/>
                  <span >Announce queue position</span>
                </label> 
                <label className="custom-control">
                  <input id="radio1" name="radio" type="radio"/>
                  <span>Announce wait time</span>
                </label>
              </div>
            </div>
            <div className="form-group">
              <label>Play message for callers in queue positions</label>
              <input type="type" className="form-control" defaultValue="" />
            </div>
            <div className="form-group">
              <label className="right switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              Play high volume message
            </div>
            <AudioUpload label="Audio" />
            <hr />
            <div className="btns">
              <a href="" className="btn">Save</a>
              <a href="" className="btn">Cancel</a>
            </div>
          </PanelBarItem>
          <PanelBarItem title={"Comfort Message"}>
            <div className="form-group">
              <label className="right switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              Play comfort message
            </div>
            <div className="form-group">
              <label>Time between Messages</label>
              <input type="type" className="form-control" defaultValue="" />
              <small>Can't be less then 10 seconds.</small>
            </div>
            <AudioUpload label="Audio" />
            <hr />
            <div className="btns">
              <a href="" className="btn">Save</a>
              <a href="" className="btn">Cancel</a>
            </div>
          </PanelBarItem>
          <PanelBarItem title={"Music On Hold Message"}>
            <div className="form-group">
              <label className="right switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              Enable music or video on hold for queued calls
            </div>
            <AudioUpload label="Audio" />
            <hr />
            <div className="btns">
              <a href="" className="btn">Save</a>
              <a href="" className="btn">Cancel</a>
            </div>
          </PanelBarItem>
          <PanelBarItem title={"Call Whisper Message"}>
            <div className="form-group">
              <label className="right switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              Play Call Whisper Message
            </div>
            <AudioUpload label="Audio" />
            <hr />
            <div className="btns">
              <a href="" className="btn">Save</a>
              <a href="" className="btn">Cancel</a>
            </div>
          </PanelBarItem>
        </PanelBar>
      </div>
    )
  }
};

export default AnnoucementsModal;

