import React, { Component } from 'react';
import { DataLoadingSpinner } from '../../components/spinners';
import { Modal } from 'react-bootstrap';
import * as apiCalls from '../../requests/api_calls';
import MsTeamsConfig from './msteams_config';
import { convertToLocal } from './components/utility';


export default class MsTeamsDNS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Enterprise: this.props.Enterprise,
      loading: true,
      Submission: false,
      submitError: false,
      MSTeamsConfig: this.props.MSTeamsConfig,
      Ticket: null,
      CustomerName: this.props.CustomerName,
      PrimaryGateWay: this.props.PrimaryGateWay,
      SecondaryGateWay: this.props.SecondaryGateWay,
    };
  }

  componentDidMount() {
    if (this.state.MSTeamsConfig.IsDNSTicketCreated) {
      this.getSubmittedTicket();
    } else {
      this.setState({ loading: false });
    }
  }

  getSubmittedTicket = async () => {
    await this.setState({ loading: true });
    let { DNSTicketID } = this.state.MSTeamsConfig;
    let path = `admin/GetMSTeamsTicket?TicketId=${DNSTicketID}`;
    await apiCalls.fetchData(path).then(data => {
      if (data) {
        this.setState({
          loading: false,
          Ticket: data,
        });
      } else {
        this.setState({
          loading: false,
          Ticket: null,
        });
      }
    }).catch(err => console.error(err));
  }

  handleInputChange = async (e, key) => {
    let { value } = e.target;
    let MSTeamsConfig = { ...this.state.MSTeamsConfig };
    MSTeamsConfig[key] = value;
    await this.setState({ MSTeamsConfig });
    //console.log("MSTeamsConfig", MSTeamsConfig); // inserted AD Information
  }

  inputDataField = (val, key, onChangeFunc, readOnly = false, type = 'text') => {
    return (
      <input
        style={{
          width: '50%',
          display: 'inline-block',
        }}
        type={type}
        name={key}
        value={val}
        className="form-control"
        onChange={(e) => onChangeFunc(e, key)}
        disabled={readOnly}
      />
    )
  }

  dataRow = (label, field) => {
    return (
      <div className="form-group-wrapper"
        style={{ margin: '1%', }}
      >
        <label
          style={{
            width: '50%',
            display: 'inline-block',
          }}
        >{label} </label>
        {field}
      </div >
    )
  }

  cardContent = () => {
    let { loading, MSTeamsConfig, Ticket } = this.state;
    if (!loading) {
      let { IsDNSTicketCreated, DNSTicketID, DomainSetupResponse } = MSTeamsConfig;
      let createdTicketID = !IsDNSTicketCreated ? "" : Ticket.TicketNumber;
      let createdOn = !IsDNSTicketCreated ? "" : convertToLocal(Ticket.CreatedDate).toLocaleString();
      let status = !IsDNSTicketCreated ? "No open ticket" : Ticket.Status;
      let dnsTicketData = !IsDNSTicketCreated ? "" : this.parseDomainSetupResponse();
      return (
        <div>
          <p><b>The following represents the request to add DNS records to completeuc.com zone</b></p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="flex-row"
              style={{
                width: '60%',
              }}
            >
              {this.dataRow("DNS Ticket ID:", this.inputDataField(createdTicketID, null, null, true))}
              {this.dataRow("DNS Ticket Created On:", this.inputDataField(createdOn, null, null, true))}
              {this.dataRow("Ticket Status:", this.inputDataField(status, null, null, true))}
              {this.dataRow("", <textarea
                style={{
                  height: "300px",
                  width: '50%',
                  resize: "none",
                }}
                value={this.parseDomainSetupResponse()}
                disabled
              />)}
              {/* {this.dnsBtns()} */}
              <button
                className="a btn"
                onClick={() => this.setState({ showConfirmationModal: true })}
                disabled={IsDNSTicketCreated ? true : false}
              >Create Ticket</button>
              <button
                className="a btn"
                onClick={this.saveAndContinue}
              >Continue</button>
              {this.wizardMsg()}
            </div>
            <div className="flex-row"
              style={{
                marginLeft: '2%',
                width: '40%',
                border: '1px solid grey'
              }}
            >
              <div style={{ textAlign: "center", paddingLeft: '10px' }}>
                <span style={{ textDecoration: "underline" }}>Support Notes</span>
                <p style={{ textAlign: "left" }}>In this step, the Deployment Wizard allows the user to submit a pre-defined ticket containing the correct information (collected from the previous steps) which is sent to the Support Team requesting the update for DNS entries in preparation for the next deployment step (SBC Configuration) for the Microsoft 365 Tenant.
                  <br /><br />Click the Create Ticket button to submit the setup request to Support.
                  <br /><br />When completed and confirmation is displayed, click Continue to move on to the next step.
                </p>
              </div>
            </div>
          </div>
        </div >
      )
    } else {
      return <div className="card-content"
        style={{
          paddingTop: "20px",
        }}
      >
        <DataLoadingSpinner style={{ marginTop: "3%" }} className='load spinner' />
      </div>
    }
  }

  wizardMsg = () => {
    if (this.state.Ticket && this.state.Ticket !== null) {
      let { IsDNSTicketCreated, IsDNSTicketCompleted } = this.state.MSTeamsConfig;
      let { CreatedDate, ClosedDate } = this.state.Ticket;
      if (IsDNSTicketCreated && !IsDNSTicketCompleted) {
        return <div><br /><small style={{
          color: 'green',
          marginLeft: '10px'
        }}>DNS Ticket Created: {convertToLocal(CreatedDate).toLocaleString()}</small></div>
      }
      if (IsDNSTicketCreated && IsDNSTicketCompleted) {
        return <div><br />
          <small style={{
            color: 'green',
            marginLeft: '10px'
          }}>DNS Ticket Created: {convertToLocal(CreatedDate).toLocaleString()}</small><br />
          <small style={{
            color: 'green',
            marginLeft: '10px'
          }}>DNS Ticket Completed: {convertToLocal(ClosedDate).toLocaleString()}</small>
        </div>
      }
    }
  }

  formatDateTime = (dateTime) => {
    try {
      let date = new Date(dateTime);
      if (date instanceof Date) {
        let time = date.getTime();
        let today = new Date(time);
        return `${today.toString()}`;
      }
    } catch (e) {
      return "";
    }
  }

  parseDomainSetupResponse = () => {
    let { DomainSetupResponse } = this.state.MSTeamsConfig;

    if (DomainSetupResponse && DomainSetupResponse !== null) {
      let parsedArr = JSON.parse(DomainSetupResponse);
      if (parsedArr[0].Name.includes("PHL")) {
        parsedArr = parsedArr.reverse();
      }
      let dnsString = `Please add the following A and TXT DNS records to the completeuc.com zone:\n\nA record ${parsedArr[0].Name}\nIP 208.85.134.200\n\nTXT name: ${parsedArr[0].Name}\nTXT value: ${parsedArr[0].domainDnsTxtRecord !== null && parsedArr[0].domainDnsTxtRecord.Text ? parsedArr[0].domainDnsTxtRecord.Text : "null"}\nTTL: ${parsedArr[0].domainDnsTxtRecord !== null && parsedArr[0].domainDnsTxtRecord.Ttl ? parsedArr[0].domainDnsTxtRecord.Ttl : "null"} or your provider default.\n\nA record ${parsedArr[1].Name}\nIP 162.244.101.70\n\nTXT name: ${parsedArr[1].Name}\nTXT value: ${parsedArr[0].domainDnsTxtRecord !== null && parsedArr[1].domainDnsTxtRecord.Text ? parsedArr[1].domainDnsTxtRecord.Text : "null"}\nTTL: ${parsedArr[0].domainDnsTxtRecord !== null && parsedArr[1].domainDnsTxtRecord.Ttl ? parsedArr[1].domainDnsTxtRecord.Ttl : "null"} or your provider default.`;
      return dnsString
    } else return "";
  }

  submitNewTicket = async () => {
    await this.setState({ submittedTicket: true });
    let apiPath = 'admin/CreateMSTeamsTicket?type=dns';
    let ticketDTO = {
      LocationAccountNumber: null,
      Subject: `Add DNS record to completeuc.com – ${this.state.CustomerName}`,
      Description: this.parseDomainSetupResponse(),
      Department: "Systems Engineering",
      Category: null,
      SubCategory: null,
      OtherCategory: null,
      Priority: "Minor",
      ContactId: "0030h00002j2iej", // DATA ADMIN //"0030h00002UpuW5", // James
      PreferredCommunication: "",
    };
    console.log("TICKET DTO", ticketDTO);
    let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(ticketDTO)).then(res => res).catch(err => console.error(err));
    if (response) {
      this.props.RefreshData();
    }
  }

  saveAndContinue = () => {
    //this.setState({ completed: true });
    this.props.Proceed("sbc");
  }

  render() {
    return (
      <div>
        <div className="card-content"
          style={{
            paddingTop: "20px"
          }}>
          <div className="card-actions"></div>
          <Modal show={this.state.showConfirmationModal} onHide={this.handleClose} backdrop='static'>
            <Modal.Header>
              <Modal.Title>Submit DNS ticket?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <hr />
              {this.state.submittedTicket ? <DataLoadingSpinner className='load spinner' /> : <div className="btns">
                <span>
                  <button type="button" className="btn" onClick={this.submitNewTicket}>Yes</button>
                  <button className="btn" onClick={() => this.setState({ showConfirmationModal: false })}>No</button>
                </span>
              </div>}
            </Modal.Footer>
          </Modal>
          {this.cardContent()}
        </div>
      </div>
    );
  };
}