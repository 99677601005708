import React, { Component } from "react";
import { Route, Link, withRouter, Redirect, Switch } from "react-router-dom";
import Dashboard from "./../dashboard";
import CallRecordingMain from "./../callRecording/callRecording"
import services from "./../services";
import sips from "./../sips";
import admin from "./../admin";
import Profile from "./../profile";
import billing from "./../billing";
import contactCenter from "./../contact_center";
import contactCenterSettings from "./../contact_center_settings";
import locations from "./../locations/index";
import location_detail from "./../locations/location_details";
import devices from "./../devices/index";
import Macd from "./../macd/macd";
import MacdAll from "./../macd/macd_all";
import MacdAdd from "./../macd/macd_add";
import MacdReview from "./../macd/macd_review";
import MacdConfirm from "./../macd/macd_confirm";
import MacdUser from "./../macd/macd_user";
import EnterpriseSettings from "./../enterprise_settings";
import EnterpriseSettingsAlt from "./../enterprise_settings_alt";
import momentumLogo from "./../../images/momentum_logo.svg";
import IframeSettings from "./../iframe_page";
import Support from "./../support";
import Training from "./../training";
import call_logs_all from "./../call_logs_all";
import voicemail_all from "./../voicemails/voicemail_all";
import ApiStatuses from "./../../tests/api/statuses";
import user_settings from "./../user_settings";
import "./../../css/bootstrap.css";
import "./../../css/app.css";
import MyServices from "./../navigation/my_services";
import AdminMenu from "./../navigation/admin_menu";
import HelpCenter from "./../navigation/help_center";
import * as session from "../../scripts/session";
import {
  userIsAdmin,
  homePageUrl,
  shouldApplyCustomizations
} from "./../../scripts/helper";
import { isNewSession } from "./../../scripts/session";
import "@progress/kendo-ui";
import { connect } from "react-redux";
import dropDownMenu from './../../components/dropdown_menu';
import GlobalSearch from './../../components/global_search';
import IpTrunking from '../ipTrunking/ipTrunk';
import ManageTrunkGroup from '../ipTrunking/manageTrunkGroup';
import unMatchedPath from './../../components/unmatched_path';
import IPCallLogsLocation from '../ipTrunking/components/ip_call_logs_location';
import IPCallLogsDetailed from '../ipTrunking/components/call_logs_detailed';
import EnterpriseCallLogsAll from '../enterprise_call_logs_all';
import AllTickets from '../ticketing/ticketing_all';
import DetailedTicket from '../ticketing/detailed_ticket_view';
import NewTicket from '../ticketing/new_ticket';
import _ from 'lodash';
import DocumentRepository from "../document_repository";
import EnterpriseContactDirectory from '../enterprise_contact_directory';
import VmReport from '../vm_report';
import EnterpriseTrunking from "../EnterpriseTrunking/enterprise_trunking";
import ManageEntTrunkGroup from "../EnterpriseTrunking/manage_trunk_group";
import ManageADUser from "../activeDirectory/components/manage_user_config";
import AdMain from "../activeDirectory/ad_main";
import MsTeamsMain from "../microsoftTeams/msteams_main";
import ManageMsTeamsUser from "../microsoftTeams/components/manage_user_config";
import { checkBillingAccount } from '../../utility/utility';

//routes only retail can access
class ProtectedWholesaleRoute extends Component {
  hasAccessToComponent = () => {
    const isWholesale = session.IsWholesale();
    const { match: { params: { page = '' } = {} } = {} } = this.props;
    const retailPages = ['/billing', '/training', '/support', '/tickets_all', '/ticket', '/new_ticket']
    return !isWholesale || ((isWholesale && _.indexOf(retailPages, this.props.path) < 0))
  }

  render() {
    const { component: Component, ...props } = this.props;
    return (
      <Route
        {...props}
        render={props => (
          this.hasAccessToComponent() ?
            <Component {...props} /> :
            <Redirect to='/' />
        )}
      />
    )
  }
}
//routes only wholesale can access
class ProtectedRetailRoute extends Component {
  hasAccessToComponent = () => {
    return session.IsWholesale();
  }

  render() {
    const { component: Component, ...props } = this.props;
    return (
      <Route
        {...props}
        render={props => (
          this.hasAccessToComponent() ?
            <Component {...props} /> :
            <Redirect to='/' />
        )}
      />
    )
  }
}

class Sidebar extends Component {
  constructor() {
    super();
    this.state = {
      userServices: [],
      loading: true,
      sideBarLogo: null,
    };
  }

  updateSidebarLogo = () => {
    const { sideBarLogo: existingLogo } = this.state;
    const { logo: incomingLogo } = this.props;

    if (!shouldApplyCustomizations()) {
      this.setState({ sideBarLogo: momentumLogo });
    } else if (incomingLogo && existingLogo != incomingLogo) {
      this.setState({ sideBarLogo: incomingLogo });
    }
  }

  componentDidMount() {
    let homepage = homePageUrl();
    if (isNewSession() || checkBillingAccount()) this.props.history.push(homepage);

    this.updateSidebarLogo();
  }

  apiTests = () => {
    let env = process.env.REACT_APP_ENVIRONMENT;
    if (env === "development" || env === "staging") {
      return <Route path="/test" component={ApiStatuses} />;
    }
  };

  sideBarClassName = () => {
    return this.props.showSideBar ? "sidebar" : "sidebar visible";
  };

  devicesRoute = () => {
    if (this.props.hasDevices)
      return <Route path="/devices" component={devices} />;
  };

  render() {
    const { sideBarLogo } = this.state;
    return (
      <div>
        <div className={this.sideBarClassName()}>
          <Link to={homePageUrl()} className="logo">
            <img src={sideBarLogo} className="App-logo" alt="Logo" />
          </Link>
          {dropDownMenu()}
          <GlobalSearch />
          <div className="nav">
            <MyServices />
            <AdminMenu />
            <HelpCenter />
          </div>
        </div>
        <div className="main body">
          <div className="">
            <Switch>
              <Route exact path="/" render={props => <Dashboard />} />
              <Route path="/dashboard" render={props => <Dashboard />} />
              <Route
                exact
                path="/admin/profile"
                render={props => <Profile adminProfile={true} />}
              />
              <Route
                path="/profile/:ServiceNumber?/:PBXUserID?/:modal?"
                component={Profile}
              />
              <Route path="/admin" component={admin} />
              <ProtectedWholesaleRoute path="/billing" component={billing} />
              <Route path="/contact_center" component={contactCenter} />
              <Route
                path="/contact_center_settings"
                component={contactCenterSettings}
              />
              <Route path="/locations" component={locations} />
              {this.devicesRoute()}
              <Route path="/macd_user" component={MacdUser} />
              <Route path="/macd_all" component={MacdAll} />
              {/* <Route path="/macd" component={Macd} /> */}
              <Route exact path="/macd/:tabName?" component={Macd} />
              <Route path="/macd_add/:OrderId?" component={MacdAdd} />
              <Route path="/macd_review/:OrderId" component={MacdReview} />
              <Route path="/macd_confirm/:OrderId" component={MacdConfirm} />
              <Route
                path="/services/:LocationAccountNumber?/:view?/:TrunkGroupServiceId?"
                component={services}
              />
              <Route path="/sips" component={sips} />
              <Route path="/location_detail" component={location_detail} />
              <Route
                path="/enterprise_settings"
                component={EnterpriseSettings}
              />
              <Route
                path="/enterprise_settings_alt"
                component={EnterpriseSettingsAlt}
              />
              <ProtectedWholesaleRoute
                path="/iframe_page/:page/:LocationAccountNumber?/:tn?"
                component={IframeSettings}
              />
              <ProtectedWholesaleRoute path="/support" component={Support} />
              <ProtectedWholesaleRoute path="/training" component={Training} />
              <Route
                path="/call_logs_all/:LocationAccountNumber/:PBXUserID/:ServiceNumber"
                component={call_logs_all}
              />
              <Route path="/user_settings" component={user_settings} />
              <Route path="/voicemails_all/:AccountNumber/:DirectoryNumber" component={voicemail_all} />
              <Route path='/ip_trunking' component={IpTrunking} />
              <Route path='/ip_location_call_logs' component={IPCallLogsLocation} />
              <Route path='/ip_detailed_call_logs/:LocationAccountNumber/:TrunkID' component={IPCallLogsDetailed} />
              <Route path='/manage_ip_trunking/:trunkId' component={ManageTrunkGroup} />
              <Route path='/enterpise_call_logs_all' component={EnterpriseCallLogsAll} />
              <ProtectedRetailRoute path='/enterprise_contact_directory' component={EnterpriseContactDirectory} />
              <ProtectedWholesaleRoute path='/tickets_all' component={AllTickets} />
              <ProtectedWholesaleRoute path='/ticket/:TicketId' component={DetailedTicket} />
              <ProtectedWholesaleRoute path='/new_ticket' component={NewTicket} />
              <ProtectedRetailRoute path='/documents' component={DocumentRepository} />
              <Route path='/vm_report_full' component={VmReport} />
              <Route path='/enterprise-trunking' component={EnterpriseTrunking} />
              <Route path='/enterprise-trunk-group/:groupId/:trunkGroupName' component={ManageEntTrunkGroup} />
              <Route path='/active-directory' component={AdMain} />
              {/* <Route path='/ad-menu/:enterpriseId' component={SyncMenu} /> */}
              <Route path='/ad-user-config' component={ManageADUser} />
              <Route path='/microsoft-teams' component={MsTeamsMain} />
              <Route path='/ms-teams-user-config' component={ManageMsTeamsUser} />
              <Route path='/call-recording' component={CallRecordingMain} />
              {this.apiTests()}
              <Route component={unMatchedPath} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    showSideBar: state.reducer.showSideBar,
    hasDevices: state.reducer.hasDevices,
    smallLogo: state.reducer.customSmallLogo,
    largeLogo: state.reducer.customLargeLogo
  };
};

export default withRouter(connect(mapStateToProps)(Sidebar));
