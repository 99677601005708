import React, { Component } from 'react';
import * as apiCalls from '../../requests/api_calls';
import { DataLoadingSpinner } from '../../components/spinners';


export default class TicketingCountsWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      Listings: [],
      FullListings: [],
      TicketStats: [],
      LocationAccountNumber: '',
      ContactId: '',
      ContactOptions: [],
      LocationOptions: [],
    }
  }


  componentDidMount() {
    this.fetchTicketCountData();
  }

  fetchTicketCountData = () => {
    let requests = [
      this.fetchTicketStats(),
      this.fetchContactList(),
      this.fetchLocations(),
    ];
    Promise.all(requests).catch(err => console.error(err));
  }

  fetchContactList = () => {
    let apiPath = 'admin/GetContactList';
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        let options = data.map((contact, idx) => {
          return <option key={idx} value={contact.ContactId}>{contact.Name}</option>
        });
        this.setState({ ContactOptions: options });
      }
    }).catch(err => console.error(err));
  }

  fetchLocations = () => {
    let apiPath = 'admin/Locations';
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        let options = data.map((location, idx) => {
          return <option key={idx} value={location.LocationAccountNumber}>{location.LocationName}</option>
        });
        this.setState({ LocationOptions: options });
      }
    })
  }

  fetchTicketStats = async () => {
    await this.setState({ loading: true });
    let apiPath = `admin/GetTicketingStat?LocationAccountNumber=${this.state.LocationAccountNumber}&ContactId=${this.state.ContactId}`;
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        this.setState({ TicketStats: data, loading: false });
      } else {
        this.setState({ loading: false });
      }
    }).catch(err => console.error(err));
  }

  handleChangeContact = (e) => {
    this.setState({ ContactId: e.target.value }, this.fetchTicketStats);
  }

  handleChangeLocation = (e) => {
    this.setState({ LocationAccountNumber: e.target.value }, this.fetchTicketStats);
  }

  ticketFilterDropDown = (name) => {
    if (name === 'contacts' || name === 'locations') {
      return (
        <div
          style={{
            float: 'left',
            width: '50%',
            paddingRight: '4px',
            paddingBottom: '10px'
          }}
        >
          <select className="form-control"
            onChange={name === 'contacts' ? this.handleChangeContact : this.handleChangeLocation}
          >
            <option value=''>{name === 'contacts' ? 'All Contacts' : 'All Locations'}</option>
            {name === 'contacts' ? this.state.ContactOptions : this.state.LocationOptions}
          </select>
        </div>
      )
    }
  }

  ticketRow = (description, count, view) => {
    return (
      <div className="row"
        style={{
          borderBottom: '1px solid #eee',
          marginBottom: '15px',
          padding: '0 4px',
        }
        }
      >
        <label style={{ display: 'block' }}>{description}</label>
        <h3 style={{
          margin: '0',
          fontSize: '42px',
          fontWeight: 'bold',
          marginBottom: '5px',
          display: 'inline-block',
          color: '#00b5e1'
        }}>{count}</h3>
        <a className='btn'
          style={{
            float: 'right',
            margin: '2px 0 0',
          }}
          onClick={() => {
            let setTicketView = (val) => {
              sessionStorage.setItem('ticketView', val);
              this.props.transitionToAllTickets();
            }
            switch (view) {
              case 'View Escalated':
                setTicketView('escalated');
                break;
              case 'View Pending Customer':
                setTicketView('pendingCustomer');
                break;
              case 'View Recently Closed':
                setTicketView('recentClosed');
                break;
              case 'View Recently Updated':
                setTicketView('recentUpdated');
                break;
              case 'View Open':
                setTicketView('open');
                break;
              case 'View All Tickets':
                setTicketView('all');
                break;
              default:
            }
          }}
        >{view}</a>
      </div >
    )
  }

  gridContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      if (this.state.TicketStats) {
        let { Closed, Escalated, Open, PendingCustomer, Starred, Updated, Total7 } = this.state.TicketStats;
        return (
          <div className="main-container" style={{
            paddingLeft: '20px',
          }}>
            {this.ticketRow('Escalated', Escalated, 'View Escalated')}
            {this.ticketRow('Pending Customer', PendingCustomer, 'View Pending Customer')}
            {this.ticketRow('Closed (30 Days)', Closed, 'View Recently Closed')}
            {this.ticketRow('Updated (24 Hours)', Updated, 'View Recently Updated')}
            {this.ticketRow('Open', Open, 'View Open')}
            {this.ticketRow('All Tickets', Total7, 'View All Tickets')}
            <div className='ticket-btns' style={{
              textAlign: 'center',
              paddingTop: '20px',
            }}>
              {sessionStorage.hasTicketing === 'FULL' ? <a
                className='no-btn'
                style={{
                  marginRight: '50px',
                  marginLeft: '-20px' // adjusted for padding to main container
                }}
                onClick={this.props.transitionToNewTicket}
              >Enter New Ticket</a> : null}
              <a
                className='no-btn'
                onClick={this.props.transitionToAllTickets}
              >View All Tickets</a>
            </div>
          </div>
        )
      } else {
        return <p style={{ textAlign: 'center' }}> No Available Ticketing Data</p>
      }
    }
  }

  render() {
    return (
      <div className="ticketing card">
        <div>
          <div className="card-header row">
            <h2 className="move"
              style={{ marginLeft: '0' }}
            >Support Tickets</h2>
          </div>
          <div className="card-content">
            <div className="activity-actions">
              <div className="activity-action">
                <div className="filter">
                  {(this.state.LocationOptions && this.state.LocationOptions.length > 0) && (this.state.ContactOptions && this.state.ContactOptions.length > 0)
                    ? this.ticketFilterDropDown('locations') : null}
                  {(this.state.LocationOptions && this.state.LocationOptions.length > 0) && (this.state.ContactOptions && this.state.ContactOptions.length > 0)
                    ? this.ticketFilterDropDown('contacts') : null}
                </div>
              </div>
            </div>
            {this.gridContent()}
          </div>
        </div>
      </div>
    )
  }
}