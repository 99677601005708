import React, { Component } from 'react';
import { DataLoadingSpinner } from '../../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import * as apiCalls from '../../requests/api_calls';
import _ from 'lodash';
import { orderBy } from '@progress/kendo-data-query';
import { headerToolTip } from '../../components/ellipsisHeaderTooltip';
import { connect } from 'react-redux';
import moment from 'moment';

class VmReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      hasVmUsageReport: false,
      Listings: [],
      Sort: [{ field: "VMLastAccessDateTimeDisplay", dir: "desc" }],
      hasPermission: sessionStorage.hasVmUsageReport,
    };

    class CustomWrapperCell extends GridCell {

      render() {
        let fieldName = this.props.field;
        let styles = fieldName === "VMLastAccessDateTime" ? { overflowWrap: "break-word" } : { overflowWrap: "auto" };
        let cellData = fieldName === "ServiceNumber" && this.props.dataItem["IsExtensionOnly"] ? this.props.dataItem["Extension"] : this.props.dataItem[fieldName];
        return (
          <td style={styles}>
            <div>{cellData}</div>
          </td>
        )
      }
    }

    this.wrapper = CustomWrapperCell;
  }

  componentDidMount = (props) => {
    let { hasVmUsageReport } = sessionStorage;
    if (hasVmUsageReport === "true") {
      this.setState({hasVmUsageReport: true});
      this.fetchVm();
    }
  }

  componentWillReceiveProps(props) {
    if (this.state.hasVmUsageReport !== props.hasVmUsageReport) {
      this.setState({hasVmUsageReport: props.hasVmUsageReport});
      this.fetchVm();
    }
  }

  fetchVm = async () => {
    await this.setState({ loading: true });
    let path = `vm/VmReportList`;
    await apiCalls.fetchData(path).then(data => {
      if (data && data.length > 0) {
        data = data.filter((itm) => {
          if (itm.VMLastAccessDateTime || itm.VMLastAccessDateTime !== null) {
            return itm;
          }
        }).slice(0, 5);
        data.forEach(itm => { // convert Escalated boolean to string in order to filter
          if (itm.VMLastAccessDateTime) {
            itm.VMLastAccessDateTimeDisplay =moment.utc(itm.VMLastAccessDateTime).local().format('MM-DD-YYYY hh:mm:ss A') + ` ${sessionStorage.userTimeZone}`;
          }
        });
        this.setState({ Listings: data, FullListings: data, loading: false });
      } else {
        this.setState({ Listings: [], FullListings: [], loading: false });
      }
    }).catch(err => console.error(err));
  }

  gridContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      if (this.state.Listings && this.state.Listings.length > 0) {
        let columnWidth = '100px';
        let columnWidthStats = '60px'
        return (
          <Grid
            style={{
              //height: `${this.state.gridHeight}px`,
            }}
            data={this.state.Listings}
            sortable={{ allowUnsort: true, mode: 'single' }}
            sort={this.state.Sort}
            onSortChange={this.sortChange}
          >
            <Column width={columnWidth} field="ServiceNumber" title={headerToolTip("Service ID")} cell={this.wrapper} />
            <Column width={columnWidthStats} field="VMTotalTodaysMessages" title={headerToolTip("Today")} cell={this.wrapper} />
            <Column width={columnWidthStats} field="VMTotalMessages" title={headerToolTip("Total")} cell={this.wrapper} />
            <Column width={columnWidthStats} field="VMNewMessages" title={headerToolTip("New")} cell={this.wrapper} />
            <Column width={columnWidthStats} field="VMSavedMessages" title={headerToolTip("Saved")} cell={this.wrapper} />
            <Column width={columnWidth} field="VMLastAccessDateTimeDisplay" title={headerToolTip("Last Access Date")} cell={this.wrapper} />

            <Column title=' ' field='filler' />
          </Grid>
        )
      } else {
        return <p style={{ textAlign: 'center', paddingTop: '15px' }}>No Available Usage Data</p>
      }
    }
  }

  sortChange = (event) => {

    let sort = event.sort;
    if (sort && sort.length > 0)
    {
      //VMLastAccessDateTimeDisplay Sort: [{ field: "VMLastAccessDateTime", dir: "desc" }],
      if (sort[0].field === 'VMLastAccessDateTimeDisplay')
        sort = [{ field: "VMLastAccessDateTime", dir: sort[0].dir }];

      this.setState({
        Listings: orderBy(this.state.Listings, sort),
        Sort: event.sort
      });
    } else {
      this.setState({Sort: []});
    }
  }

  render() {
    return (
      <div className="vm-report card">
        <div className="card-header">
          <h2 className="move">Voicemail Usage Report</h2>
        </div>
        <div className="card-content">
          <div className="vm-grid">
            {this.gridContent()}
          </div>
          {!this.state.loading ? <div className="vm-full-link" style={{ textAlign: "center", paddingTop: "10px" }}>
            <a onClick={this.props.transitionToVmReport}
            >Full Report</a>
          </div> : null}
        </div>
      </div>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    hasVmUsageReport: state.reducer.hasVmUsageReport
  };
}

export default connect(mapStateToProps)(VmReport);