import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from './../../images/m_logo.svg';
import GlobalSearch from './../../components/global_search';
import * as actions from './../../store/actions';
import { store } from '../../store/store';
import dropDownMenu from './../../components/dropdown_menu';
import { connect } from 'react-redux';

class NavBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }

  componentWillReceiveProps(props) {
    if (props.loading !== this.state.loading)
      this.setState({ loading: props.loading });
  }

  toggleSidebar = () => {
    let action = { type: actions.TOGGLE_SIDEBAR };
    store.dispatch(action);
  }

  redirectToOldPortal = () => {
    if (sessionStorage.serviceProviderName === "Vyve_Broadband") {
      return (
        <span
          className="classic-portal-link"
          style={{
            position: "relative",
            left: "300px"
          }}
        >
          <a
            href="https://businessvoice.vyvebroadband.com/"
            //target="_blank"
            style={{
              color: "#F2F2F2",
              fontSize: "smaller"
            }}
          >Click here to return to classic portal</a>
        </span>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div className='navbar'>
          <div className='mobile'>
            <a className='nav-button dropdown-toggle' type="button" id="" onClick={this.toggleSidebar} title="Click to toggle navigation menu">
              <svg width="40" height="40" viewBox="0 0 25 25"><path d="m136 216c-22 0-40 18-40 40 0 22 18 40 40 40 22 0 40-18 40-40 0-22-18-40-40-40z m240 0c-22 0-40 18-40 40 0 22 18 40 40 40 22 0 40-18 40-40 0-22-18-40-40-40z m-120 0c-22 0-40 18-40 40 0 22 18 40 40 40 22 0 40-18 40-40 0-22-18-40-40-40z" transform="scale(0.046875 0.046875)"></path></svg>
            </a>
          </div>
          {dropDownMenu()}
          <GlobalSearch /> {/* only displays for admin */}
        </div>
      )
    }
    else {
      let finalLogo = !sessionStorage.customLogoSmall || sessionStorage.customLogoSmall == '' ? logo : sessionStorage.customLogoSmall;
      return (
        <div className='navbar'>
          <div className='mobile'>
            <Link to='/'><img src={finalLogo} className="App-logo" alt="logo" /></Link>'
            <a className='nav-button dropdown-toggle' type="button" id="" onClick={this.toggleSidebar} title="Click to toggle navigation menu">
              <svg width="40" height="40" viewBox="0 0 25 25"><path d="m136 216c-22 0-40 18-40 40 0 22 18 40 40 40 22 0 40-18 40-40 0-22-18-40-40-40z m240 0c-22 0-40 18-40 40 0 22 18 40 40 40 22 0 40-18 40-40 0-22-18-40-40-40z m-120 0c-22 0-40 18-40 40 0 22 18 40 40 40 22 0 40-18 40-40 0-22-18-40-40-40z" transform="scale(0.046875 0.046875)"></path></svg>
            </a>
          </div>
          {this.redirectToOldPortal()}
          {dropDownMenu()}
          <GlobalSearch /> {/* only displays for admin */}
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  let isLoading = true;
  if (state.reducer.themes_loaded && state.reducer.themes_loaded === true)
    isLoading = false;
  return {
    loading: isLoading
  }
}

export default connect(mapStateToProps)(NavBar);