import React, { Component } from 'react';
import { DataLoadingSpinner } from '../../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import * as apiCalls from '../../requests/api_calls';
import _ from 'lodash';
import ToolTipCell from '../../components/toolTipCell';
import { headerToolTip } from '../../components/ellipsisHeaderTooltip';
import * as icons from '../../scripts/icons';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';

export default class StarredTicketingWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Listings: [],
      loading: false,
      FullListings: [],
      Sort: [{ field: "TicketNum", dir: "desc" }],
      filter: { logic: "and", filters: [] },
      LocationAccountNumber: '',
      ContactId: '',
      Term: '',
      QueryLocator: ''
    }

    const favoriteTicket = this.favoriteTicket.bind(this);
    const transitionToTicketDetail = this.props.transitionToTicketDetail.bind(this);

    class FavoriteButton extends GridCell {
      render() {
        let ticket = this.props.dataItem;
        const cursorType = ticket.isTogglingStatus === true ? 'wait' : 'pointer';
        let cannotFavorite = ticket.TicketId == "" && ticket.TicketId == "";
        if (cannotFavorite) {
          return <td></td>
        } else {
          return (
            <td>
              <a
                className={ticket.Starred ? 'star' : 'dim-star'}
                key={ticket.TicketId}
                onClick={() => favoriteTicket(ticket, ticket.TicketId)}
                style={{cursor: cursorType}}
              >
                <svg tabIndex="0" width="15" height="15" viewBox="0 0 24 24"
                >
                  <path d="m480 192l-157-20-67-140-67 140-157 20 115 104-29 152 138-74 139 74-30-152z" transform="scale(0.046875 0.046875)"></path>
                </svg>
              </a>
            </td>
          )
        }
      }
    }

    class DataLink extends GridCell {

      render() {
        let { TicketNumber, TicketId } = this.props.dataItem;
        return (
          <td>
            <a
              className='no-btn'
              onClick={() => transitionToTicketDetail(TicketId)}
            >{TicketNumber}</a>
          </td>
        )

      }
    }
    this.favBtn = FavoriteButton;
    this.DataLink = DataLink;
  }

  componentDidMount() {
    this.fetchTicketList();
  }

  fetchTicketList = async () => {
    await this.setState({ loading: true })
    let apiPath = `admin/GetTicketList`;
    apiPath += `?LocationAccountNumber=${this.state.LocationAccountNumber}&ContactId=${this.state.ContactId}&filter=STARRED&term=${this.state.Term}&QueryLocator=${this.state.QueryLocator}`;
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        let { QueryLocator, TicketList } = data;
        TicketList.forEach(ticket => { // convert Escalated boolean to string in order to filter
          ticket.isTogglingStatus = false;
          if (ticket.LastModifiedDate) {
            ticket.LastModifiedDate += ' ' + sessionStorage.userTimeZone;
          }
          if (ticket.Escalated === true) {
            ticket.Escalated = 'yes';
          } else {
            ticket.Escalated = 'no';
          }
        });
        this.setState({ Listings: TicketList, FullListings: TicketList, QueryLocator: QueryLocator, loading: false });
      } else {
        this.setState({ Listings: [], FullListings: [], loading: false });
      }

    }).catch(err => console.error(err));
  }

  gridContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      if (this.state.Listings && this.state.Listings.length > 0) {
        let columnWidth = '80px'
        return (
          <Grid
            style={{
              //height: `${this.state.gridHeight}px`,
            }}
            data={this.state.Listings}
            sortable={{ allowUnsort: true, mode: 'single' }}
            sort={this.state.Sort}
            onSortChange={this.sortChange}
          >
            <Column width='40px' field='Starred' title={icons.star} cell={this.favBtn} />
            <Column width={columnWidth} field='TicketNumber' title={headerToolTip('Ticket Number')} cell={this.DataLink} />
            <Column width={columnWidth} field='Subject' title={headerToolTip('Subject')} cell={ToolTipCell} />
            <Column width='65px' field='Priority' title={headerToolTip('Priority')} cell={ToolTipCell} />
            <Column width='65px' field='Escalated' title={headerToolTip('Escalated')} />
            <Column width='65px' field='Status' title={headerToolTip('Status')} cell={ToolTipCell} />
            <Column width={columnWidth} field='LastModifiedDate' title={headerToolTip('Last Update')} cell={ToolTipCell} />
            <Column title=' ' field='filler' />
          </Grid>
        )
      } else {
        return <p style={{ textAlign: 'center', paddingTop: '15px' }}>No Available Tickets</p>
      }
    }
  }

  favoriteTicket = async (ticket) => {
    //get the current ticket from the tickets list
    //update the property isTogglingStatus = true
    ticket.isTogglingStatus = true;
    let newTickets = this.state.Listings.slice();
    let index = _.findIndex(newTickets, s => s.TicketId == ticket.TicketId);
    newTickets[index] = ticket;
    this.setState({ Listings: newTickets });
    if (ticket) {
      let apiPath = `admin/UpdateTicket`;
      let dto = {
        TicketId: ticket.TicketId,
        Starred: !ticket.Starred// === false ? true : false,
      };
      let response = await apiCalls.post(apiPath, 'PATCH', JSON.stringify(dto)).then(res => res).catch(err => console.error(err));
      if (response.ok) {
        this.updateTicket(ticket);
      } else {
        ticket.isTogglingStatus = false;
        newTickets[index] = ticket;
        this.setState({ Listings: newTickets });
      }
    }
  }

  updateTicket = (item, newFavServiceId = null) => {
    let newIsFavState = item.Starred ? false : true;
    let updatedItem = new Object(item);
    updatedItem.Starred = newIsFavState;
    updatedItem.isTogglingStatus = false;
    if (newFavServiceId)
      updatedItem.FavoriteServiceId = newFavServiceId;
    let newTickets = this.state.Listings.slice();
    let index = _.findIndex(newTickets, s => s.TicketId == updatedItem.TicketId);
    newTickets[index] = updatedItem;
    this.setState({ Listings: newTickets });
  }

  searchListings = (event) => {
    let searchText = event.target.value.trim();
    let fullDetails = this.state.FullListings;
    if (searchText !== '') {
      searchText = event.target.value;
      let services = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'TicketNumber', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Subject', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Priority', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Escalated', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Status', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'LastModifiedDate', operator: 'contains', value: searchText, ignoreCase: true },
        ]
      });
      this.setState({ Listings: services })
    }
    else {
      this.setState({ Listings: fullDetails });
    }
  }

  sortChange = (event) => {
    this.setState({
      Listings: orderBy(this.state.Listings, event.sort),
      Sort: event.sort
    });
  }

  render() {
    return (
      <div className="ticketing card">
        <div className="card-header">
          <h2 className="move">Starred Tickets
          <small>&nbsp;{!this.state.loading ? this.state.Listings.length : null}&nbsp;</small>
          </h2>
          {this.state.loading ? null :
            <div className="search">
              <label htmlFor="fav_services_srch_tb" className="hidden">favorite services search</label>
              <input id="fav_services_srch_tb" type="text" autoComplete={"off"} className="form-control search-control" onChange={this.searchListings} />
              <svg width="12" height="12" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
            </div>}
        </div>
        <div className="card-actions"></div>
        <div className="card-content">
          {this.gridContent()}
        </div>
        {!this.state.loading ? <div className='button-container'
          style={{
            textAlign: 'center',
            paddingTop: '20px',
          }}>
          {sessionStorage.hasTicketing === 'FULL' ? <a
            className="no-btn"
            style={{
              marginRight: '50px',
              marginLeft: '-20px' // adjusted for padding to main container
            }}
            onClick={this.props.transitionToNewTicket}
          >Enter New Ticket</a> : null}
          <a
            className="no-btn"
            onClick={this.props.transitionToAllTickets}
          >View All Tickets</a>
        </div> : null}
      </div>
    )
  }
}