import React, { Component, Fragment } from 'react';
import * as apiCalls from '../../requests/api_calls';
import { Modal } from 'react-bootstrap';
import { DataLoadingSpinner } from '../../components/spinners';


export default class VoiceUserSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Settings: null,
      SettingsCopy: null,
      Loading: false,
      VoipSwitch: sessionStorage.VoipSwitch,
      UserId: this.props.UserId,
      showConfirmModal: false,
      ErrorMsg: "",
    };
  }

  openModal = async () => {
    await this.setState({ showConfirmModal: true, Loading: true });
    this.fetchCallRecordingSettings();
  }

  closeModal = async () => {
    await this.setState({ showConfirmModal: false, ErrorMsg: ""});
  }

  fetchCallRecordingSettings = async () => {
    let path = 'admin/UserCallRecordingGet?switchName=' + this.state.VoipSwitch + '&userId=' + this.state.UserId;
    await apiCalls.fetchData(path).then(data => {
      if (data) {
        //console.log('Data', data);
        let copy = JSON.parse(JSON.stringify(data));
        this.setState({
          Settings: data,
          SettingsCopy: copy,
          Loading: false,
        });
      } else {
        this.setState({
          Settings: null,
          SettingsCopy: null,
          Loading: false,
        });
      }
    }).catch(err => console.error(err));
  }

  sumbitVoiceSettings = async () => {
    await this.setState({ Loading: true });
    let path = 'admin/UserCallRecordingModify';
    this.state.Settings.pauseResumeNotificationField = parseInt(this.state.Settings.pauseResumeNotificationField)
    this.state.Settings.recordCallRepeatWarningToneTimerSecondsField = parseInt(this.state.Settings.recordCallRepeatWarningToneTimerSecondsField)
    this.state.Settings.recordingOptionField = parseInt(this.state.Settings.recordingOptionField)
    let dto = {
      UserName: sessionStorage.username,
      VoipSwitch: this.state.VoipSwitch,
      Extension: this.state.UserId,
      Entity: this.state.Settings,
    }
    //console.log("DTO", dto);
    let response = await apiCalls.post(path, "POST", JSON.stringify(dto)).then(data => data).catch(err => console.error(err));

    if (response) {
      if(response.ok) {
        await this.setState({
          Failed: false,
          ErrorMsg: "Success!"
        })
      } else {
        await this.setState({
          Failed: true,
          ErrorMsg: response.message
        })
      }
      this.fetchCallRecordingSettings();
    }
  }

  dataRow = (label, field) => {
    return (
      <div className="form-group-wrapper"
        style={{ margin: '1%', }}
      >
        <label
        // style={{
        //   width: '50%',
        //   display: 'inline-block',
        // }}
        >{label} </label>
        {field}
      </div >
    )
  }

  recordRadioDataField = (val, key, type = "radio") => {
    return (
      <div>
        <input
          type={type}
          value={"0"}
          checked={val === "0"}
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
        <label htmlFor="default"
          style={{
            width: '50%',
          }}
        >Always</label><br />
        <input
          type={type}
          value={"1"}
          checked={val === "1"}
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
        <label htmlFor="trunkGroupPolicy"
          style={{
            width: '50%',
          }}
        >Always with Pause/Resume</label><br />
        <input
          type={type}
          value={"2"}
          checked={val === "2"}
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
        <label htmlFor="default"
          style={{
            width: '50%',
          }}
        >On Demand</label><br />
        <input
          type={type}
          value={"3"}
          checked={val === "3"}
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
        <label htmlFor="default"
          style={{
            width: '50%',
          }}
        >On Demand with user initiated start</label><br />
        <input
          type={type}
          value={"4"}
          checked={val === "4"}
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
        <label htmlFor="default"
          style={{
            width: '50%',
          }}
        >Never</label><br />
      </div>
    )
  }

  pauseResumeRadioDataField = (val, key, type = "radio") => {
    return (
      <div>
        <input
          type={type}
          value={"0"}
          checked={val === "0"}
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
        <label htmlFor="default"
          style={{
            width: '50%',
          }}
        >None</label><br />
        <input
          type={type}
          value={"1"}
          checked={val === "1"}
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
        <label htmlFor="trunkGroupPolicy"
          style={{
            width: '50%',
          }}
        >Beep</label><br />
        <input
          type={type}
          value={"2"}
          checked={val === "2"}
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
        <label htmlFor="default"
          style={{
            width: '50%',
          }}
        >Play Announcement</label><br />
      </div>
    )
  }

  inputDataField = (val, key, type = 'text') => {
    if (type === "text" || type === "number") {
      return (
        <input
          // style={{
          //   width: type === "number" ? '5%' : "25%",
          //   display: 'inline-block',
          //   textAlign: type === "number" ? 'center' : "",
          //   cursor: readOnly === true ? "not-allowed" : "default"
          // }}
          type="text"
          id={key}
          value={val}
          className='custom-input-class'
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
      )
    } else {
      return (
        <input
          style={{
            display: 'inline-block',
            float: 'left'
          }}
          type="checkbox"
          checked={val}
          className='check-box'
          onChange={(e) => this.handleInputChange(e, key, type)}
        //disabled={readOnly === true ? true : false}
        />
      )
    }
  }

  callWarningTone = (enableTone, secondsTone) => {
    return (
      <Fragment>
        {this.inputDataField(enableTone, "enableRecordCallRepeatWarningToneField", "checkbox")}
        &nbsp;&nbsp;
        {/* {this.inputDataField(secondsTone, "recordCallRepeatWarningToneTimerSecondsField", "number")} */}
        <input
          // style={{
          //   width: type === "number" ? '5%' : "25%",
          //   display: 'inline-block',
          //   textAlign: type === "number" ? 'center' : "",
          //   cursor: readOnly === true ? "not-allowed" : "default"
          // }}
          type="number"
          id={"recordCallRepeatWarningToneTimerSecondsField"}
          value={secondsTone}
          min={10}
          disabled={enableTone ? false : true}
          className='custom-input-class'
          onChange={(e) => this.handleInputChange(e, "recordCallRepeatWarningToneTimerSecondsField", "number")}
        />
      </Fragment>
    )
  }

  handleInputChange = async (e, key, type = 'text') => {
    let value = type === 'checkbox' ? e.target.checked : e.target.value;
    //console.log("EVENT VALUE", value);
    let Settings = { ...this.state.Settings };
    Settings[key] = value;
    await this.setState({ Settings });
  }

  formButtons = () => {
    let {
      enableCallRecordingAnnouncementField,
      enableRecordCallRepeatWarningToneField,
      enableVoiceMailRecordingField,
      pauseResumeNotificationField,
      recordCallRepeatWarningToneTimerSecondsField,
      recordingOptionField
    } = this.state.Settings;
    return (
      <div>
        <button className="a btn"
          //onClick={() => this.setState({ showConfirmationModal: true })} // we will show a confirmation modal on click
          onClick={this.sumbitVoiceSettings}
        disabled={
          enableCallRecordingAnnouncementField === this.state.SettingsCopy.enableCallRecordingAnnouncementField &&
          enableRecordCallRepeatWarningToneField === this.state.SettingsCopy.enableRecordCallRepeatWarningToneField &&
          enableVoiceMailRecordingField === this.state.SettingsCopy.enableVoiceMailRecordingField &&
          parseInt(pauseResumeNotificationField) === this.state.SettingsCopy.pauseResumeNotificationField &&
          parseInt(recordCallRepeatWarningToneTimerSecondsField) === this.state.SettingsCopy.recordCallRepeatWarningToneTimerSecondsField &&
          parseInt(recordingOptionField) === this.state.SettingsCopy.recordingOptionField
            ? true : false
        }
        >
          Save
        </button>
        <button
          className="a btn"
          onClick={() => this.setState({ showConfirmModal: false })}
        >Cancel</button>
      </div >
    )
  }

  modalBody = () => {
    if (!this.state.Loading) {
      if (this.state.Settings && this.state.Settings !== null) {
        let {
          PropertyChanged,
          enableCallRecordingAnnouncementField,
          enableRecordCallRepeatWarningToneField,
          enableVoiceMailRecordingField,
          pauseResumeNotificationField,
          recordCallRepeatWarningToneTimerSecondsField,
          recordingOptionField,
          isVoiceMessagingAssignedField
        } = this.state.Settings;
        const isVoiceMessagingAssigned = this.state.Settings.isVoiceMessagingAssignedField
        return (
          <div>
            <h6><b>Record Call:</b></h6>
            {this.dataRow("", this.recordRadioDataField(recordingOptionField.toString(), "recordingOptionField"))}
            {this.dataRow("Play Call Recording Start/Stop Announcement", this.inputDataField(enableCallRecordingAnnouncementField, "enableCallRecordingAnnouncementField", "checkbox"))}
            {isVoiceMessagingAssigned === false ?null:(this.dataRow("Record Voice Messaging", this.inputDataField(enableVoiceMailRecordingField, "enableVoiceMailRecordingField", "checkbox")))}
            <h6><b>Pause / Resume Notification:</b></h6>
            {this.dataRow("", this.pauseResumeRadioDataField(pauseResumeNotificationField.toString(), "pauseResumeNotificationField"))}
            <h6><b>Recording Notification:</b></h6>
            {this.dataRow("Repeat Call Record Warning Tone", this.callWarningTone(enableRecordCallRepeatWarningToneField, recordCallRepeatWarningToneTimerSecondsField))}
            <hr />
            {this.formButtons()}
          </div>
        )
      } else {
        return (
          <p style={{ textAlign: 'center', paddingTop: '15px' }}>No Available Data</p>
        )
      }
    } else {
      return <div>
        <DataLoadingSpinner style={{ marginTop: "3%" }} className='load spinner' />
      </div>
    }
  }

  cardContent = () => {
    return (
      <Fragment>
        <a onClick={this.openModal} className="btn" style={{fontSize:"small"}}>Configure</a>
        <Modal show={this.state.showConfirmModal} backdrop='static'>
          <Modal.Header>
            <Modal.Title>User Voice Settings</Modal.Title>
          </Modal.Header>
          {this.modalBody()}
          {this.state.Loading ? null : <Modal.Footer>
            <p style={{ color: this.state.Failed ? "red" : "green", float: "left", paddingTop: "10px" }}>{this.state.ErrorMsg}</p>
          </Modal.Footer>}
        </Modal>
      </Fragment>
    )
  }

  render() {
    return this.cardContent();
  };

  // render() {
  //   if (!this.state.Loading) {
  //     if (this.state.Settings && this.state.Settings !== null) {
  //       return this.cardContent();
  //     } else {
  //       return (
  //         <p style={{ textAlign: 'center', paddingTop: '15px' }}>No Available Data</p>
  //       )
  //     }
  //   } else {
  //     return <div>
  //       <DataLoadingSpinner style={{ marginTop: "3%" }} className='load spinner' />
  //     </div>
  //   }
  // };
}