import React, { Component } from 'react';
import { fetchData, postData } from './../requests/api_calls';
import { DataLoadingSpinner } from './../components/spinners';
import * as settings from './../scripts/settings';
import EditProfileModal from './profile/modals/editProfileModal'
import ChangePasswordModal from './profile/modals/changePasswordModal';
import ChangeEmailModal from './profile/modals/changeEmailModal';
import E911Modal from './profile/modals/e911Modal';
import { userIsAdmin, selectedUserIsAdmin, titleCasePhrase } from './../scripts/helper';
import { connect } from 'react-redux';
//import TC_MODAL from './modals/termsAndConditionsModal';

class Profile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fetch_error: "",
      hasValidUser: true,
      loading: true,
      profile: {},
      showE911: props.match ? props.match.params.modal === 'e911' : false,
      hasE911: true,
      propsSN: props.match && props.match.ServiceNumber ? props.match.ServiceNumber : '',
      adminProfile: props.adminProfile,
      ServiceNumber: props.ServiceNumber,
      PBXUserID: props.PBXUserID
    };
    this.getHeaderValues = this.getHeaderValues.bind(this);
    this.getChangeEmailValues = this.getChangeEmailValues.bind(this);
    this.updateFromEditProfile = this.updateFromEditProfile.bind(this);
  }

  componentDidMount() {
    this.fetchProfileInfo(this.getProfileApiUrl(this.state.ServiceNumber, this.state.PBXUserID), settings.noResultsMsg);
  }

  componentWillReceiveProps(props) {
    let urlPath = window.location.pathname;
    let pbx = '';
    let sn = '';
    let parts = urlPath.split('/');
    if (parts.length > 2) {
      if (parts[parts.length - 1] !== 'profile') {
        sn = parts[parts.length - 2].replace("%20", "");
        pbx = parts[parts.length - 1];
      }
      else
        this.setState({ adminProfile: true });
    }
    let state = this.state;
    if ((state.ServiceNumber !== sn && props.ServiceNumber !== sn) || (state.PBXUserID !== pbx && props.PBXUserID !== pbx)) {
      this.setState({ loading: true });
      this.updateServiceSelector(sn, pbx);
    }
    else if (state.ServiceNumber !== sn || state.PBXUserID !== pbx) {
      this.setState({ ServiceNumber: sn, PBXUserID: pbx, loading: true });
      this.fetchProfileInfo(this.getProfileApiUrl(sn, pbx), settings.noResultsMsg);
    }
  }

  updateServiceSelector = async (ServiceNumber, PBXUserID) => {
    let UserId = 0;
    let UserRole = '';
    let apiPath = `usr/GetUserIdRole?ServiceNumber=${ServiceNumber}&PBXUserID=${PBXUserID}`;
    let data = await fetchData(apiPath).catch((message) => {
      this.setState({ ...{ loading: false, fetch_error: message.toString() } });
    });
    if (data !== null) {
      UserId = data.UserId;
      UserRole = data.Role;
    }
    this.setState({ adminProfile: selectedUserIsAdmin(UserRole) });
    settings.selectService(UserId, ServiceNumber, PBXUserID);

  }

  fetchProfileInfo = (apiPath, errorMsg) => {
    fetchData(apiPath).then((data) => {
      if (data !== null && data !== undefined) {
        this.loadData(data);
        this.setState({ loading: false });
      }
      else {
        this.setState({ profile: {}, loading: false });
      }
    }).catch((message) => {
      this.setState({ ...{ loading: false, fetch_error: message.toString() } });
    });
  }

  // loadData = async (data) => {
  //   let bValidUser = true;
  //   if (!data.FirstName || data.FirstName === '' || !data.LastName || data.LastName === '')
  //     bValidUser = false;

  //   await this.setState({...{profile: data,loading: false, hasValidUser: bValidUser}});
  // }

  loadData = (data) => {
    let bShow911 = false;
    if (data.HasE911 && data.HasE911.toString() === 'true')
      bShow911 = true;

    this.setState({ ...{ profile: data, loading: false, hasE911: bShow911 } });
  }
  updateFromEditProfile = (FirstName, LastName, Designation, Department, Email, Language) => {
    let profile = this.state.profile;
    profile.FirstName = FirstName;
    profile.LastName = LastName;
    profile.Designation = Designation;
    profile.Department = Department;
    profile.Email = Email;
    profile.Language = Language;
    this.setState({ profile: profile });
  }

  getProfile = () => {
    let title = "";
    let profile = this.state.profile; //this.state.profile.UserId === parseInt(sessionStorage.userId) && userIsAdmin() ? this.props.adminProfile : this.state.profile;
    let isAdmin = this.state.profile.UserId === parseInt(sessionStorage.userId) && userIsAdmin() ? true : (
      (this.state.profile.Role === 'SUPER_ADMIN' || this.state.profile.Role === 'ALLGROUP_ADMIN' || this.state.profile.Role === 'GROUP_ADMIN') ? true : false
    );

    if (this.state.profile.Designation)
      title = <div className="title">{this.state.profile.Designation}</div>

    let svcAddress = "";
    if (this.state.profile.SVCStreet1 ||
      this.state.profile.SVCStreet2 ||
      this.state.profile.SVCCity ||
      this.state.profile.SVCState ||
      this.state.profile.SVCZip)
      svcAddress = <div className="address">
        <address>
          {this.state.profile.SVCStreet1} {this.state.profile.SVCStreet2} <br />
          {this.state.profile.SVCCity}, {this.state.profile.SVCState} {this.state.profile.SVCZip}
        </address>
      </div>

    let e911address = "";
    let userId = sessionStorage.SelectedUserId;
    if (this.state.profile.PBXUserId && this.state.profile.PBXUserId !== '')
      userId = this.state.profile.PBXUserId;

    if (this.state.profile.E911Street1 ||
      this.state.profile.E911Street2 ||
      this.state.profile.E911City ||
      this.state.profile.E911State ||
      this.state.profile.E911Zip)
      e911address = <div className="address">
        <address>
          {this.state.profile.E911Street1} {this.state.profile.E911Street2} <br />
          {this.state.profile.E911City}, {this.state.profile.E911State} {this.state.profile.E911Zip}
        </address>
      </div>
    return (
      <div className="items">
        <div className="item">
          <div className="form-group">
            <div className="company">{this.getCompany()}</div>
            {title}
            {svcAddress}
          </div>
          <div className="phone form-group">
            <b>Department:</b> {this.state.profile.Department}
          </div>
          <div className="phone form-group">
            <b>Service Number:</b>
            <div className="value">
              {this.state.profile.ServiceNumber}
              <span className="ext">
                Ext. {this.state.profile.Extension}</span>
            </div>
          </div>
          <div className="user-id form-group">
            <b>Email:</b> {this.state.profile.Email} <br />
            <b>Mobile:</b> {this.state.profile.Mobile} <br />
            <b>Pager:</b> {this.state.profile.Pager} <br />
            <b>YahooId:</b> {this.state.profile.YahooId} <br />
          </div>
          <div className="user-id form-group">
            <b>Location:</b>
            <div className="value">{this.state.profile.Location}</div>
          </div>
          <div className="user-id form-group">
            <b>Phone Model:</b>
            <div className="value">{this.state.profile.DeviceModel}</div>
          </div>
          <div className="user-id form-group">
            <b>Mac Address:</b>
            <div className="value">{this.state.profile.DeviceMAC}</div>
          </div>
          <div className="user-id form-group">
            <b>Language:</b>
            <div className="value">{this.state.profile.Language}</div>
          </div>
        </div>
        <div className="item">
          <div className="portal-login-username form-group">
            <b>Portal Login Username:</b>
            <div className="value">{this.state.profile.PortalUsername}</div>
          </div>
          <div className="form-group">
            <b>User ID:</b>
            <div className="value">{userId}</div>
          </div>
          <div className="form-group">
            <b>Enterprise ID:</b>
            <div className="value">{this.state.profile.ParentAccountNumber}</div>
          </div>

          <div className="e911-address form-group" style={{ display: this.state.hasE911 ? 'block' : 'none' }}>
            <b>E911 Address:</b>
            <div className="value green">
              {e911address}
            </div>
          </div>

          <div className="e911-update" style={{ display: this.state.hasE911 ? 'block' : 'none' }}>
            <E911Modal labelClassName="no-btn" showModal={this.state.showE911} showUser911={true} ServiceNumber={this.state.profile.ServiceNumber} AccountNumber={this.state.profile.AccountNumber} />
            <br /><br />
            <p>
              PLEASE READ: Your Current E911 Service Address is considered the address at which emergency personnel will respond when an E911 call is placed from your number. By default, your Current E911 Service Address will be the same as your Primary E911 Service Address, which is the primary address at which your service resides. You must temporarily update your Current E911 Service Address in the event you utilize the service from an address other than the Primary E911 Service Address.
              Current E911 Service Addresses must validate with the Public Safety Answering Point (PSAP) system. Using the service to place E911 calls at a non-validated E911 service address can result in an unsuccessful emergency personnel dispatch. If a service address cannot be validated, it may be because the address you entered is not specific enough, is either a PO Box, is not with the 48 United States or Hawaii, or too new to be verified by the PSAP system. If you are unable to validate a service address, please contact your administrator or service provider.
            <span className="time-to-validate"> Please allow 10 minutes for address to validate in our system after updating. </span>
            To change the Primary E911 Service Address listed, please contact your administrator or service provider to initiate a Change of Address, or “Move”, order.
            </p>
          </div>

          <div className="form-group" >
            <b>Password Recovery:</b>
            <div className="value"></div>
            <div>
              <ChangeEmailModal superlink CurrentUserProfile={this.state.adminProfile} isAdmin={userIsAdmin()} GetEmailValues={this.getChangeEmailValues} />
            </div>
            <div>
              <ChangePasswordModal superlink CurrentUserProfile={this.state.adminProfile} isAdmin={userIsAdmin()} ServiceNumber={this.state.profile.ServiceNumber} PBXUserID={this.state.profile.PBXUserID} />
            </div>
          </div>
          <hr />
          <div className="actions" >
            <EditProfileModal CurrentUserProfile={profile} GetHeaderValues={this.getHeaderValues} HasE911={this.state.hasE911} isAdmin={isAdmin} updateProfilePage={this.updateFromEditProfile} />
          </div>
        </div>
      </div>)
  }

  showPrincipalUserProfile = (ServiceNumber, PBXUserID) => {
    return this.serviceNotSelected(ServiceNumber, PBXUserID) || this.state.adminProfile;
  }

  serviceNotSelected = (ServiceNumber, PBXUserID) => {
    return !(ServiceNumber || PBXUserID) || (ServiceNumber === '' && PBXUserID === '');
  }

  getProfileApiUrl = (ServiceNumber, PBXUserID) => {
    let apiUrl = '';
    if (this.showPrincipalUserProfile(ServiceNumber, PBXUserID))
      apiUrl = 'usr/UserProfile';
    else
      apiUrl = `usr/UserProfile?PBXUserID=${PBXUserID}&ServiceNumber=${ServiceNumber}`;

    return apiUrl;

  }

  getName() {
    if (this.state.loading)
      return "";

    if (this.state.profile.FirstName || this.state.profile.LastName)
      return this.state.profile.FirstName + " " + this.state.profile.LastName;
    else if (this.state.profile.ServiceNumber && this.state.profile.ServiceNumber !== '')
      return this.state.profile.ServiceNumber
    else if (this.state.profile.Extension && this.state.profile.Extension !== '')
      return 'Ext: ' + this.state.profile.Extension
    else
      return "Admin";
  }
  getCompany() {
    return (<span>
      {this.state.profile.CustomerName}
    </span>
    )
    // if(this.props.IsAdmin)
    //   return ( 
    //     <span>
    //       <div> {this.state.profile.CustomerName}</div>
    //       <small>Enterprise ID: {this.state.profile.ParentAccountNumber }</small>
    //       <div>&nbsp;</div>
    //     </span>
    //   )
    // else{
    //   return ( <span>
    //     {this.state.profile.CustomerName}
    //     </span>
    //   )
    // }
  }
  getChangeEmailValues = () => {
    return {
      ServiceNumber: this.state.profile.ServiceNumber, //props.CurrentUserProfile.ServiceNumber ? props.CurrentUserProfile.ServiceNumber : '',
      PBXUserID: this.state.profile.PBXUserId,
      UserID: sessionStorage.SelectedUserId ? sessionStorage.SelectedUserId : 0
    }
  }
  getHeaderValues = () => {
    return {
      EnterpriseId: this.state.profile.ParentAccountNumber,
      ServiceNumber: this.state.profile.ServiceNumber,
      Mobile: this.state.profile.Mobile,
      Pager: this.state.profile.Pager,
      YahooId: this.state.profile.YahooId,
      GroupId: this.state.profile.Location
    };
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="full-card container">
          <div className="profile card">
            <div className="card-header">
              <div className='card-actions'><div className='move'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m512 256c0 5-2 9-5 13l-74 73c-3 4-7 5-12 5-5 0-10-1-13-5-4-4-6-8-6-13l0-36-109 0 0 109 36 0c5 0 9 2 13 6 4 3 5 8 5 13 0 5-1 9-5 12l-73 74c-4 3-8 5-13 5-5 0-9-2-13-5l-73-74c-4-3-5-7-5-12 0-5 1-10 5-13 4-4 8-6 13-6l36 0 0-109-109 0 0 36c0 5-2 9-6 13-3 4-8 5-13 5-5 0-9-1-12-5l-74-73c-3-4-5-8-5-13 0-5 2-9 5-13l74-73c3-4 7-5 12-5 5 0 10 1 13 5 4 4 6 8 6 13l0 36 109 0 0-109-36 0c-5 0-9-2-13-6-4-3-5-8-5-13 0-5 1-9 5-12l73-74c4-3 8-5 13-5 5 0 9 2 13 5l73 74c4 3 5 7 5 12 0 5-1 10-5 13-4 4-8 6-13 6l-36 0 0 109 109 0 0-36c0-5 2-9 6-13 3-4 8-5 13-5 5 0 9 1 12 5l74 73c3 4 5 8 5 13z' transform='scale(0.046875 0.046875)'></path></svg></div><div className='expand'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m26 344l-26 158c0 2 1 5 3 7c0 0 0 0 0 0c2 2 5 3 7 3l158-27c3 0 6-3 7-6c1-3 0-7-3-9l-30-31l82-82c4-4 4-9 0-13l-57-57c-3-3-9-3-12 0l-83 83l-31-31c-2-2-5-3-9-2c-3 1-5 4-6 7z m460-176l26-158c0-2-1-5-3-7c0 0 0 0 0 0c-2-2-5-3-7-3l-158 27c-3 0-6 3-7 6c-1 3 0 7 3 9l30 31l-82 82c-4 4-4 9 0 13l57 57c3 3 9 3 12 0l83-83l31 31c2 2 5 3 9 2c3-1 5-4 6-7z' transform='scale(0.046875 0.046875)'></path></svg></div></div>
              <h2 className="move">Profile</h2>
            </div>
            <div className="vm-actions card-content">
              <DataLoadingSpinner className='load spinner pull-left' />
            </div>
          </div>
        </div>
      );
    }


    return (
      <div className="full-card container">
        <div className="profile card">
          <div className="card-header">
            <div className='card-actions'><div className='move'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m512 256c0 5-2 9-5 13l-74 73c-3 4-7 5-12 5-5 0-10-1-13-5-4-4-6-8-6-13l0-36-109 0 0 109 36 0c5 0 9 2 13 6 4 3 5 8 5 13 0 5-1 9-5 12l-73 74c-4 3-8 5-13 5-5 0-9-2-13-5l-73-74c-4-3-5-7-5-12 0-5 1-10 5-13 4-4 8-6 13-6l36 0 0-109-109 0 0 36c0 5-2 9-6 13-3 4-8 5-13 5-5 0-9-1-12-5l-74-73c-3-4-5-8-5-13 0-5 2-9 5-13l74-73c3-4 7-5 12-5 5 0 10 1 13 5 4 4 6 8 6 13l0 36 109 0 0-109-36 0c-5 0-9-2-13-6-4-3-5-8-5-13 0-5 1-9 5-12l73-74c4-3 8-5 13-5 5 0 9 2 13 5l73 74c4 3 5 7 5 12 0 5-1 10-5 13-4 4-8 6-13 6l-36 0 0 109 109 0 0-36c0-5 2-9 6-13 3-4 8-5 13-5 5 0 9 1 12 5l74 73c3 4 5 8 5 13z' transform='scale(0.046875 0.046875)'></path></svg></div><div className='expand'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m26 344l-26 158c0 2 1 5 3 7c0 0 0 0 0 0c2 2 5 3 7 3l158-27c3 0 6-3 7-6c1-3 0-7-3-9l-30-31l82-82c4-4 4-9 0-13l-57-57c-3-3-9-3-12 0l-83 83l-31-31c-2-2-5-3-9-2c-3 1-5 4-6 7z m460-176l26-158c0-2-1-5-3-7c0 0 0 0 0 0c-2-2-5-3-7-3l-158 27c-3 0-6 3-7 6c-1 3 0 7 3 9l30 31l-82 82c-4 4-4 9 0 13l57 57c3 3 9 3 12 0l83-83l31 31c2 2 5 3 9 2c3-1 5-4 6-7z' transform='scale(0.046875 0.046875)'></path></svg></div></div>
            <h2 className="move name">{this.getName()}</h2>
          </div>
          <div className="user-info card-content">

            <div className="card-content">

              {this.getProfile()}

            </div>
          </div>
        </div>
      </div>
    )
  }

}


const mapStateToProps = (state) => {
  return {
    ServiceNumber: (state.reducer.ServiceNumber || ''),
    PBXUserID: (state.reducer.PBXUserID || '')
  }
}

export default connect(mapStateToProps)(Profile);