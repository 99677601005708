import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';
import SuperLink from './../../components/super_link'
import CCLogos from './../../images/credit-card-logos.png';
import CheckOne from './../../images/check_one.gif';
import CheckTwo from './../../images/check_two.gif';
import { DatePicker } from '@progress/kendo-dateinputs-react-wrapper';


class PayManageModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {   
  }

  render() {
    return (
      <span className="setting">
        <span onClick={this.handleShow}> 
      		<a className="k-button btn green">Manage Payments (only if payment setup)</a>

        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Manage Payments</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="payModal">
	            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum omnis facere, ex quasi. Atque officiis distinctio, nulla officia perspiciatis dolores.</p>
            	<hr />
            	<div className="btns">
            		<a className="btn">Cancel Payment for this Credit Card (0321)</a>
            		<a className="btn">Cancel</a>
            	</div>
			</div>
          </Modal.Body>
        </Modal>
      </span>
    );
  }
};

export default PayManageModal;
