import moment from 'moment';
import React, { Component } from 'react';
import DeviceStatuses from './../../components/deviceStatuses';
import { Link } from 'react-router-dom';
import { fetchData } from './../../requests/api_calls';
import { DataLoadingSpinner } from './../../components/spinners';
import { registrationLastUpdated } from './../../scripts/helper';
import * as settings from './../../scripts/settings.js';
import { serviceFilters } from '../../constants/filters.js'
import format from 'string-format';

const defaultSelection = 'All Locations';

class Devices extends Component {

  constructor(props) {
    super(props);
    this.state = {
      devicesProvisioned: 0,
      devicesRegistered: 0,
      devicesUnregistered: 0,
      linesRegistered: 0,
      linesUnregistered: 0,
      total: 0,
      loading: true,
      selectedLocationValue: ''
    };
    this.filterLocations = this.filterLocations.bind(this);
  };

  componentDidMount() {
    this.fetchDeviceStats('admin/DeviceStat');
    this.fetchLocations('admin/Locations');
  }

  fetchDeviceStats = (apiPath, forcedRefresh) => {
    fetchData(apiPath).then((data) => {
      if (data) {
        this.setState({
          devicesProvisioned: (data.CountProvisioned || 0),
          devicesRegistered: (data.CountRegistered || 0),
          devicesUnregistered: (data.CountUnregistered || 0),
          linesRegistered: (data.CountLinesRegistered || 0),
          linesUnregistered: (data.CountLinesUnregistered || 0),
          total: data.CountTotal,
          loading: false,
          registrationUpdated: forcedRefresh ? registrationLastUpdated() : false
        });
      } else { // error: probably a request timeout
        let previousLocationValue = this.state.previousLocationValue || defaultSelection;
        this.setState({
          selectedLocationValue: previousLocationValue,
          loading: false,
          registrationUpdated: forcedRefresh ? 'Failed to retrieve registration status: ' + moment().format('M-DD-YYYY hh:mm:ss A') : ''
        });
      }
    });
  }

  fetchLocations = (apiPath) => {
    fetchData(apiPath).then((data) => {
      if (data) {
        let locationOptions = data.map((location, index) => {
          return ( this.locationOption(location, index) );
        });
        this.setState({locationOptions: locationOptions, loading: false});
      } else {
        this.setState({loading: false});
      }
    });
  }

  locationOption = (location, index) => {
    return (
      <option key={index} value={location.LocationAccountNumber} >
        {location.LocationName} - {location.LocationAccountNumber}
      </option>
    )
  }

  filterLocations = (event) => {
    let locationAccountNumber = event.target.value;
    // let selectionIndex = event.target.selectedIndex;
    // let locationAddress = event.target.options[selectionIndex].text;
    let stateChanges = {
      loading:true,
      selectedLocationValue: locationAccountNumber,
      previousLocationValue: this.state.selectedLocationValue,
    }
    this.setState({...stateChanges});
    if(locationAccountNumber) {
      this.fetchDeviceStats('admin/DeviceStat?LocationAccountNumber=' + locationAccountNumber);
      settings.setQuickViewLocation(locationAccountNumber);
    } else{
      this.fetchDeviceStats('admin/DeviceStat');
      settings.setQuickViewLocation("");
    }
  }

  manageBtn = (serviceUserFilter, categoryLabel, count) => {
    let path = '/services/' + this.state.selectedLocationValue;
    let linkClass = count > 0 ? 'btn' : 'hidden';
    return (
      <div className="quick-item">
        <Link className={linkClass} to={{
          pathname: path,
          serviceUserFilter: serviceUserFilter,
          viewFilter: serviceFilters.ALL_SERVICES
        }} >
          Manage
        </Link>
        <label>{categoryLabel}</label>
        <h3>{count}</h3>
      </div>
    )
  }

  getCount = (filterValue, categoryLabel, count, displaySide) => {
    let path = '/services/' + this.state.selectedLocationValue;
    if (!count) {
      return (
        <div className={format("quick-item {}", displaySide)}>
        <label>{categoryLabel}</label>
          <h3>{count}</h3>
        </div>
      )
    } else {
      return (
        <div className="quick-item left">
        <label>{categoryLabel}</label>
          <Link to={{
            pathname: path,
            serviceUserFilter: filterValue,
            viewFilter: serviceFilters.ALL_SERVICES,
          }} >
            <h3>{count}</h3>
          </Link>
        </div>
      )
    }
  }

  checkRegistration = () => {
    let forcedRefresh = true;
    if (this.state.selectedLocationValue) {
      let locationAccountNumber = this.state.selectedLocationValue;
      this.fetchDeviceStats('admin/DeviceStat?LocationAccountNumber=' + locationAccountNumber + '&ForceDeviceRefresh=TRUE', forcedRefresh);
    } else {
      this.fetchDeviceStats('admin/DeviceStat?ForceDeviceRefresh=TRUE', forcedRefresh);
    }
  }

  devicesSummary = () => {
    //let path = '/services/' + this.state.selectedLocationValue;
    //let right = "right", left = "left"; // for css, either right or left
    if (this.state.loading) {
      return (
        <div className="quick-items">
          <DataLoadingSpinner className='load spinner'/>
        </div>
      )
    } else {
      return (
        <div className="quick-items">
          {this.manageBtn(serviceFilters.DEVICE_REGISTERED, 'Registered', this.state.devicesRegistered)}
          {this.manageBtn(serviceFilters.DEVICE_UNREGISTERED, 'Unregistered', this.state.devicesUnregistered)}
          {this.manageBtn(serviceFilters.DEVICE_ASSIGNED, 'Provisioned', this.state.devicesProvisioned)}          
          {/* <div className='col-md-6 item-row'>
            <label>Lines Unregistered</label><h3>{this.state.linesUnregistered}</h3>
          </div>
          <div className='col-md-6 item-row'>
            <label>Devices Provisioned</label><h3>{this.state.devicesProvisioned}</h3>
          </div> */}
        </div>
      )
    }
  }

  render() {
    return (
      <div className="devices quick-view card">
        <div>
          <div className="card-header row">
            <div className='col-md-3'>
              <h2 className="move">Devices</h2>
            </div>
            <div className='col-md-9'>
              <DeviceStatuses
                checkRegistration={this.checkRegistration}
                updated={this.state.registrationUpdated}
              />
            </div>
          </div>
          <div className="card-content">
            <div className="activity-actions">
              <div className="activity-action">
                <div className="filter">
                  <select name="" className="form-control" onChange={this.filterLocations.bind(this)} value={this.state.selectedLocationValue} >
                    <option value="">{defaultSelection}</option>
                    {this.state.locationOptions}
                  </select>
                </div>
              </div>
            </div>
            {this.devicesSummary()}
          </div>
        </div>
      </div>
    )
  }

}

export default Devices;

