import React, { Component } from 'react';
import { DataLoadingSpinner } from './spinners';
import { fetchData } from './../requests/api_calls';

class DeviceStatuses extends Component {

  constructor(props) {
    super(props);
    this.state = {loading: props.loading};
  }

  componentWillReceiveProps(nextProps) {
    this.setState({loading: false});
  }

  realtimeRegistrationStatus = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='adjacent-left load spinner' />
    } else return <span>{this.props.updated}</span>
  }

  updateStatus = () => {
    this.setState({loading: true}, this.props.checkRegistration())
  }

  deviceStatus = () => {
    if (this.props.updated) {
      return (
        <div>
          {this.realtimeRegistrationStatus()}
          <a onClick={this.updateStatus}> Update</a>
        </div>
      )
    } else if (this.state && this.state.loading) {
      return (
        <div>
          <DataLoadingSpinner className='adjacent-left load spinner' textLeft={'Loading'} />
          <a onClick={this.updateStatus}> Update</a>
        </div>
      )
    } else return <a onClick={this.updateStatus}> Check Registration Status</a>
  }

  render() {
    return (
      <div className='device-status'>
        {this.deviceStatus()}
      </div>
    )
  }
}

export default DeviceStatuses;