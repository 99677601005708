import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { Accordion, AccordionItem } from 'react-sanfona';
import { Tooltip } from '@progress/kendo-popups-react-wrapper';


class AnnouncementRepositoryModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { showModal:false, deleteable: false };
    this.changeAction = this.changeAction.bind(this);
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  changeAction(event){
    this.setState({deleteable: true})
  }

  componentDidMount() {   
  }

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Announcement Repository</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Announcement Repository</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Announcement repository allows you to manage all the audio announcements</p>
            </div>
            <div id="announcementRepositoryModal">
              <table className="table">
                <thead>
                  <tr>
                    <th className="check"><input type="checkbox" /></th>
                    <th>Description</th>
                    <th>File Type</th>
                    <th>File Size</th>
                    <th>Play</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><input type="checkbox" onChange={this.changeAction} checked={this.state.deleteable} /></td>
                    <td>Cool Desc</td>
                    <td>.wav</td>
                    <td>36mb</td>
                    <td className="play">
                      <Tooltip content="Play" position="top"> 
                        <a href=""><svg width="12" height="12" viewBox="0 0 23 23"><path d="m405 233l-278-166c-4-2-7-3-11-3-11 0-20 9-20 20l0 0 0 344 0 0c0 11 9 20 20 20 4 0 7-1 11-3l278-166c7-5 11-14 11-23 0-9-4-17-11-23z" transform="scale(0.046875 0.046875)"></path></svg></a>
                      </Tooltip> 
                      <Tooltip content="Download" position="top"> 
                        <a href=""><svg width="12" height="12" viewBox="0 0 23 23"><path d="m478 280c9 15 11 31 7 49c0 0-17 94-17 94c-1 7-4 13-10 18c-6 5-13 8-20 8c0 0-365 0-365 0c-7 0-13-3-19-8c-6-5-10-11-10-18c0 0-18-94-18-94c-3-18 0-34 7-49c0 0 81-192 81-192c8-16 20-23 37-23c0 0 209 0 209 0c17 0 30 7 38 23c0 0 80 192 80 192m-35 67c1-7-1-14-5-19c-4-6-10-9-18-9c0 0-329 0-329 0c-8 0-14 3-18 9c-4 5-6 12-5 19c0 0 7 38 7 38c1 8 4 14 10 19c6 5 13 8 20 8c0 0 302 0 302 0c8 0 14-3 20-8c6-5 9-11 10-19c0 0 6-38 6-38" transform="scale(0.046875 0.046875)"></path></svg></a>
                      </Tooltip> 
                    </td>
                    <td align="right"><a className="icon btn"  data-toggle="collapse" data-target="#ar1" ><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="ar1">
                    <td colSpan="6">
                      <div className="table-edit">
                        <div className="form-group">
                          <label>Description</label>
                          <input type="type" className="form-control" defaultValue="My room" />
                        </div>
                        <div className="form-group">
                          <label>File</label>
                          <input type="file" className="form-control" defaultValue="" />
                          <small>Max file size for audio announcements is 5MB in CCIT u-Law 8kHz, 8bit Mono format</small>
                        </div>
                        <hr />
                        <div className="btns">
                          <a href="" className="btn">Save</a>
                          <a href="" className="btn">Cancel</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" onChange={this.changeAction} checked={this.state.deleteable} /></td>
                    <td>Cool Desc</td>
                    <td>.wav</td>
                    <td>36mb</td>
                    <td className="play">
                      <Tooltip content="Play" position="top"> 
                        <a href=""><svg width="12" height="12" viewBox="0 0 23 23"><path d="m405 233l-278-166c-4-2-7-3-11-3-11 0-20 9-20 20l0 0 0 344 0 0c0 11 9 20 20 20 4 0 7-1 11-3l278-166c7-5 11-14 11-23 0-9-4-17-11-23z" transform="scale(0.046875 0.046875)"></path></svg></a>
                      </Tooltip> 
                      <Tooltip content="Download" position="top"> 
                        <a href=""><svg width="12" height="12" viewBox="0 0 23 23"><path d="m478 280c9 15 11 31 7 49c0 0-17 94-17 94c-1 7-4 13-10 18c-6 5-13 8-20 8c0 0-365 0-365 0c-7 0-13-3-19-8c-6-5-10-11-10-18c0 0-18-94-18-94c-3-18 0-34 7-49c0 0 81-192 81-192c8-16 20-23 37-23c0 0 209 0 209 0c17 0 30 7 38 23c0 0 80 192 80 192m-35 67c1-7-1-14-5-19c-4-6-10-9-18-9c0 0-329 0-329 0c-8 0-14 3-18 9c-4 5-6 12-5 19c0 0 7 38 7 38c1 8 4 14 10 19c6 5 13 8 20 8c0 0 302 0 302 0c8 0 14-3 20-8c6-5 9-11 10-19c0 0 6-38 6-38" transform="scale(0.046875 0.046875)"></path></svg></a>
                      </Tooltip> 
                    </td>
                    <td align="right"><a className="icon btn"  data-toggle="collapse" data-target="#ar2" ><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="ar2">
                    <td colSpan="6">
                     <div className="table-edit">
                        <div className="form-group">
                          <label>Description</label>
                          <input type="type" className="form-control" defaultValue="My room" />
                        </div>
                        <div className="form-group">
                          <label>File</label>
                          <input type="file" className="form-control" defaultValue="" />
                          <small>Max file size for audio announcements is 5MB in CCIT u-Law 8kHz, 8bit Mono format</small>
                        </div>
                        <hr />
                        <div className="btns">
                          <a href="" className="btn">Save</a>
                          <a href="" className="btn">Cancel</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  {this.deleteRow()}
                  <tr>
                    <td colSpan="6">
                      <small>File Storage: used 14 MB. available 984 MB.</small>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="6">
                      <div className="btns">
                        <a className="btn" data-toggle="collapse" data-target="#ar2">Add Announcement</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  deleteRow() {
    if (this.state.deleteable){  
      return (
        <tr className="deleteable">
        <td colSpan="6">
          <a className="no-btn">Delete Items</a>
          <a className="no-btn">Cancel</a>
        </td>
      </tr>
      )
    }
  }
};

export default AnnouncementRepositoryModal;
