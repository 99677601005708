import React, { Component } from 'react';
import * as helper from '../../scripts/helper';
import * as apiCalls from '../../requests/api_calls';
import { DataLoadingSpinner } from '../../components/spinners';
import ManageTrunkModal from './components/manageTrunkModal';
import * as _ from 'lodash';
import IPTrunkReviewModal from './components/ipTrunkReviewModal';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6

export default class ManageTrunkGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      TrunkId: props.match.params.trunkId,
      Data: [],
      ValidIps: [],
      OriginalCopy: [],
      CustomerProductId: null,
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.hasIpTrunk === 'true' && sessionStorage.IPTrunkLocationName && sessionStorage.IPTrunkStartDate) {
      this.fetchIpTrunk();
    } else {
      this.props.history.push('/');
    }
  }

  formGroupDropdown = (labelFor, labelName, val, type = 'text') => {
    if (labelFor === 'Direction') {
      return (
        <div className='form-group-wrapper'>
          <label htmlFor={labelFor}>{labelName} :</label>
          <select id={labelFor} className='custom-input-class' value={val} disabled
            style={{ cursor: 'not-allowed' }}
            onChange={(e) => this.handleInputChange(e, labelFor, type)}
          >
            <option value="">pending</option>
            <option value="in">in</option>
            <option value="out">out</option>
            <option value="both">both</option>
          </select>
        </div>
      )
    }
    if (labelFor === 'GroupPolicy') {
      return (
        <div className='form-group-wrapper'>
          <label htmlFor={labelFor}>{labelName} :</label>
          <select id={labelFor} className='custom-input-class' value={val}
            onChange={(e) => this.handleInputChange(e, labelFor, type)}
          >
            <option value="round_robin">round_robin</option>
            <option value="top_down">top_down</option>
          </select>
        </div>
      )
    }
    if (labelFor === 'ServiceState') {
      return (
        <div className='form-group-wrapper'>
          <label htmlFor={labelFor}>{labelName} :</label>
          <select id={labelFor} className='custom-input-class' value={val}
            onChange={(e) => this.handleInputChange(e, labelFor, type)}
          >
            <option value="inservice">inservice</option>
            <option value="block">block</option>
          </select>
        </div>
      )
    }
  }

  formGroupCheckBox = (labelFor, labelName, val, type = 'checkbox', enabled = true) => {
    return (
      <div className='form-group-wrapper'>
        <label htmlFor={labelFor}>{labelName}:</label>
        <input type={type} id={labelFor} name='' checked={val} className='custom-check' disabled={!enabled ? 'disabled' : ''}
          style={{ cursor: labelFor === 'International' ? 'not-allowed' : 'pointer' }}
          onChange={(e) => this.handleInputChange(e, labelFor, type)}
        />
      </div>
    )
  }

  formGroupInput = (labelFor, labelName, val, type = 'text') => {
    let nodeLabel = labelFor.length === 5 ? labelFor.slice(0, -1) : labelFor.slice(0, -2);
    let validIp = helper.ValidateIPaddress(val);
    if (nodeLabel === 'Node' && val !== '' && validIp === false) {
      return (
        <div className='form-group-wrapper'>
          <label htmlFor={labelFor}>{labelName}:</label>
          <input type={type} id={labelFor} name='' value={val} className='custom-input-class'
            readOnly={(labelFor === 'TrunkId' || labelFor === 'TrunkLocationAddress' || labelFor === 'SentDate' || labelFor === 'LineDescription') ? true : false}
            style={{
              cursor: (labelFor === 'TrunkId' || labelFor === 'TrunkLocationAddress' || labelFor === 'SentDate') ? 'not-allowed' : 'pointer',
              border: '1px solid red',
              borderRadius: '0%',
            }}
            onChange={(e) => this.handleInputChange(e, labelFor, type)}
          />
        </div>
      )
    }
    return (
      <div className='form-group-wrapper'>
        <label htmlFor={labelFor}>{labelName}:</label>
        <input type={type} id={labelFor} name='' value={val} className='custom-input-class'
          readOnly={(labelFor === 'TrunkId' || labelFor === 'TrunkLocationAddress' || labelFor === 'SentDate') ? true : false}
          style={{
            cursor: (labelFor === 'TrunkId' || labelFor === 'TrunkLocationAddress' || labelFor === 'SentDate') ? 'not-allowed' : 'pointer',
          }}
          onChange={(e) => this.handleInputChange(e, labelFor, type)}
        />
      </div>
    )
  }

  fetchIpTrunk = async () => {
    await this.setState({ loading: true });
    let apiPath = `admin/GetIpTrunk?TrunkGroupID=${this.state.TrunkId}`;
    await apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        let { IPAddress, SIPS, Sansay } = data.ProvInfo;
        let obj = {
          // SIPS
          PromotionOfferPackageID: SIPS.PromotionOfferPackageID,
          TrunkId: SIPS.TrunkId,
          GroupPolicy: SIPS.GroupPolicy,
          ServiceState: SIPS.ServiceState,
          Direction: SIPS.Direction,
          TechPrefix: SIPS.TechPrefix,
          IncomingCNAM: SIPS.IncomingCNAM,
          International: SIPS.International,
          LineDescription: SIPS.LineDescription,
          TerminationCode: SIPS.TerminationCode,
          TerminationNotes: SIPS.TerminationNotes,
          TNActivation: SIPS.TNActivation,
          VoipSwitch: SIPS.VoipSwitch,
          UserEmail: SIPS.UserEmail,
          // SANSAY
          Block: Sansay.Block,
          PseudoCarrier: Sansay.PseudoCarrier,
          E911Carrier: Sansay.E911Carrier,
          Status: Sansay.Status,
          SentDate: Sansay.SentDate,
          LastUpdatedDate: Sansay.LastUpdatedDate,
          // IP Address
          Node1: IPAddress.Node1,
          Node2: IPAddress.Node2,
          Node3: IPAddress.Node3,
          Node4: IPAddress.Node4,
          Node5: IPAddress.Node5,
          Node6: IPAddress.Node6,
          Node7: IPAddress.Node7,
          Node8: IPAddress.Node8,
          Node9: IPAddress.Node9,
          Node10: IPAddress.Node10,
          Node11: IPAddress.Node11,
          Node12: IPAddress.Node12,
        }
        let copy = JSON.parse(JSON.stringify(obj));
        this.setState({
          Data: [obj],
          OriginalCopy: [copy],
          loading: false,
          CustomerProductId: data.CustomerProductId,
          CustomerLocationId: data.CustomerLocationId,
        })
      } else {
        this.setState({
          Data: [],
          OriginalCopy: [],
          loading: false,
        })
      }
    }).catch(err => console.error(err));
  }

  handleInputChange = (e, labelFor, type) => {
    let newValue = type === 'text' ? e.target.value : e.target.checked;
    let existingArray = this.state.Data.slice();
    existingArray[0][labelFor] = newValue;
    // COMPARE ORIGINAL TO UPDATED ARRAY
    let originalCopy = this.state.OriginalCopy.slice();
    if (originalCopy && originalCopy.length > 0) {
      // Using Lodash for Obj comparison... had to add 'HasChanges' prop to Original for lodash to work properly
      let objIsEqual = _.isEqual(originalCopy, existingArray);
      if (objIsEqual === true) {
        delete existingArray[0].HasChanges;
        delete originalCopy[0].HasChanges;
      } else {
        existingArray[0].HasChanges = true;
        originalCopy[0].HasChanges = true;
      }
    }
    this.setState({ Data: existingArray });
  }

  updateIpTrunk = async () => {
    let apiPath = `admin/UpdateIPTrunk`;
    let obj = this.state.Data[0];
    let ipTrunkDTO = {
      CustomerLocationId: this.state.CustomerLocationId,
      CustomerProductId: this.state.CustomerProductId,
      ProvInfo:
      {
        SIPS:
        {
          PromotionOfferPackageID: obj.PromotionOfferPackageID,
          TrunkId: obj.TrunkId,
          GroupPolicy: obj.GroupPolicy,
          ServiceState: obj.ServiceState,
          Direction: obj.Direction,
          TechPrefix: obj.TechPrefix,
          IncomingCNAM: obj.IncomingCNAM,
          International: obj.International,
          LineDescription: obj.LineDescription,
          TerminationCode: obj.TerminationCode,
          TerminationNotes: obj.TerminationNotes,
          TNActivation: obj.TNActivation,
          VoipSwitch: obj.VoipSwitch,
          UserEmail: obj.UserEmail
        },
        Sansay:
        {
          Block: obj.Block,
          PseudoCarrier: obj.PseudoCarrier,
          E911Carrier: obj.E911Carrier,
          Status: obj.Status,
          SentDate: obj.SentDate,
          LastUpdatedDate: obj.LastUpdatedDate
        },
        IPAddress:
        {
          Node1: obj.Node1,
          Node2: obj.Node2,
          Node3: obj.Node3,
          Node4: obj.Node4,
          Node5: obj.Node5,
          Node6: obj.Node6,
          Node7: obj.Node7,
          Node8: obj.Node8,
          Node9: obj.Node9,
          Node10: obj.Node10,
          Node11: obj.Node11,
          Node12: obj.Node12
        }
      }
    }
    await this.setState({ SubmitModalOpen: true, reviewSub: true });
    let response = await apiCalls.send(apiPath, 'PATCH', JSON.stringify(ipTrunkDTO)).then(res => res).catch(err => console.error(err));
    let { message } = response;
    if (response.ok === true) {
      this.setState({
        reviewSub: false,
        successSub: true,
      });
    } else {
      this.setState({
        reviewSub: false,
        failedSub: true,
      });
    }
  }

  handleSubmitForm = async () => {
    await this.setState({ errorMsg: null })
    let changedItems = this.state.Data.filter(res => res.HasChanges === true);
    if (!changedItems || changedItems.length === 0) {
      this.setState({ errorMsg: 'No Changes have been made...' });
      return;
    }
    let obj = this.state.Data[0];
    let fullIpList = [];
    let validIpList = Object.keys(obj).filter(res => {
      if (res) {
        let nodeLabel = res.length === 5 ? res.slice(0, -1) : res.slice(0, -2);
        if (nodeLabel === "Node") {
          fullIpList.push(res);
          if (helper.ValidateIPaddress(obj[res]) === true || obj[res] === '') return res;
        }
      }
    });
    if (validIpList && fullIpList && (validIpList.length === fullIpList.length)) {
      if (!obj.Node1 || obj.Node1 === '' || obj.Node1 === null) {
        this.setState({ errorMsg: 'IP Address Node 1 may not be empty...' });
        return;
      }
      this.updateIpTrunk();
      return;
    } else {
      this.setState({ errorMsg: 'The requested submission contains an invalid IP Address...' });
      return;
    }
  }

  errorHandler = () => {
    if (this.state.errorMsg) {
      return <ReactCSSTransitionGroup
        transitionName="basic-feature-ip"
        transitionAppear={true}
        transitionAppearTimeout={4000}
        transitionEnter={true}
        transitionEnterTimeout={4000}
        transitionLeave={false}
      >
        {this.state.errorMsg === 'Success!' ? <div className="inline inline-ip" style={{ color: 'green' }}>{this.state.errorMsg}</div> : <div className="inline inline-ip">{this.state.errorMsg}</div>}
      </ReactCSSTransitionGroup>
    }
  }

  ipTrunkForm = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      if (this.state.Data && this.state.Data.length > 0) {
        let Data = this.state.Data[0];
        return (
          <div className="card-content">
            <div className="header-wrapper">
              <h5 className='manage-ip-trunk-header'>Provisioning Information</h5>
              {/* {this.manageTrunkModal()} */}
            </div>
            <div className='manage-ip-trunk-wrapper'>
              <div className='ip-form-container'>
                <form>
                  <div className='column-one'>
                    {this.formGroupInput('TrunkId', 'Trunk ID', this.state.TrunkId)}
                    {this.formGroupInput('TrunkLocationAddress', 'Trunk Location Address', sessionStorage.IPTrunkLocationName)}
                    {this.formGroupCheckBox('IncomingCNAM', 'CNAM Delivery', Data.IncomingCNAM)}
                    {this.formGroupCheckBox('International', 'International Allowed', Data.International, 'checkbox', false)}
                    {this.formGroupInput('TechPrefix', 'Tech Prefix')}
                    <br />
                    {this.formGroupInput('Node1', 'IP Address Node 1', Data.Node1)}
                    {this.formGroupInput('Node3', 'IP Address Node 3', Data.Node3)}
                    {this.formGroupInput('Node5', 'IP Address Node 5', Data.Node5)}
                    {this.formGroupInput('Node7', 'IP Address Node 7', Data.Node7)}
                    {this.formGroupInput('Node9', 'IP Address Node 9', Data.Node9)}
                    {this.formGroupInput('Node11', 'IP Address Node 11', Data.Node11)}
                  </div>
                  <div className='column-two'>
                    {this.formGroupInput('LineDescription', 'Line Description', Data.LineDescription)}
                    {this.formGroupInput('SentDate', 'Trunk Start Date', sessionStorage.IPTrunkStartDate)}
                    {this.formGroupDropdown('GroupPolicy', 'Group Policy', Data.GroupPolicy)}
                    {this.formGroupDropdown('ServiceState', 'Service State', Data.ServiceState)}
                    {this.formGroupDropdown('Direction', 'Direction', Data.Direction)}
                    {this.formGroupInput('Node2', 'IP Address Node 2', Data.Node2)}
                    {this.formGroupInput('Node4', 'IP Address Node 4', Data.Node4)}
                    {this.formGroupInput('Node6', 'IP Address Node 6', Data.Node6)}
                    {this.formGroupInput('Node8', 'IP Address Node 8', Data.Node8)}
                    {this.formGroupInput('Node10', 'IP Address Node 10', Data.Node10)}
                    {this.formGroupInput('Node12', 'IP Address Node 12', Data.Node12)}
                  </div>
                </form>
              </div>
              <div className='form-footer'>
                <a className='custom-form-submit'
                  onClick={this.handleSubmitForm}
                >Submit</a>
                {this.errorHandler()}
              </div>
            </div>
          </div>
        );
      }
    }
  }

  handleShow = () => {
    if (!this.state.showModal)
      this.setState({ showModal: true });
    else
      this.setState({ showModal: false })
  }

  manageTrunkModal = () => {
    return <ManageTrunkModal
      handleShow={this.handleShow}
      showModal={this.state.showModal}
      trunkid={this.state.TrunkId}
    />
  }

  handleSubmitModal = () => {
    if (!this.state.SubmitModalOpen)
      this.setState({ SubmitModalOpen: true });
    else {
      this.fetchIpTrunk();
      this.setState({ SubmitModalOpen: false })
    }
  }

  render() {
    return (
      <div className="manage-ip-trunk-page">
        <div className="container full-card">
          <div className="manage-ip-trunk card">
            <div className="card-header">
              <h1>IP Trunking</h1>
            </div>
            {this.ipTrunkForm()}
            <IPTrunkReviewModal
              show={this.state.SubmitModalOpen}
              reviewSub={this.state.reviewSub}
              successSub={this.state.successSub}
              failedSub={this.state.failedSub}
              close={this.handleSubmitModal}
            />
          </div>
        </div>
      </div >
    )
  }
};