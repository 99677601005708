import React, { Component } from 'react';
import { DataLoadingSpinner } from '../../components/spinners';
import { Modal } from 'react-bootstrap';
import * as apiCalls from '../../requests/api_calls';
import { convertToLocal } from './components/utility';
export default class MsTeamsSBC extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Enterprise: this.props.Enterprise,
      loading: true,
      Submission: false,
      submitError: false,
      MSTeamsConfig: this.props.MSTeamsConfig,
      Ticket: null,
      CustomerName: this.props.CustomerName,
      PrimaryGateWay: this.props.PrimaryGateWay,
      SecondaryGateWay: this.props.SecondaryGateWay,
    };
  }

  componentDidMount() {
    if (this.state.MSTeamsConfig.IsSBCTicketCreated) {
      this.getSubmittedTicket();
    } else {
      this.setState({ loading: false });
    }
  }

  getSubmittedTicket = async () => {
    await this.setState({ loading: true });
    let { SBCTicketID } = this.state.MSTeamsConfig;
    let path = `admin/GetMSTeamsTicket?TicketId=${SBCTicketID}`;
    await apiCalls.fetchData(path).then(data => {
      if (data) {
        this.setState({
          loading: false,
          Ticket: data,
        });
      } else {
        this.setState({
          loading: false,
          Ticket: null,
        });
      }
    }).catch(err => console.error(err));
  }

  handleInputChange = async (e, key) => {
    let { value } = e.target;
    let MSTeamsConfig = { ...this.state.MSTeamsConfig };
    MSTeamsConfig[key] = value;
    await this.setState({ MSTeamsConfig });
    //console.log("MSTeamsConfig", MSTeamsConfig); // inserted AD Information
  }

  inputDataField = (val, key, onChangeFunc, readOnly = false, type = 'text') => {
    return (
      <input
        style={{
          width: '50%',
          display: 'inline-block',
        }}
        type={type}
        name={key}
        value={val}
        className="form-control"
        onChange={(e) => onChangeFunc(e, key)}
        disabled={readOnly}
      />
    )
  }

  dataRow = (label, field) => {
    return (
      <div className="form-group-wrapper"
        style={{ margin: '1%', }}
      >
        <label
          style={{
            width: '50%',
            display: 'inline-block',
          }}
        >{label} </label>
        {field}
      </div >
    )
  }

  cardContent = () => {
    let { loading, MSTeamsConfig, Ticket } = this.state;
    if (!loading) {
      let { IsSBCTicketCreated, SBCTicketID, IsSBCTicketCompleted, SBCTicketCompletedOn } = MSTeamsConfig;
      let createdTicketID = !IsSBCTicketCreated ? "" : Ticket.TicketNumber;
      let createdOn = !IsSBCTicketCreated ? "" : convertToLocal(Ticket.CreatedDate).toLocaleString();
      let status = !IsSBCTicketCreated ? "No open ticket" : Ticket.Status;
      let sbcTicketData = !IsSBCTicketCreated ? "" : `RPX Account Number: ${this.state.Enterprise}\nCustomer Name: ${this.state.CustomerName}, LLC\nRPX Switch Name: ${this.state.MSTeamsConfig.Switch}\n\nFQDNs: ${this.state.PrimaryGateWay} and ${this.state.SecondaryGateWay}`;
      return (
        <div>
          <p><b>The following represents the request to add MS Teams SBC functionality</b></p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="flex-row"
              style={{
                width: '60%',
              }}
            >
              {this.dataRow("SBC Ticket ID:", this.inputDataField(createdTicketID, null, null, true))}
              {this.dataRow("SBC Ticket Created On:", this.inputDataField(createdOn, null, null, true))}
              {this.dataRow("Ticket Status:", this.inputDataField(status, null, null, true))}
              {this.dataRow("", <textarea
                style={{
                  height: "150px",
                  width: '50%',
                  resize: "none",
                }}
                value={sbcTicketData}
                disabled
              />)}
              {/* {this.sbcBtns()} */}
              <button
                className="a btn"
                onClick={() => this.setState({ showConfirmationModal: true })}
                disabled={IsSBCTicketCreated ? true : false}
              >Create Ticket</button>
              <button
                className="a btn"
                onClick={this.saveAndContinue}
              >Continue</button>
              {this.wizardMsg()}
            </div>
            <div className="flex-row"
              style={{
                marginLeft: '2%',
                width: '40%',
                border: '1px solid grey'
              }}
            >
              <div style={{ textAlign: "center", paddingLeft: '10px' }}>
                <span style={{ textDecoration: "underline" }}>Support Notes</span>
                <p style={{ textAlign: "left" }}>In this step, the Deployment Wizard helps the Admin create and submit a properly formatted ticket to the Support Team requesting set up of routes in the SBC that will handle calls between the Microsoft Teams Tenant and the Network.
                <br/><br/>Click the Create Ticket button to submit the SBC Setup request to Support.
                <br/><br/>When completed and confirmation displays, click Continue to move on to the next step.
              </p>
              </div>
            </div>
          </div>
        </div >
      )
    } else {
      return <div className="card-content"
        style={{
          paddingTop: "20px",
        }}
      >
        <DataLoadingSpinner style={{ marginTop: "3%" }} className='load spinner' />
      </div>
    }
  }

  wizardMsg = () => {
    if (this.state.Ticket && this.state.Ticket !== null) {
      let { IsSBCTicketCreated, IsSBCTicketCompleted } = this.state.MSTeamsConfig;
      let { CreatedDate, ClosedDate } = this.state.Ticket;
      if (IsSBCTicketCreated && !IsSBCTicketCompleted) {
        return <div><br /><small style={{
          color: 'green',
          marginLeft: '10px'
        }}>SBC Ticket Created: {convertToLocal(CreatedDate).toLocaleString()}</small></div>
      }
      if (IsSBCTicketCreated && IsSBCTicketCompleted) {
        return <div><br />
          <small style={{
            color: 'green',
            marginLeft: '10px'
          }}>SBC Ticket Created: {convertToLocal(CreatedDate).toLocaleString()}</small><br />
          <small style={{
            color: 'green',
            marginLeft: '10px'
          }}>SBC Ticket Completed: {convertToLocal(ClosedDate).toLocaleString()}</small>
        </div>
      }
    }
  }

  formatDateTime = (dateTime) => {
    try {
      let date = new Date(dateTime);
      if (date instanceof Date) {
        let time = date.getTime();
        let today = new Date(time);
        return `${today.toString()}`;
      }
    } catch (e) {
      return "";
    }
  }

  submitNewTicket = async () => {
    await this.setState({ submittedTicket: true });
    let apiPath = 'admin/CreateMSTeamsTicket?type=sbc';
    let ticketDTO = {
      //AccountId: "0010h00001XhZxcAAF",
      LocationAccountNumber: null,
      Subject: `Please add MS Teams SBC functionality for ${this.state.CustomerName} - ${this.state.Enterprise}`,
      Description: `RPX Account Number: ${this.state.Enterprise}\nCustomer Name: ${this.state.CustomerName}, LLC\nRPX Switch Name: ${this.state.MSTeamsConfig.Switch}\n\nFQDNs: ${this.state.PrimaryGateWay} and ${this.state.SecondaryGateWay}`,
      Department: "Voice Operations",
      Category: null,
      SubCategory: null,
      OtherCategory: null,
      Priority: "Minor",
      ContactId: "0030h00002j2iej", // DATA ADMIN //"0030h00002UpuW5", // James
      PreferredCommunication: "",
    };
    console.log("TICKET DTO", ticketDTO);

    let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(ticketDTO)).then(res => res).catch(err => console.error(err));
    if (response) {
      this.props.RefreshData();
    }
  }

  saveAndContinue = () => {
    //this.setState({ completed: true });
    this.props.Proceed("temp");
  }

  render() {
    return (
      <div>
        <div className="card-content"
          style={{
            paddingTop: "20px"
          }}>
          <div className="card-actions"></div>
          <Modal show={this.state.showConfirmationModal} onHide={this.handleClose} backdrop='static'>
            <Modal.Header>
              <Modal.Title>Submit SBC ticket?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <hr />
              {this.state.submittedTicket ? <DataLoadingSpinner className='load spinner' /> : <div className="btns">
                <span>
                  <button type="button" className="btn" onClick={this.submitNewTicket}>Yes</button>
                  <button className="btn" onClick={() => this.setState({ showConfirmationModal: false })}>No</button>
                </span>
              </div>}
            </Modal.Footer>
          </Modal>
          {this.cardContent()}
        </div>
      </div>
    );
  };
}