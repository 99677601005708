import React, { Component } from 'react';
import { DataLoadingSpinner } from './../components/spinners';
import { fetchData } from './../requests/api_calls';

class Applications extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      applications: []
    };
  };

  componentDidMount() {
    this.updateApplication();
  }

  componentDidUpdate(prevProps){
    if(
      this.props.ServiceNumber !== prevProps.ServiceNumber ||
      this.props.PBXUserID !== prevProps.PBXUserID){
        this.updateApplication();
        
    }
  }
 
  updateApplication=()=>{
    this.setState({applications:[],loading: true});
    let params = "?ServiceNumber=" + this.props.ServiceNumber;
    if (this.props.PBXUserID) params += "&PBXUserID=" + this.props.PBXUserID;
    let apiPath = 'usr/UserApplications' + params;
    fetchData(apiPath).then((data) => {
      if (data !== null) {
        this.loadData(data);
      } else if (data === null) {
       
        this.setState({applications:[],loading: false});
      };
    });
    // this.hascallRecording();
  }
  loadData = (data) => {
    if(sessionStorage.hasCallRecordingService==="false")
    {
      let f =data.filter(el=>el.ApplicationID !==15 )
      let applications = f.map((app, index) => this.appInfo(app, index) );
      this.setState({applications: applications,loading: false});
    }
    else
    {
      let applications = data.map((app, index) => this.appInfo(app, index) );
      this.setState({applications: applications,loading: false});
    }    
  }

  appInfo(app, index) {
    let numberClasses = ['one', 'two', 'three', 'four', 'five', 'six', 'seven']
    let numClassesIndex = index % numberClasses.length;
    return (
      <div key={index} className={'app ' + numberClasses[numClassesIndex]} >
        <h4>{app.ApplicatoinName}
          <a onClick={()=> window.open(app.ApplicationURL, "_blank")} className="btn">Connect</a>
        </h4>
        <p></p>
      </div>  
    )
  }

  hascallRecording=()=>{
    let apiPath = 'admin/HasCallRecording';
    fetchData(apiPath).then((data) => {
      if (data !== null) {
        
      } else if (data === null) {
       
      };
    });
  }

  appList = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner'/>
    } else if (this.state.applications.length > 0) {     
      return this.state.applications
    } else { 
      return <h4 className='empty'>No applications are available.</h4>
    }
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <div className='card-actions'><div className='move'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m512 256c0 5-2 9-5 13l-74 73c-3 4-7 5-12 5-5 0-10-1-13-5-4-4-6-8-6-13l0-36-109 0 0 109 36 0c5 0 9 2 13 6 4 3 5 8 5 13 0 5-1 9-5 12l-73 74c-4 3-8 5-13 5-5 0-9-2-13-5l-73-74c-4-3-5-7-5-12 0-5 1-10 5-13 4-4 8-6 13-6l36 0 0-109-109 0 0 36c0 5-2 9-6 13-3 4-8 5-13 5-5 0-9-1-12-5l-74-73c-3-4-5-8-5-13 0-5 2-9 5-13l74-73c3-4 7-5 12-5 5 0 10 1 13 5 4 4 6 8 6 13l0 36 109 0 0-109-36 0c-5 0-9-2-13-6-4-3-5-8-5-13 0-5 1-9 5-12l73-74c4-3 8-5 13-5 5 0 9 2 13 5l73 74c4 3 5 7 5 12 0 5-1 10-5 13-4 4-8 6-13 6l-36 0 0 109 109 0 0-36c0-5 2-9 6-13 3-4 8-5 13-5 5 0 9 1 12 5l74 73c3 4 5 8 5 13z' transform='scale(0.046875 0.046875)'></path></svg></div><div className='expand'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m26 344l-26 158c0 2 1 5 3 7c0 0 0 0 0 0c2 2 5 3 7 3l158-27c3 0 6-3 7-6c1-3 0-7-3-9l-30-31l82-82c4-4 4-9 0-13l-57-57c-3-3-9-3-12 0l-83 83l-31-31c-2-2-5-3-9-2c-3 1-5 4-6 7z m460-176l26-158c0-2-1-5-3-7c0 0 0 0 0 0c-2-2-5-3-7-3l-158 27c-3 0-6 3-7 6c-1 3 0 7 3 9l30 31l-82 82c-4 4-4 9 0 13l57 57c3 3 9 3 12 0l83-83l31 31c2 2 5 3 9 2c3-1 5-4 6-7z' transform='scale(0.046875 0.046875)'></path></svg></div></div>
          <h2 className="move">Applications</h2>
        </div>      
        <div className="applications card-content">
          {this.appList()}
          {/*<div className="app">
            <a href="" className="no-btn">View All Applications</a>
          </div>*/}
        </div>
      </div>
    )
  }

}

export default Applications;