import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import BillingCard from './admin/billing_card';
import FavServices from './admin/fav_services';
import CallChart from './admin/call_chart';
import QuickView from './admin/quick_view';
import Devices from './admin/devices';
import ActivityHistory from './admin/activity_history';
import TicketingCountsWidget from './admin/ticket_count_widget';
import StarredTicketingWidget from './admin/starred_tickets_widget';
import Masonry from "react-masonry-component";
import VmReport from './admin/vm_report_widget';
import { checkBillingAccount } from '../utility/utility';
//import TC_MODAL from './modals/termsAndConditionsModal';
import { fetchData } from "./../requests/api_calls";

class Admin extends Component {
  constructor(props) {
    super(props);

    const favServices = this.favServices.bind(this);
    const activityHistory = this.activityHistory.bind(this);
    const starredTickets = this.starredTickets.bind(this);
    const quickView = this.quickView.bind(this);
    const devices = this.devices.bind(this);
    const callChart = this.callChart.bind(this);
    const ticketingCount = this.ticketingCount.bind(this);

    let widgets = [
      { toggle: 'showFavServices', functionName: favServices, widgetRow: 0, widgetColumn: 0 },
      { toggle: 'showActivityHistory', functionName: activityHistory, widgetRow: 0, widgetColumn: 0 },
      { toggle: 'showStarredTicketingWidget', functionName: starredTickets, widgetRow: 0, widgetColumn: 0 },
      { toggle: 'showQuickView', functionName: quickView, widgetRow: 0, widgetColumn: 0 },
      { toggle: 'showDevices', functionName: devices, widgetRow: 0, widgetColumn: 0 },
      { toggle: 'showCallChart', functionName: callChart, widgetRow: 0, widgetColumn: 0 },
      { toggle: 'showTicketingCountsWidget', functionName: ticketingCount, widgetRow: 0, widgetColumn: 0 }
    ];

    let ipTrunkingOnly = sessionStorage.isIpTrunkingOnly !== null && sessionStorage.isIpTrunkingOnly !== undefined ? sessionStorage.isIpTrunkingOnly : false;

    this.state = {
      hasTicketing: sessionStorage.hasTicketing ? sessionStorage.hasTicketing : 'NONE',
      hasDevices: false,
      ipTrunkingOnly: ipTrunkingOnly,
      showFavServices: true,
      showActivityHistory: true,
      showStarredTicketingWidget: true,
      showQuickView: true,
      showDevices: ipTrunkingOnly ? false : true,
      showCallChart: true,
      showTicketingCountsWidget: true,
      widgets: widgets
    };
  }

  componentDidMount() {
    //this.fetchTermsAndConditions();
  }

  // fetchTermsAndConditions = async () => {
  //   let path = "admin/EnterpriseTC";
  //   await fetchData(path).then(data => {
  //     if (data) {
  //       sessionStorage.setItem('entTCFlag', data[0].TCFlag);
  //     }
  //   }).catch(err => console.error(err));
  // }

  componentWillReceiveProps = (props) => {
    let state = this.state;
    if (state.hasTicketing !== props.hasTicketing || state.hasDevices !== props.hasDevices || state.ipTrunkingOnly !== props.ipTrunkingOnly) //in the future this may change if user can customize their dashboard
      this.setState({
        hasTicketing: props.hasTicketing,
        hasDevices: props.hasDevices,
        showFavServices: true,
        showActivityHistory: true,
        showStarredTicketingWidget: ((!props.hasTicketing && state.hasTicketing === false) || props.hasTicketing === 'NONE') ? false : true,
        showQuickView: true,
        showDevices: (props.ipTrunkingOnly !== null && props.ipTrunkingOnly !== undefined && props.ipTrunkingOnly === true) ? false : state.showDevices,
        showCallChart: true,
        showTicketingCountsWidget: ((!props.hasTicketing && state.hasTicketing === false) || props.hasTicketing === 'NONE') ? false : true,
      })
  }

  transitionToTicketDetail = (ticketId) => {
    this.props.history.push(`/ticket/${ticketId}`);
  }

  transitionToNewTicket = () => {
    this.props.history.push('/new_ticket');
  }

  transitionToAllTickets = () => {
    this.props.history.push('/tickets_all');
  }

  transitionToCallLogDetails = () => {
    this.props.history.push('/enterpise_call_logs_all');
  }

  transitionToVmReport = () => {
    this.props.history.push(`/vm_report_full`);
  }

  favServices = () => {
    return (
      <FavServices />
    );
  }

  activityHistory = () => {
    return (
      <ActivityHistory />
    );
  };

  starredTickets = () => {
    if (this.state.hasTicketing && this.state.hasTicketing !== 'NONE')
      return (
        <StarredTicketingWidget
          transitionToTicketDetail={this.transitionToTicketDetail}
          transitionToNewTicket={this.transitionToNewTicket}
          transitionToAllTickets={this.transitionToAllTickets}
        />
      );
    return null;
  }

  quickView = () => {
    return (
      <QuickView />
    )
  }

  devices = () => {
    return (
      <Devices />
    );
  }
  callChart = () => {
    return (
      <CallChart
        transition={this.transitionToCallLogDetails}
      />
    )
  }
  ticketingCount = () => {
    if (this.state.hasTicketing && this.state.hasTicketing !== 'NONE')
      return (
        <TicketingCountsWidget
          transitionToNewTicket={this.transitionToNewTicket}
          transitionToAllTickets={this.transitionToAllTickets}
        />
      );
    return null;
  }

  vmReport = () => {
    let { hasVmUsageReport } = sessionStorage;
    if (hasVmUsageReport === "true") {
      return (
        <VmReport
          transitionToVmReport={this.transitionToVmReport}
        />
      )
    } else return;

  }


  render() {
    let hasTicketing = this.state.hasTicketing ? (this.state.hasTicketing !== 'NONE' ? true : false) : false;
    let hasDevices = this.state.showDevices; //todo: this.state.hasDevices could be incorporated if Device checkbox in rpx is not checked?
    
    if (checkBillingAccount()) { // only show billing widget for select Alteva, CNG & DCT accounts
      return (
        <div className="container">
          <BillingCard />
        </div>
      )
    } else {
      return (
        <div className="container">
          <BillingCard />
          <Masonry
            id={'grid'}
            className={'admin cards'}
            disableImagesLoaded={false}
            updateOnEachImageLoad={false}
            enableResizableChildren={true}
          >
            <div className="column">
              <FavServices />
              <ActivityHistory />
              {hasTicketing ? <StarredTicketingWidget
                transitionToTicketDetail={this.transitionToTicketDetail}
                transitionToNewTicket={this.transitionToNewTicket}
                transitionToAllTickets={this.transitionToAllTickets}
              /> : null}
            </div>
            <div className="column">
              <QuickView />
              {hasDevices ? <Devices /> : null}
              {this.vmReport()}
            </div>
            <div className="column">
              <CallChart
                transition={this.transitionToCallLogDetails}
              />
              {hasTicketing ? <TicketingCountsWidget
                transitionToNewTicket={this.transitionToNewTicket}
                transitionToAllTickets={this.transitionToAllTickets}
              /> : null}
            </div>
          </Masonry>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    hasTicketing: state.reducer.hasTicketing,
    hasDevices: state.reducer.hasDevices,
    ipTrunkingOnly: state.reducer.isIpTrunkingOnly
  }
}

export default connect(mapStateToProps)(withRouter(Admin));