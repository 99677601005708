import React, { Component } from 'react';
import SuperLink from './../components/super_link';
import BillingChart from './billing_chart';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { fetchData } from './../requests/api_calls';
import PayModal from './modals/payModal';
import PayManageModal from './modals/payManageModal';

var Masonry = require('react-masonry-component');

class Billing extends Component {
  constructor(props) {
		super(props);
		this.state = {
      bill_stat: {
				CustomerName:"",
				CustomerAccountNumber:"",
				Street1BILL:"",
				Street2BILL:"",
				CityBILL:"",
				StateBILL:"",
				ZipBILL:"",
				InvoiceID:"",
				InvoiceDate:"",
				InvoiceDueDate:"",
				InvoiceAmount:"",
				InvoicePaid:"",
				InvoiceName:"",
				InvoiceURL:"",
			},
    };
  }
	componentDidMount() {
    this.fetchBillingStat('/admin/BillingStat');
  }

  fetchBillingStat = (apiPath, errorMsg) => {
    fetchData(apiPath).then((data) => {
      if (data !== null) { 
        this.loadData(data);
      } else if (data === null) {
        this.showNoResultsMessage(errorMsg);
      };
    });
  }
	
  loadData = (data) => {
		data.InvoiceAmount = data.InvoicePaid? 0: data.InvoiceAmount.toLocaleString(undefined, { minimumFractionDigits: 2 });
		data.InvoiceDueDate = (new Date(data.InvoiceDueDate)).toLocaleDateString();
    this.setState({bill_stat: data});
  }

  render() {
    return (
			<div className="billing container">
			<Masonry
				className={'cards'}
				id={'grid'} >
				<div className="card-holder">
					<div className="billing-info card">
						<div className="card-header">
							<h2>Billing</h2>
						</div>
						<div className="card-content">
							<h3>{this.state.bill_stat.CustomerName}</h3>
							<div>
								<b>Account Number: {this.state.bill_stat.CustomerAccountNumber}</b>
							</div>
							<div>
								<b>Billing Address:</b><br />
								{this.state.bill_stat.Street1BILL}<br />
								{this.state.bill_stat.CityBILL}, {this.state.bill_stat.StateBILL} {this.state.bill_stat.ZipBILL} 
							</div>
							<div>
								<a href="">Edit Billing Address</a>
							</div>
							<div>
								<Button>Set Up Auto Pay</Button>
							</div>
						</div>
					</div>
				</div>
				<div className="card-holder">
					<div className="card payment">
						<div className="card-header">
							<PayModal />
							<PayManageModal />
							<h2>Current Payment</h2>
						</div>
						<div className="card-content">
							<div>
								Total Amount Due:
								<h1>${this.state.bill_stat.InvoiceAmount}</h1>
							</div>
							<div>
								Due On:
								<h4> {this.state.bill_stat.InvoiceDueDate}</h4>
							</div>
							<div>
								Past Due:
								<h4>$0.00</h4>
							</div>
							<hr />
							<div>
								<b>Recent Billing Activity:</b><br />
								Previous Balance: $165.75<br />
								Payment - 901: 165.75<br />
								Total Balance Forward: $0.00
							</div>
						</div>
					</div>
				</div>
				<div className="card-holder">
					<div className="card billing-info">
						<div className="card-header">
							<h2>Statements</h2>
						</div>
						<div className="statements card-content">
							<div className="statements-action">
								<div className="filter">
									<select name="" className="form-control">
										<option defaultValue="">1998</option>
										<option defaultValue="">1999</option>
										<option defaultValue="">2000</option>
									</select>
								</div>
							</div>
							<table className="table">
								<thead>
									<tr>
										<th>Billing Period</th>
										<th>Amount Due</th>
										<th>Status</th>
										<th>Confirmation</th>
										<th>View Bill</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>08/02 - 9/01</td>
										<td>$166.75</td>
										<td>Paid</td>
										<td>5AGD153492</td>
										<td><a href=""><svg width="14" height="18" viewBox="0 0 20 20"><path d="m211 317l-26 0l0 47l15 0l0-15l11 0c11 0 17-7 17-16c0-9-6-16-17-16z m-2 20l-9 0l0-8l9 0c2 0 4 1 4 4c0 2-2 4-4 4z m47-20l-21 0l0 47l21 0c15 0 26-9 26-24c0-15-11-23-26-23z m0 35l-7 0l0-23l7 0c8 0 12 5 12 11c0 6-5 12-12 12z m35 12l14 0l0-18l21 0l0-12l-21 0l0-5l22 0l0-12l-36 0z m120-300l0 0l0-21c0-4-4-8-9-8l-168 0l-133 132l0 302c0 4 4 8 9 8l292 0c5 0 9-4 9-8l0-13l0 0z m-259 362l0-241l91 0c5 0 9-4 9-9l0-90l108 0l0 340z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
									</tr>
									<tr>
										<td>08/02 - 9/01</td>
										<td>$166.75</td>
										<td>Paid</td>
										<td>5AGD153492</td>
										<td><a href=""><svg width="14" height="18" viewBox="0 0 20 20"><path d="m211 317l-26 0l0 47l15 0l0-15l11 0c11 0 17-7 17-16c0-9-6-16-17-16z m-2 20l-9 0l0-8l9 0c2 0 4 1 4 4c0 2-2 4-4 4z m47-20l-21 0l0 47l21 0c15 0 26-9 26-24c0-15-11-23-26-23z m0 35l-7 0l0-23l7 0c8 0 12 5 12 11c0 6-5 12-12 12z m35 12l14 0l0-18l21 0l0-12l-21 0l0-5l22 0l0-12l-36 0z m120-300l0 0l0-21c0-4-4-8-9-8l-168 0l-133 132l0 302c0 4 4 8 9 8l292 0c5 0 9-4 9-8l0-13l0 0z m-259 362l0-241l91 0c5 0 9-4 9-9l0-90l108 0l0 340z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
									</tr>
									<tr>
										<td>08/02 - 9/01</td>
										<td>$166.75</td>
										<td>Paid</td>
										<td>5AGD153492</td>
										<td><a href=""><svg width="14" height="18" viewBox="0 0 20 20"><path d="m211 317l-26 0l0 47l15 0l0-15l11 0c11 0 17-7 17-16c0-9-6-16-17-16z m-2 20l-9 0l0-8l9 0c2 0 4 1 4 4c0 2-2 4-4 4z m47-20l-21 0l0 47l21 0c15 0 26-9 26-24c0-15-11-23-26-23z m0 35l-7 0l0-23l7 0c8 0 12 5 12 11c0 6-5 12-12 12z m35 12l14 0l0-18l21 0l0-12l-21 0l0-5l22 0l0-12l-36 0z m120-300l0 0l0-21c0-4-4-8-9-8l-168 0l-133 132l0 302c0 4 4 8 9 8l292 0c5 0 9-4 9-8l0-13l0 0z m-259 362l0-241l91 0c5 0 9-4 9-9l0-90l108 0l0 340z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
									</tr>
									<tr>
										<td>08/02 - 9/01</td>
										<td>$166.75</td>
										<td>Paid</td>
										<td>5AGD153492</td>
										<td><a href=""><svg width="14" height="18" viewBox="0 0 20 20"><path d="m211 317l-26 0l0 47l15 0l0-15l11 0c11 0 17-7 17-16c0-9-6-16-17-16z m-2 20l-9 0l0-8l9 0c2 0 4 1 4 4c0 2-2 4-4 4z m47-20l-21 0l0 47l21 0c15 0 26-9 26-24c0-15-11-23-26-23z m0 35l-7 0l0-23l7 0c8 0 12 5 12 11c0 6-5 12-12 12z m35 12l14 0l0-18l21 0l0-12l-21 0l0-5l22 0l0-12l-36 0z m120-300l0 0l0-21c0-4-4-8-9-8l-168 0l-133 132l0 302c0 4 4 8 9 8l292 0c5 0 9-4 9-8l0-13l0 0z m-259 362l0-241l91 0c5 0 9-4 9-9l0-90l108 0l0 340z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
									</tr>
									<tr>
										<td>08/02 - 9/01</td>
										<td>$166.75</td>
										<td>Paid</td>
										<td>5AGD153492</td>
										<td><a href=""><svg width="14" height="18" viewBox="0 0 20 20"><path d="m211 317l-26 0l0 47l15 0l0-15l11 0c11 0 17-7 17-16c0-9-6-16-17-16z m-2 20l-9 0l0-8l9 0c2 0 4 1 4 4c0 2-2 4-4 4z m47-20l-21 0l0 47l21 0c15 0 26-9 26-24c0-15-11-23-26-23z m0 35l-7 0l0-23l7 0c8 0 12 5 12 11c0 6-5 12-12 12z m35 12l14 0l0-18l21 0l0-12l-21 0l0-5l22 0l0-12l-36 0z m120-300l0 0l0-21c0-4-4-8-9-8l-168 0l-133 132l0 302c0 4 4 8 9 8l292 0c5 0 9-4 9-8l0-13l0 0z m-259 362l0-241l91 0c5 0 9-4 9-9l0-90l108 0l0 340z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
									</tr>
									<tr>
										<td>08/02 - 9/01</td>
										<td>$166.75</td>
										<td>Paid</td>
										<td>5AGD153492</td>
										<td><a href=""><svg width="14" height="18" viewBox="0 0 20 20"><path d="m211 317l-26 0l0 47l15 0l0-15l11 0c11 0 17-7 17-16c0-9-6-16-17-16z m-2 20l-9 0l0-8l9 0c2 0 4 1 4 4c0 2-2 4-4 4z m47-20l-21 0l0 47l21 0c15 0 26-9 26-24c0-15-11-23-26-23z m0 35l-7 0l0-23l7 0c8 0 12 5 12 11c0 6-5 12-12 12z m35 12l14 0l0-18l21 0l0-12l-21 0l0-5l22 0l0-12l-36 0z m120-300l0 0l0-21c0-4-4-8-9-8l-168 0l-133 132l0 302c0 4 4 8 9 8l292 0c5 0 9-4 9-8l0-13l0 0z m-259 362l0-241l91 0c5 0 9-4 9-9l0-90l108 0l0 340z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
									</tr>
									<tr>
										<td>08/02 - 9/01</td>
										<td>$166.75</td>
										<td>Paid</td>
										<td>5AGD153492</td>
										<td><a href=""><svg width="14" height="18" viewBox="0 0 20 20"><path d="m211 317l-26 0l0 47l15 0l0-15l11 0c11 0 17-7 17-16c0-9-6-16-17-16z m-2 20l-9 0l0-8l9 0c2 0 4 1 4 4c0 2-2 4-4 4z m47-20l-21 0l0 47l21 0c15 0 26-9 26-24c0-15-11-23-26-23z m0 35l-7 0l0-23l7 0c8 0 12 5 12 11c0 6-5 12-12 12z m35 12l14 0l0-18l21 0l0-12l-21 0l0-5l22 0l0-12l-36 0z m120-300l0 0l0-21c0-4-4-8-9-8l-168 0l-133 132l0 302c0 4 4 8 9 8l292 0c5 0 9-4 9-8l0-13l0 0z m-259 362l0-241l91 0c5 0 9-4 9-9l0-90l108 0l0 340z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
									</tr>
								</tbody>
							</table>
							<div className="view-more">
								<SuperLink label="View All Statements" href="" />
							</div>
						</div>
					</div>
				</div>
				<div className="card-holder">
					<div className="card balance-history">
						<div className="card-header">
							<h2>Balance History</h2>
						</div>
						<div className="card-content">
							<div className="balance-history-action">
								<div className="filter">
									<input type="text" className="form-control" placeholder="From" />
								</div>
								<div className="filter">
									<input type="text" className="form-control" placeholder="To" />
								</div>
							</div>
							<BillingChart />
						</div>
					</div>
				</div>
			</Masonry>
		</div>
    );
  }
}


export default Billing;