import React, { Component } from "react";
import { DataLoadingSpinner } from "../components/spinners";
import { withRouter } from "react-router-dom";
import "@progress/kendo-theme-default/dist/all.css";
import "../css/bootstrap.css";
import "../css/app.css";
import "../css/spinner.css";
import "../css/msp_login.css";
import * as helper from "../scripts/helper";
import * as config from "../requests/config";
import { tokenRepo } from '../store/redux';
import * as actions from "../store/actions";
import { connect } from "react-redux";




class MSPLogin extends Component {
  constructor(props) {
    super(props);
    this.loadingLogo = "";
    this.emailInput = React.createRef();
    this.state = {
      loginEmail: '',
      loginError: ""
    };

  }

  getCustomizations = async currentUrl => {
    const b2cResults = await helper.getB2CConfigResults(currentUrl);
    const customAuthPolicy = this.preProcessCustomizations(b2cResults);

    await this.setState({ auth: customAuthPolicy, loading: false });
  };

  preProcessCustomizations = b2cResults => {
    let customAuthPolicy = config.auth;

    if (b2cResults) {
      const authority =
        config.authorityUrl +
        b2cResults.B2CPolicy +
        config.defaultXPolicyConfig;
      customAuthPolicy = {
        clientId: b2cResults.B2CClientId,
        authority: authority,
        b2cScopes: []
      };
      const { LogoSmall, LogoLarge, VendorName, BrowserIcon } = b2cResults;
      this.loadingLogo = LogoLarge;
      helper.updateFavicon(BrowserIcon);
      if (!helper.isDigitalServicesDomain()) {
        helper.setTitle(VendorName);
      }
    }
    return customAuthPolicy;
  };

  componentDidMount() {
    this.props.history.push("\session");
    const domain = helper.getDomain();
    this.getCustomizations(domain);
    //this.emailInput.current.focus();
    //this.props.history.push("\session");
  }

  handleLoginEmailChange = (event) => {
    this.setState({ loginEmail: event.target.value });
  }


  handleSubmit = (event) => {

    // SB 7/25/2021: new env variable
    const appName = process.env.REACT_APP_NAME;

    helper.checkIsUserValid(this.state.loginEmail, appName).then(response => {
      if (!response) {
        this.setState({ loginError: "Invalid User" });
        return;
      }
      this.setState({ loginError: "" });
      localStorage.setItem('mspLoginData', JSON.stringify(response));

      this.props.tokenRepo(actions.SET_MSP_LOGIN_DATA, { ...response });
      sessionStorage.setItem("isMspLogin", false);
    });

    event.preventDefault();
  }

  render() {
    if (sessionStorage.redirecting) {
      return (
        <div className="redirect container">
          {/* <img src={loadingLogo} className="App-logo" alt={imageAltText} /> */}
          <DataLoadingSpinner className="redirect spinner" />
        </div>
      );
    } else {
      return (
        <form onSubmit={this.handleSubmit} className='msp-login'>

          <div className="login">

            <img id="msp_logo" className="App-logo" src={this.loadingLogo} />

            <div className="form-holder">
              <div id="api" data-name="Unified">
                <div className="localAccount" aria-label="Sign in with your existing account" role="form">

                  <div className="intro">
                    <h2>Sign in with your existing account</h2>
                  </div>

                  <div className="error pageLevel"  >
                    <p role="alert">{this.state.loginError}</p>
                  </div>

                  <div className="entry">
                    <div className="entry-item">
                      <label htmlFor="logonIdentifier">Email Address</label>

                      <div className="error itemLevel" aria-hidden="true" style={{ display: 'none' }}>
                        <p role="alert" />
                      </div>

                      <input type="email" id="logonIdentifier" name="Email address"
                        pattern="^[a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                        placeholder="Email Address"
                        tabIndex={1}
                        value={this.state.loginEmail}
                        onChange={this.handleLoginEmailChange}
                        ref={this.emailInput}
                      />
                    </div>

                    <div className="working" />

                    {
                      <div className="buttons">
                        <button id="next" type="submit" tabIndex={2}>Sign in</button>
                      </div>
                    }

                  </div>

                </div>
              </div>
            </div>

          </div>
        </form>

      );
    }
  }
}


const mapDispatchToProps = { tokenRepo };

export default withRouter(connect(null, mapDispatchToProps)(MSPLogin));