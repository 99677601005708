import React, { Component } from "react";
import format from "string-format";
import moment from "moment";
import { DatePicker } from "@progress/kendo-dateinputs-react-wrapper";
import * as settings from "../scripts/settings";
import * as config from "../requests/config";
import * as apiCalls from "../requests/api_calls";
import { connect } from "react-redux";
import BSCallLogsTab from "../components/bs_call_logs_tab";
import SansayCallLogsTab from "../components/sansay_call_logs_tab";
import FaxCallLogsTab from "../components/fax_call_logs_tab";

class EnterpriseCallLogsAll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      LocationAccountNumber: "",
      PBXUserID: "",
      BSCallLogs: [],
      SansayCallLogs: [],
      FaxCallLogs: [],
      Sort: [],
      tab: "bs",
      PBXUserID: "",
      loading: false,
      call_category: this.getStoredCallFilters()[2],
      call_type: this.getStoredCallFilters()[0],
      status_type: this.getStoredCallFilters()[1],
      date_type: this.getStoredCallFilters()[5],
      from_date: this.getStoredCallFilters()[3],
      to_date: this.getStoredCallFilters()[4],
      hasIpTrunking: sessionStorage.hasIpTrunk
    };
    this.handleCallCategory = this.handleCallCategory.bind(this);
    this.handleCallType = this.handleCallType.bind(this);
    this.handleStatusType = this.handleStatusType.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleChangeFromDate = this.handleChangeFromDate.bind(this);
    this.handleChangeToDate = this.handleChangeToDate.bind(this);
    this.changeTab = this.changeTab.bind(this);
  }

  getStoredCallFilters = () => {
    let callType = "1";
    let callStatus = "1";
    let callCategory = "1";
    let dateType = "3";
    let { EnterpriseCallCategoryFilter, CallActivityFilters } = sessionStorage;
    let from = moment(Date.now())
      .subtract(7, "days")
      .startOf("day")
      .format("YYYY-MM-DD");
    let to = moment(Date.now())
      .endOf("day")
      .format("YYYY-MM-DD");

    if (CallActivityFilters) {
      let { status, type, fromDate, toDate } = JSON.parse(CallActivityFilters);
      if (type) {
        if (type === "INCOMING") {
          callType = "2";
        } else if (type === "OUTGOING") {
          callType = "3";
        } else {
          callType = "1";
        }
      }
      if (status) {
        if (status === "CONNECTED") {
          callStatus = "2";
        } else if (status === "NO_ANSWER") {
          callStatus = "3";
        } else if (status === "INCOMPLETE") {
          callStatus = "4";
        } else if (status === "FORWARDED") {
          callStatus = "5";
        } else {
          callStatus = "1";
        }
      }
      if (fromDate && toDate) {
        from = fromDate;
        to = toDate;
        let date1 = new Date(fromDate);
        let date2 = new Date(toDate);
        let timeDiff = Math.abs(date2.getTime() - date1.getTime());
        let dayDifference = Math.ceil(timeDiff / (1000 * 3600 * 24));
        if (dayDifference === 6) {
          dateType = "3";
        } else if (dayDifference === 29) {
          dateType = "4";
        } else if (dayDifference === 89) {
          dateType = "5";
        } else {
          dateType = "6";
        }
      }
    }
    if (EnterpriseCallCategoryFilter) {
      if (EnterpriseCallCategoryFilter === "International") {
        callCategory = "2";
      } else if (EnterpriseCallCategoryFilter === "Internal") {
        callCategory = "3";
      } else if (EnterpriseCallCategoryFilter === "Inbound") {
        callCategory = "4";
      } else if (EnterpriseCallCategoryFilter === "Out Local") {
        callCategory = "5";
      } else if (EnterpriseCallCategoryFilter === "Out LD") {
        callCategory = "6";
      } else if (EnterpriseCallCategoryFilter === "Other") {
        callCategory = "7";
      } else {
        callCategory = "1";
      }
    }
    return [callType, callStatus, callCategory, from, to, dateType];
  };

  componentDidMount() {
    this.fetchLocations();
    setTimeout(() => {
      sessionStorage.removeItem("EnterpriseCallCategoryFilter");
      sessionStorage.removeItem("CallActivityFilters");
    }, 3000);
  }

  getApiUrl() {

    let finalToDate = this.state.to_date + '%2023:59:59';
    let newOptions = {
      call_type: this.state.call_type,
      status_type: this.state.status_type,
      call_category: this.state.call_category,
      from_date: this.state.from_date,
      to_date: finalToDate
    };

    let apiUrl = `usr/GetCallLogs?LocationAccountNumber=${this.state.LocationAccountNumber}&PBXUserID=${this.state.PBXUserID}`;
    apiUrl += format("&CallType={call_type}&CallStatus={status_type}&CallCategory={call_category}&FromDate={from_date}&ToDate={to_date}", newOptions);
    return apiUrl;
  }

  fetchCallLogs = async apiPath => {
    let selectedTab = this.state.tab;
    await this.setState({ loading: true });
    apiCalls
      .fetchData(apiPath)
      .then(async data => {
        if (data !== null && data !== undefined) {
          let { BSCallLogs, SansayCallLogs, FDRCallLogs } = data;
          let CallStatus = this.state.status_type;
          if (BSCallLogs.length === 0 && SansayCallLogs.length > 0) {
            selectedTab = "sansay";
          }
          if (SansayCallLogs.length === 0 && BSCallLogs.length > 0) {
            selectedTab = "bs";
          }
          if (BSCallLogs.length === 0 && SansayCallLogs.length === 0 && FDRCallLogs.length > 0) {
            selectedTab = 'fax';
            CallStatus = this.getStoredCallFilters()[1];
          }

          let revisedBSCallLogs = this.revisedData(BSCallLogs);
          let revisedSansayLogs = this.revisedData(SansayCallLogs, true);
          let revisedFaxLogs = this.revisedData(FDRCallLogs, false, true);

          await this.setState({
            BSCallLogs: revisedBSCallLogs,
            SansayCallLogs: revisedSansayLogs,
            FaxCallLogs: revisedFaxLogs,
            status_type: CallStatus,
            loading: false,
            tab: selectedTab
          });
        } else
          this.setState({
            BSCallLogs: [],
            SansayCallLogs: [],
            FaxCallLogs: [],
            logs: [],
            loading: false
          });
      })
      .catch(msg => console.error(msg));
  };

  revisedData = (data, isIPTrunking = false, isFax = false) => {
    let revisedData = [];
    if (data && data.length > 0) {
      if (isIPTrunking)
        revisedData = data.map((item, idx) => {
          item.Release_Time = new Date(item.Release_Time);
          item.Start_Time = new Date(item.Start_Time);
          return item;
        });
      else if (isFax)
        revisedData = data.map((item, idx) => {
          item.StartTime = new Date(item.StartTime);
          return item;
        });
      else
        revisedData = data.map((item, idx) => {
          item.Date = new Date(item.Date);
          return item;
        });
    }

    return revisedData;
  };

  handleChangeFromDate = event => {
    let from = moment(event.sender._oldText, "M/D/YYYY");
    if (from.isValid()) {
      this.setState({ ...{ from_date: from.format("YYYY-MM-DD") } }, () => {
        let to = moment(this.state.to_date);
        if (to.isValid() && to.isSameOrAfter(from)) {
          this.setState(
            {
              ...{
                SansayCallLogs: [],
                BSCallLogs: [],
                FaxCallLogs: [],
                loading: true
              }
            },
            this.componentDidMount
          );
        }
      });
    }
  };

  handleChangeToDate = event => {
    let to = moment(event.sender._oldText, "M/D/YYYY");
    if (to.isValid()) {
      this.setState({ ...{ to_date: to.format("YYYY-MM-DD") } }, () => {
        let from = moment(this.state.from_date);
        if (from.isValid() && from.isSameOrBefore(to)) {
          this.setState(
            {
              ...{
                SansayCallLogs: [],
                BSCallLogs: [],
                FaxCallLogs: [],
                loading: true
              }
            },
            this.componentDidMount
          );
        }
      });
    }
  };

  handleDate = event => {
    switch (event.target.value) {
      case "1":
        this.setState(
          {
            ...{
              SansayCallLogs: [],
              BSCallLogs: [],
              FaxCallLogs: [],
              date_type: event.target.value,
              from_date: moment(Date.now())
                .startOf("day")
                .format("YYYY-MM-DD"),
              to_date: moment(Date.now())
                .endOf("day")
                .format("YYYY-MM-DD"),
              loading: true
            }
          },
          this.componentDidMount
        );
        return;
      case "2":
        this.setState(
          {
            ...{
              SansayCallLogs: [],
              BSCallLogs: [],
              FaxCallLogs: [],
              date_type: event.target.value,
              from_date: moment(Date.now())
                .subtract(1, "days")
                .startOf("day")
                .format("YYYY-MM-DD"),
              to_date: moment(Date.now())
                .endOf("day")
                .subtract(1, "days")
                .format("YYYY-MM-DD"),
              loading: true
            }
          },
          this.componentDidMount
        );
        return;
      case "3":
        this.setState(
          {
            ...{
              SansayCallLogs: [],
              BSCallLogs: [],
              FaxCallLogs: [],
              date_type: event.target.value,
              from_date: moment(Date.now())
                .subtract(7, "days")
                .startOf("day")
                .format("YYYY-MM-DD"),
              to_date: moment(Date.now())
                .endOf("day")
                .format("YYYY-MM-DD"),
              loading: true
            }
          },
          this.componentDidMount
        );
        return;
      case "4":
        this.setState(
          {
            ...{
              SansayCallLogs: [],
              BSCallLogs: [],
              FaxCallLogs: [],
              date_type: event.target.value,
              from_date: moment(Date.now())
                .subtract(30, "days")
                .startOf("day")
                .format("YYYY-MM-DD"),
              to_date: moment(Date.now())
                .endOf("day")
                .format("YYYY-MM-DD"),
              loading: true
            }
          },
          this.componentDidMount
        );
        return;
      case "5":
        this.setState(
          {
            ...{
              SansayCallLogs: [],
              BSCallLogs: [],
              FaxCallLogs: [],
              date_type: event.target.value,
              from_date: moment(Date.now())
                .subtract(90, "days")
                .startOf("day")
                .format("YYYY-MM-DD"),
              to_date: moment(Date.now())
                .endOf("day")
                .format("YYYY-MM-DD"),
              loading: true
            }
          },
          this.componentDidMount
        );
        return;
      case "6":
        this.setState({
          ...{
            SansayCallLogs: [],
            BSCallLogs: [],
            FaxCallLogs: [],
            date_type: event.target.value,
            from_date: "From",
            to_date: "To"
          }
        });
        return;
    }
  };

  handleCallCategory = event => {
    this.setState(
      {
        ...{
          SansayCallLogs: [],
          BSCallLogs: [],
          FaxCallLogs: [],
          call_category: event.target.value,
          loading: true
        }
      },
      this.componentDidMount
    );
  };

  handleCallType = event => {
    this.setState(
      {
        ...{
          SansayCallLogs: [],
          BSCallLogs: [],
          FaxCallLogs: [],
          call_type: event.target.value,
          loading: true
        }
      },
      this.componentDidMount
    );
  };

  handleStatusType = event => {
    this.setState(
      {
        ...{
          SansayCallLogs: [],
          BSCallLogs: [],
          FaxCallLogs: [],
          status_type: event.target.value,
          loading: true
        }
      },
      this.componentDidMount
    );
  };

  exportSansayBtn = () => {
    if ((this.state.tab === "sansay" && this.state.SansayCallLogs.length > 0) || (this.state.tab === "sansay" && sessionStorage.callActivityCount > 60000)) {
      return (
        <div id="div-calls-csv" className="pull-right">
          <form
            ref="downloadCsv"
            target="iFrameSave"
            action={config.baseApiUrl + "usr/GetSansayCallLogsCSV"}
            method="POST"
          >
            <input
              type="hidden"
              name="LocationAccountNumber"
              value={this.state.LocationAccountNumber}
            ></input>
            <input type="hidden" name="TrunkID" value={this.props.trunkId} />
            <input
              type="hidden"
              name="CallType"
              value={this.state.call_type}
            ></input>
            <input
              type="hidden"
              name="CallStatus"
              value={this.state.status_type}
            ></input>
            <input
              type="hidden"
              name="CallCategory"
              value={this.state.call_category}
            ></input>
            <input
              type="hidden"
              name="FromDate"
              value={this.state.from_date}
            ></input>
            <input
              type="hidden"
              name="ToDate"
              value={this.state.to_date}
            ></input>
            <input
              type="hidden"
              name="access_token"
              value={config.storedAccessToken()}
              className="hidden"
            ></input>
            <input
              type="hidden"
              name="x_policy"
              value={config.storedXPolicy()}
              className="hidden"
            ></input>
          </form>
          <iframe
            name="iFrameSave"
            height="0"
            width="0"
            className="hidden pull-right"
          ></iframe>
          <a
            tabIndex="0"
            className="no-btn"
            ref="exportBtn"
            onClick={e => this.exportCallLogs()}
          >
            Export Call Logs
          </a>
        </div>
      );
    } else return null;
  };

  exportBsBtn = () => {
    if ((this.state.tab === "bs" && this.state.BSCallLogs.length > 0) || (this.state.tab === "bs" && sessionStorage.callActivityCount > 60000)) {
      return (
        <div id="div-calls-csv" className="pull-right">
          <form
            ref="downloadCsv"
            target="iFrameSave"
            action={config.baseApiUrl + "usr/GetBSCallLogsCSV"}
            method="POST"
          >
            <input
              type="hidden"
              name="LocationAccountNumber"
              value={this.state.LocationAccountNumber}
            ></input>
            <input
              type="hidden"
              name="PBXUserID"
              value={this.state.PBXUserID}
            />
            <input
              type="hidden"
              name="CallType"
              value={this.state.call_type}
            ></input>
            <input
              type="hidden"
              name="CallStatus"
              value={this.state.status_type}
            ></input>
            <input
              type="hidden"
              name="CallCategory"
              value={this.state.call_category}
            ></input>
            <input
              type="hidden"
              name="FromDate"
              value={this.state.from_date}
            ></input>
            <input
              type="hidden"
              name="ToDate"
              value={this.state.to_date}
            ></input>
            <input
              type="hidden"
              name="access_token"
              value={config.storedAccessToken()}
              className="hidden"
            ></input>
            <input
              type="hidden"
              name="x_policy"
              value={config.storedXPolicy()}
              className="hidden"
            ></input>
          </form>
          <iframe
            name="iFrameSave"
            height="0"
            width="0"
            className="hidden pull-right"
          ></iframe>
          <a
            tabIndex="0"
            className="no-btn"
            ref="exportBtn"
            onClick={e => this.exportCallLogs()}
          >
            Export Call Logs
          </a>
        </div>
      );
    } else return null;
  };

  exportFaxBtn = () => {
    if ((this.state.tab === "fax" && this.state.FaxCallLogs.length > 0) || (this.state.tab === "fax" && sessionStorage.callActivityCount > 60000)) {
      return (
        <div id="div-calls-csv" className="pull-right">
          <form
            ref="downloadCsv"
            target="iFrameSave"
            action={config.baseApiUrl + "usr/GetFaxCallLogsCSV"}
            method="POST"
          >
            <input
              type="hidden"
              name="LocationAccountNumber"
              value={this.state.LocationAccountNumber}
            ></input>
            <input
              type="hidden"
              name="ServiceNumber"
              value={''}
            ></input>
            <input
              type="hidden"
              name="CallType"
              value={this.state.call_type}
            ></input>
            <input
              type="hidden"
              name="CallStatus"
              value={this.state.status_type}
            ></input>
            <input
              type="hidden"
              name="CallCategory"
              value={this.state.call_category}
            ></input>
            <input
              type="hidden"
              name="FromDate"
              value={this.state.from_date}
            ></input>
            <input
              type="hidden"
              name="ToDate"
              value={this.state.to_date}
            ></input>
            <input
              type="hidden"
              name="access_token"
              value={config.storedAccessToken()}
              className="hidden"
            ></input>
            <input
              type="hidden"
              name="x_policy"
              value={config.storedXPolicy()}
              className="hidden"
            ></input>
          </form>
          <iframe
            name="iFrameSave"
            height="0"
            width="0"
            className="hidden pull-right"
          ></iframe>
          <a
            tabIndex="0"
            className="no-btn"
            ref="exportBtn"
            onClick={e => this.exportCallLogs()}
          >
            Export Call Logs
          </a>
        </div>
      );
    } else return null;
  };

  exportCallLogs = () => {
    this.refs.downloadCsv.submit();
    this.refs.exportBtn.text = "Downloading...";
    setTimeout(() => {
      this.refs.exportBtn.text = "Export Call Logs";
    }, 2000);
  };

  fetchLocations = async (apiPath = null) => {
    await this.setState({ loading: true });
    if (!apiPath) apiPath = "admin/Locations";
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        let locationOptions = data.map((location, index) => {
          return this.locationOption(location, index);
        });
        this.setState({ locationOptions: locationOptions, PBXUserID: "" });
        if (sessionStorage.callActivityCount < 60000) {
          //alert('Aboce 60000');
          this.fetchCallLogs(this.getApiUrl(), settings.noResultsMsg);
        }
      } else if (data === null) {
        this.setState({ locations: [], resultsMsg: settings.noResultsMsg });
      }
    });
  };

  changeLocation = event => {
    let { value } = event.target;
    let getLocationName = acctNum => {
      return this.state.locationOptions
        .map(itm => itm.props)
        .filter(res => res.value === acctNum)[0].children;
    };
    this.setState(
      {
        LocationAccountNumber: value,
        LocationName: event.target.selectedOptions
          ? event.target.selectedOptions[0].text
          : getLocationName(value)
      },
      this.componentDidMount
    );
  };

  locationOption = (location, index) => {
    return (
      <option key={index} value={location.LocationAccountNumber}>
        {location.LocationName}
      </option>
    );
  };

  callLogFilter = htmlSelect => {
    return (
      <div
        className="filter"
        style={{
          paddingRight: "4px",
          float: "left",
          width: "20%"
        }}
      >
        {htmlSelect}
      </div>
    );
  };

  tabDisplay = () => {
    if (
      (this.state.BSCallLogs && this.state.BSCallLogs.length > 0) ||
      (this.state.SansayCallLogs && this.state.SansayCallLogs.length > 0) ||
      (this.state.FaxCallLogs && this.state.FaxCallLogs.length > 0) ||
      sessionStorage.callActivityCount > 60000
    ) {
      return (
        <div className="tabs" style={{ float: "left", width: "100%" }}>
          <a
            style={{ display: "inline-block" }}
            className={this.state.tab === "bs" ? "active tab" : "tab"}
            data-act="bs"
            onClick={this.changeTab}
          >
            Cloud Voice
          </a>
          <a
            style={{
              display: this.state.hasIpTrunking && this.state.hasIpTrunking.toString() === 'true' ? "inline-block" : "none"
            }}
            className={this.state.tab === "sansay" ? "active tab" : "tab"}
            data-act="sansay"
            onClick={this.changeTab}
          >
            IP Trunking
          </a>
          <a
            className={this.state.tab === "fax" ? "active tab" : "tab"}
            data-act="fax"
            onClick={this.changeTab}
          >
            Fax
          </a>
          <div className="col-md-4 pull-right export">
            {this.exportSansayBtn()}
            {this.exportBsBtn()}
            {this.exportFaxBtn()}
          </div>
        </div>
      );
    } else return;
  };

  changeTab = event => {
    if (event.target.dataset.act !== this.state.tab) {
      this.setState({ tab: event.target.dataset.act });
    }
  };

  getTab() {
    if (sessionStorage.callActivityCount > 60000) {
      return (
        <div style={{
          display: 'block',
          height: '100px',
          overflow: 'hidden',
          width: '100%',
          paddingTop: '10px',
          textAlign: 'center'
        }}>Due to the large volume of call records, please use the export tool</div>
      )
    }
    let { tab, BSCallLogs, SansayCallLogs, FaxCallLogs, loading } = this.state;
    switch (tab) {
      case "bs":
        return <BSCallLogsTab BsLogs={BSCallLogs} isLoading={loading} />;
      case "sansay":
        return (
          <SansayCallLogsTab SansayLogs={SansayCallLogs} isLoading={loading} />
        );
      case "fax":
        return (
          <FaxCallLogsTab FaxCallLogs={FaxCallLogs} isLoading={loading} />
        );
      default:
        console.log("Sorry there isn't a tab named " + tab + ".");
    }
  }

  headerTag = () => {
    let { loading, SansayCallLogs, BSCallLogs, tab } = this.state;
    if (!loading && SansayCallLogs && tab === "sansay") {
      return SansayCallLogs.length;
    } else if (!loading && BSCallLogs && tab === "bs") {
      return BSCallLogs.length;
    } else return;
  };

  render() {
    return (
      <div className="full-card container">
        <div className="ip-call-logs card">
          <div className="card-header">
            <div className="col-md-8 row">
              <h1 className="move">
                Enterprise Call Logs
                <small> {this.headerTag()}</small>
              </h1>
            </div>

            <div className="call-log-actions card-content col-md-12">
              <div className="call-log-action">
                {this.callLogFilter(
                  <select
                    onChange={this.changeLocation}
                    value={this.state.LocationAccountNumber}
                    className="form-control"
                  //disabled={localStorage.AccountNumber && this.props.PBXUserID ? true : false}
                  >
                    <option value="">All Locations</option>
                    {this.state.locationOptions}
                  </select>
                )}
                {this.callLogFilter(
                  <select
                    value="1"
                    className="form-control"
                    value={this.state.call_category}
                    onChange={this.handleCallCategory}
                  >
                    <option value="1">All Calls</option>
                    <option value="2">International</option>
                    <option value="3">Internal</option>
                    <option value="4">Inbound</option>
                    <option value="5">Out Local</option>
                    <option value="6">Out LD</option>
                    <option value="7">Other</option>
                  </select>
                )}
                {this.callLogFilter(
                  <select
                    value="1"
                    className="form-control"
                    value={this.state.call_type}
                    onChange={this.handleCallType}
                  >
                    <option value="1">All Calls</option>
                    <option value="2">Incoming</option>
                    <option value="3">Outgoing</option>
                  </select>
                )}
                {this.callLogFilter(
                  <select
                    disabled={this.state.tab === 'fax' ? 'disabled' : ''}
                    value="1"
                    className="form-control"
                    value={this.state.status_type}
                    onChange={this.handleStatusType}
                  >
                    <option value="1">All Calls</option>
                    <option value="2">Connected</option>
                    <option value="3">No Answer</option>
                    <option value="4">Incomplete</option>
                    <option value="5">Forwarded</option>
                  </select>
                )}
                {this.callLogFilter(
                  <select
                    className="call-log-dates form-control"
                    value={this.state.date_type}
                    onChange={this.handleDate}
                  >
                    <option value="1">Today</option>
                    <option value="2">Yesterday</option>
                    <option value="3">Last 7 Days</option>
                    <option value="4">Last 30 Days</option>
                    <option value="5">Last 90 Days</option>
                    <option value="6">Custom</option>
                  </select>
                )}
                {this.state.date_type === "6" ? (
                  <div className="custom-dates">
                    <div className="date">
                      <DatePicker
                        value={this.state.from_date}
                        change={this.handleChangeFromDate}
                      />
                    </div>
                    <div className="date">
                      <DatePicker
                        value={this.state.to_date}
                        change={this.handleChangeToDate}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {this.tabDisplay()}
            </div>
          </div>
          <div className="tab-body">
            {this.getTab()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    PBXUserID: state.PBXUserID
  };
};

export default connect(mapStateToProps)(EnterpriseCallLogsAll);