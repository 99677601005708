import React, { Component } from 'react';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { headerToolTip } from '../../components/ellipsisHeaderTooltip';
import { DataLoadingSpinner } from '../../components/spinners';
import * as apiCalls from '../../requests/api_calls';
import EditForm from './components/edit_applist_dialog';

const CellEditButton =  (props) => {

  return (
      <td align="right" className="actions">
        <Button className="icon btn" onClick={() => props.handleEditButton(props)}>
          <svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg>
        </Button>
      </td>
  )}

class AppList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerIdpApps: [],
      loading: false,
      Sort: [{ field: "", dir: "desc" }],
      filter: { logic: "and", filters: [] },
      openDialog: false,
      isEditMode: false,
      editItem: {},
      hasEmptyData: []
    }
  }

  componentDidMount() {
    this.fetchCustomerIdp();
    this.fetchCustomerIdpApps();
  }

  fetchCustomerIdp = async () => {
    let path = 'admin/GetCustomerIdp';
    await apiCalls.fetchData(path).then(data => data).catch(err => console.error(err));
  }

  fetchCustomerIdpApps = async () => {
    this.setState({ loading: true })
    let path = 'admin/GetCustomerIdpApps?CustomerIdPID=2';
    await apiCalls.fetchData(path).then(data => {
      if (data) {
        console.log("DATA", data);
        let filteredcustomerIdpApps = data.filter(obj => obj.IdPAppClientId && obj.IdPAppClientKey)
        let filteredEmptyList = data.filter(obj => !obj.IdPAppClientId && !obj.IdPAppClientKey)
        this.setState({
          customerIdpApps: filteredcustomerIdpApps,
          loading: false,
          hasEmptyData: filteredEmptyList
        });
      } else {
        this.setState({
          customerIdpApps: [],
          loading: false
        });
      }
    }).catch(err => console.error(err));
    //console.log(customerIdpApps, 'customerIdpApps')
  }

  handleAddItem = () => {
    let defaultIdpAppName = this.state.hasEmptyData[0]?.IdPAppName || '';
    this.setState({
      openDialog: true,
      isEditMode: false,
      editItem: {
        ConfigJSON: null,
        CustomerIdPID: 2,
        IdPAppClientId: "",
        IdPAppClientKey: "",
        IdPAppName: defaultIdpAppName,
        Jwk: null
      },
    });
  }

  gridContent = () => {
    let { Sort, customerIdpApps } = this.state;
    if (customerIdpApps && customerIdpApps.length > 0) {

      return (
        <>
          <Grid
            style={{
              width: '100%',
            }}
            data={orderBy(customerIdpApps, Sort)}
            sortable={{ allowUnsort: true, mode: 'single' }}
            sort={Sort}
            onSortChange={this.handleSortChange}
          >
            <Column field="IdPAppName" title={headerToolTip("Idp App Name")} />
            <Column field="IdPAppClientId" title={headerToolTip("Idp App Client ID")} />
            <Column field="IdPAppClientKey" title={headerToolTip("Idp App Client Key")} />
            <Column cell={(props) => <CellEditButton handleEditButton={this.handleEditButton} {...props}/>} title={headerToolTip("Edit")} sortable={false} filterable={false} width="60px" />
          </Grid>
        </>
      )
    } else {
      return <p style={{ textAlign: 'center', paddingTop: '15px' }}>No Available Data</p>
    }
  }

  handleSortChange = (e) => {
    this.setState({
      customerIdpApps: orderBy(this.state.customerIdpApps, e.sort),
      Sort: e.sort
    });
  }

  handleFilterChange = (event) => {
    this.setState({
      filter: event.filter
    });
  }

  handleEditButton = (props) => {
    this.setState({
      openDialog: true,
      isEditMode: true,
      editItem: props.dataItem,
    });
  }

  handleCancelEdit = () => {
    this.setState({
      openDialog: false, 
      isEditMode: false
    })
  }

  updateCustomerIdpData = async (data) => {
    console.log(data)
    let path = 'admin/SaveCustomerIdpApp';
    await apiCalls.post(path, 'POST', JSON.stringify(data))
    // let updatedData = this.state.customerIdpApps.map(item => {
    //   if(item.id){

    //   }
    // })
    // console.log(updatedData)
  }

  handleSubmit = (data) => {
    this.updateCustomerIdpData(data);
  }

  render() {
    let { loading } = this.state;
    if (loading) {
      return <DataLoadingSpinner style={{ marginTop: "3%" }} className='load spinner' />
    }
    return (
      <div className="card-content"
      >
        <div className='add-button'>
          {this.state.hasEmptyData.length > 0 && <Button onClick={this.handleAddItem}>Add New App</Button>}
        </div>
        {this.gridContent()}
        <EditForm 
          cancelEdit={this.handleCancelEdit}
          onSubmit={this.handleSubmit}
          data={this.state.editItem}
          isEditMode={this.state.isEditMode}
          showModal={this.state.openDialog} 
          customerIdpAppList={this.state.isEditMode ? this.state.customerIdpApps : this.state.hasEmptyData }
        />
      </div>
    )
  }
}
export default AppList;

