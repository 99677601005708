import React from 'react';
import { Chart } from '@progress/kendo-charts-react-wrapper';

const chartOptions = {
    // title: {
    //     text: "Site Visitors Stats \n /thousands/"
    // },
    legend: {
        visible: false
    },
    seriesDefaults: {
        type: "column"
    },
    series: [{
        name: "Billing",
        data: [27, 35, 14, 19, 34, 40, 10, 5, 2,],
        color: "#00b5e1"
    }],
    valueAxis: {
        line: {
            visible: false
        },
        axisCrossingValue: 0
    },
    categoryAxis: {
        categories: [new Date("01/05"), new Date("01/06"), new Date("01/07"), new Date("01/08"), new Date("01/09"), new Date("01/010"), new Date("01/11")],
        line: {
            visible: false
        },
        labels: {
        }
    },
    tooltip: {
        visible: false,
        // template: "<span style='padding: 3px;color: #fff;'> #= series.name #: #= value # </span>"
        // template: "<span style='padding: 3px;color: #fff;'>${series.name}</span> - ${value}"
    }
};




const BillingChart = () => (
	<div className="activity card">
		<Chart {...chartOptions} />
	</div>
)

export default BillingChart;