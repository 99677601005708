import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchData } from "./../../requests/api_calls";
import { store } from "./../../store/store";
import { connect } from "react-redux";
import { userIsAdmin, userIsContactManager } from './../../scripts/helper';
import * as actions from "./../../store/actions";
import * as icons from "./../../scripts/icons";
import * as session from "../../scripts/session";
import { checkBillingAccount } from '../../utility/utility';
import * as apiCalls from '../../requests/api_calls';

const IsWholesaleUser = session.IsWholesale();

class AdminMenu extends Component {
  constructor() {
    super();
    this.state = {
      hasTrunking: false,
      hasContactCenter: false,
      hasMacd: false,
      hasDevices: false,
      hasIpTrunk: false,
      hasTicketing: 'NONE',
      hasVmUsageReport: false,
      Header: 'Admin Tools',
      hasAtLeastOneAdminLink: false,
      permissionsRetrieved: false
    };
  }

  componentDidMount() {
    // avoids 401 error by not calling for non admin
    //TODO - limit to one API call for access to various pages
    if (userIsAdmin()) {
      this.buildPermissions();
      this.setState({ hasAtLeastOneAdminLink: true });
    } else {
      this.buildSubscriberPermissions();
    }
  }

  componentWillReceiveProps = (props) => {
    let state = this.state;
    if (
      //((sessionStorage.userTypeId === "1107" || sessionStorage.userTypeId === "1105") && !sessionStorage.SelectedUserId) || window.location.href.indexOf("/MSP_ENTERPRISE") > -1 ? "admin/EnterpriseTC" : `usr/ServiceTC?serviceNumber=${sessionStorage.ServiceNumber}`;
      state.hasContactCenter !== props.hasContactCenter ||
      state.hasTrunking !== props.hasTrunking ||
      state.hasDevices !== props.hasDevices ||
      state.hasDevices !== props.hasDevices ||
      state.hasMacd !== props.hasMacd ||
      state.hasIpTrunk !== props.hasIpTrunk ||
      state.hasTicketing !== props.hasTicketing ||
      state.permissionsRetrieved !== props.permissionsRetrieved
    ) {
      this.setState({
        hasContactCenter: props.hasContactCenter,
        hasTrunking: props.hasTrunking,
        hasMacd: props.hasMacd,
        hasDevices: props.hasDevices,
        hasIpTrunk: props.hasIpTrunk,
        hasTicketing: props.hasTicketing,
        permissionsRetrieved: props.permissionsRetrieved
      });
    }
  }

  
  buildSubscriberPermissions = async () => {
    //let [contactPerms, ticketingPerms] = await this.getNonAdminPermissions();
    let hasAtLeastOneAdminLink = false;
    try {
      let callRecording = await this.hasCallRecording();
      if(callRecording=== true)
      {
        sessionStorage.setItem('hasCallRecording', true);
      }
      else
      sessionStorage.setItem('hasCallRecording', false);
    }
    catch(ex){
      console.log(ex);
      sessionStorage.setItem('hasCallRecording', false);
    }
    try {
      let contactPerms = await this.hasContactCenter();

      if (contactPerms !== null) {
        sessionStorage.setItem('hasContactCenter', true);
        hasAtLeastOneAdminLink = true;
      }
      else
        sessionStorage.setItem('hasContactCenter', false);
    } catch (ex) {
      console.log(ex);
      sessionStorage.setItem('hasContactCenter', false);
    }
    try {
      let ticketingPerms = await this.hasTicketing();
      if (ticketingPerms && !IsWholesaleUser) {
        sessionStorage.setItem('hasTicketing', ticketingPerms);
        if (ticketingPerms !== 'NONE')
          hasAtLeastOneAdminLink = true;
      }
      else
        sessionStorage.setItem('hasTicketing', 'NONE');
    } catch (ex) {
      console.log(ex);
      sessionStorage.setItem('hasTicketing', 'NONE');
    }
    try {
      if (sessionStorage.IsWholesale === "true") {
        // wrote condition this way (instead of using IsWholesaleUser var at the top) because there seems to be async issue when the string is converted to a boolean
        hasAtLeastOneAdminLink = true;
      }
    } catch (ex) {
      console.log(ex);
    }
    await this.setState({ hasAtLeastOneAdminLink: hasAtLeastOneAdminLink });
    //Start Locations
    //sessionStorage.setItem('locationList', JSON.stringify(locations));
    await this.setState({ Header: 'Admin Tools' }); //setting so it doesn't show 'Admin' Tools to non-administrators
    store.dispatch({ type: actions.PERMISSIONS_UPDATED });
  }

  buildPermissions = async () => {
    var [trunkingPerms, contactPerms, macdPerms, devicePerms, ipTrunkPerms, ticketingPerms, vmReportPerm, entTrunkPermission, adPerm, msTeams,callRecording] = await this.getAdminPermissions();
    //Start Locations
    fetchData('admin/Locations').then((locations) => {
      sessionStorage.setItem('locationList', JSON.stringify(locations));
      let ipTrunkTest = locations.filter(resp => resp.IsIPTrunking === false);
      if (!ipTrunkTest || ipTrunkTest.length === 0)
        sessionStorage.setItem('isIpTrunkingOnly', true)

      //this.setState({permissionsRetrieved: true});
      store.dispatch({ type: actions.IP_TRUNKING_ONLY_SET });
    })
      .catch(
        (err) => {
          console.error(err);
          store.dispatch({ type: actions.IP_TRUNKING_ONLY_SET });
        });
    //End Locations

    //Start SIP Trunking
    if (trunkingPerms)
      sessionStorage.setItem('hasTrunk', trunkingPerms.HasTrunking);
    else
      sessionStorage.setItem('hasTrunk', false);
    //End SIP Trunking

    //Start Contact Center
    if (contactPerms)
      sessionStorage.setItem('hasContactCenter', contactPerms.HasContactCenter);
    else
      sessionStorage.setItem('hasContactCenter', false);
    //End Contact Center

    //Start MACD
    // if any MACD Permissions equal 1, then show the "Service Changes" link
    // unless permissions that are not valid for Wholesale users are included
    let permsObj = {
      addServices: (macdPerms && macdPerms.Add_Services && !IsWholesaleUser) ? macdPerms.Add_Services : 0,
      assignUserAddons: (macdPerms && macdPerms.Assign_UserAddons)
        ? macdPerms.Assign_UserAddons
        : 0,
      changeTfTerminatingNumber: 0,
      changeVnTerminatingNumber: macdPerms && macdPerms.ChangeVN_TerminatingNumber
        ? macdPerms.ChangeVN_TerminatingNumber
        : 0,
      changeAddress: 0,
      changeBillingInfo: 0,
      changeBlock: macdPerms && macdPerms.Change_Block ? macdPerms.Change_Block : 0,
      changeCNAM: macdPerms && macdPerms.Change_CNAM ? macdPerms.Change_CNAM : 0,
      changeCallPathCount: 0,
      changeExtension: (macdPerms && macdPerms.Change_Extension && !IsWholesaleUser)
        ? macdPerms.Change_Extension
        : 0,
      changeLineType: (macdPerms && macdPerms.Change_LineType && !IsWholesaleUser)
        ? macdPerms.Change_LineType
        : 0,
      changeListing: (macdPerms && macdPerms.Change_Listing && !IsWholesaleUser)
        ? macdPerms.Change_Listing
        : 0,
      changeSeatType: (macdPerms && macdPerms.Change_SeatType && !IsWholesaleUser)
        ? macdPerms.Change_SeatType
        : 0,
      disconnectServices: 0,
      unassignUserAddons: (macdPerms && macdPerms.Unassign_UserAddons)
        ? macdPerms.Unassign_UserAddons
        : 0,
      ipTrunkCallPathBurst: macdPerms && macdPerms.Change_IPTrunking_CallPathPlan_and_Count ? macdPerms.Change_IPTrunking_CallPathPlan_and_Count : 0,
    };
    sessionStorage.setItem("MacdPerms", JSON.stringify(permsObj));
    //End MACD

    //Start Devices
    let showDevicesLink = this.hasAtLeastOneNepsPermission(devicePerms);
    if (showDevicesLink) {
      // if any NEPS permissions are 1, show the "Devices" link
      sessionStorage.setItem("DevicePerms", JSON.stringify(devicePerms));
      sessionStorage.setItem("hasDevices", true);
    } else {
      sessionStorage.removeItem("DevicePerms");
      sessionStorage.setItem("hasDevices", false);
    }
    //End Devices

    //Start IP Trunking 
    if (ipTrunkPerms && ipTrunkPerms.HasTrunking)
      sessionStorage.setItem('hasIpTrunk', ipTrunkPerms.HasTrunking);
    else
      sessionStorage.setItem('hasIpTrunk', false);
    //End IP Trunking

    // Start Ticketing
    // Returns "NONE", "READONLY", or "FULL". ***
    // I did some quick testing with new ticketing permission.
    // For a SuperAdmin all works as before.
    // But, for a user login you should probably call the new API to get permission, and if not "NONE" you should add the ticketing menu to the left menu bar.
    // Then enable update/create/delete operations on tickets only of the permission is "FULL".
    if (ticketingPerms && !IsWholesaleUser)
      sessionStorage.setItem('hasTicketing', ticketingPerms);
    else
      sessionStorage.setItem('hasTicketing', 'NONE');
    //End Ticketing

    // Start VM Usage Report
    if (vmReportPerm.length > 0 && vmReportPerm[0]) {
      sessionStorage.setItem('hasVmUsageReport', vmReportPerm[0].ShowVoiceMailReport);
    } else {
      sessionStorage.setItem('hasVmUsageReport', false);
    }
    // End VM Usage Report

    //Start Ent Trunking
    if (entTrunkPermission.length > 0 && entTrunkPermission[0]) {
      sessionStorage.setItem('hasEntTrunking', entTrunkPermission[0].ShowEnterpriseTrunk);
    } else {
      sessionStorage.setItem('hasEntTrunking', false);
    }
    //End Ent Trunking
    //Start AD Sync
    if (adPerm) {
      //console.log("PERM", adPerm);
      if (adPerm.length > 0 && adPerm[0]) {
        sessionStorage.setItem('hasActiveDirectory', adPerm[0].ShowActiveDirectory);
        sessionStorage.setItem('AdSourceSystem', adPerm[0].SourceSystem);
      } else {
        sessionStorage.setItem('hasActiveDirectory', false);
      }
    }
    if (msTeams) {
      sessionStorage.setItem('hasTeams', true);
    }
    if(callRecording){
      sessionStorage.setItem('hasCallRecording',true)
    }
    
    //End AD Sync
    store.dispatch({ type: actions.PERMISSIONS_UPDATED });
  }

  getAdminPermissions =async () => {

    let requests = [
      this.hasTrunking(),
      this.hasContactCenter(),
      this.hasMacd(),
      this.hasDevices(),
      this.hasIpTrunk(),
      this.hasTicketing(),
      this.hasVmUsageReport(),
      this.hasEnterpriseTrunking(),
      this.hasActiveDirectory(),
      this.hasMsTeams(),
      this.hasCallRecording()      
    ];
    return await Promise.all(requests).then(dataArray => dataArray).catch(err => console.error(err));
  }

  hasEnterpriseTrunking = () => {
    let apiPath = 'admin/EntTrunkPermission';
    return fetchData(apiPath).then(data => data).catch(err => console.error(err));
  }

  hasActiveDirectory = () => {
    return fetchData('admin/GetADPermission').then(data => data).catch(err => console.error(err));
  }

  hasVmUsageReport = () => {
    let apiPath = 'vm/VmReportPermission';
    return fetchData(apiPath).then(data => data).catch(err => console.error(err));
  }

  hasTicketing = () => {
    let apiPath = 'usr/GetTicketingPermission';
    return fetchData(apiPath, false, false).then(data => data).catch(err => console.error(err));
  }

  hasIpTrunk = () => {
    let apiPath = '/admin/HasIpTrunking';
    return fetchData(apiPath).then(data => data).catch(err => console.error(err));
  }

  hasTrunking = () => {
    return fetchData('admin/HasTrunking').then(data => data).catch(err => console.error(err));
  }

  hasContactCenter = () => {
    let apiPath = 'admin/HasContactCenter';
    let accNum = this.props.LocationAccountNumber;
    if (accNum) apiPath += '?LocationAccountNumber=' + accNum;
    return fetchData(apiPath, false, false).then(data => data).catch(err => console.error(err));
  }

  hasMacd = () => {
    let apiPath = "admin/macd/GetMacdPermissions";
    return fetchData(apiPath).then(data => data).catch(err => console.error(err));
  };

  serviceChangesLink = () => {
    if (this.state.hasMacd) {
      return (
        <Link
          to={{ pathname: "/macd", state: {} }}
          className="macd-link nav-item"
        >
          <span className="name">Service Changes</span>
        </Link>
      );
    } else return null;
  };

  hasAtLeastOneNepsPermission = data => {
    if (data) {
      let permsArray = Object.keys(data).map(e => data[e]);
      return permsArray.indexOf(1) >= 0;
    } else {
      // probably a 500 error
      // example error message: DB operation returned no data for UserId 1556517.
    }
  };

  hasDevices = () => {
    return fetchData("admin/GetNepsPermissions").then(data => data).catch((err) => console.error(err));
  };

  getLocations = () => {
    return fetchData('admin/Locations').then(data => data).catch((err) => console.error(err));
  }

  hasMsTeams = async () => {
    let path = `admin/GetMsTeamsPermission`;
    let response = await fetchData(path).then(data => data).catch(err => console.error(err));
    if (response === 0) {
      return false;
    } else {
      return true;

    }
  }

  hasCallRecording =async () => {
    let response= await fetchData('admin/HasCallRecording').then(data => data).catch((err) => console.error(err));
    if(response.HasCallRecordingMenu===true)
    {
      return true;
    }
    else{
      return false;
    }
  }

  menuLink = (path, icon, label) => {
    return (
      <Link to={path} className="nav-item">
        <span className="icon">{icon}</span>
        <span className="name">{label}</span>
      </Link>
    );
  };

  trunkingLink = () => {
    if (this.state.hasTrunking) {
      return (
        <Link to={{ pathname: "/sips", state: {} }} className="nav-item">
          <span className="icon">
            <svg width="12" height="12" viewBox="0 0 23 23" alt="trunking icon">
              <path
                d="m416 192l-320 0c-17 0-32 16-32 32l0 64c0 16 16 32 32 32l320 0c16 0 32-16 32-32l0-64c0-16-16-32-32-32z m-288 96l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m96 64l-320 0c-17 0-32 16-32 32l0 64c0 16 16 32 32 32l320 0c16 0 32-16 32-32l0-64c0-16-16-32-32-32z m-288 96l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m96-416l-320 0c-17 0-32 16-32 32l0 64c0 16 16 32 32 32l320 0c16 0 32-16 32-32l0-64c0-16-16-32-32-32z m-288 96l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m96-32l-32 0 0-32 32 0z"
                transform="scale(0.046875 0.046875)"
              />
            </svg>
          </span>
          <span className="name">Trunking</span>
        </Link>
      );
    } else return null;
  };

  LocationsLink = (path, icon, label) => {
    return (
      <Link to={path} className="nav-item">
        <span className="icon">{icon}</span>
        <span className="name" style={{ fontSize: "85%" }}>{label}</span>
      </Link>
    )
  }

  devicesLink = () => {
    if (this.props.hasDevices) {
      return this.menuLink("/devices", icons.devices, "Devices");
    } else return null;
  };

  contactCenterLink = () => {
    if (userIsContactManager() || this.state.hasContactCenter) {
      return this.menuLink(
        "/iframe_page/MSP_CC",
        icons.contactCenter,
        "Contact Center"
      );
    } else return null;
  };

  billingLink = () => {
    if (!session.IsWholesale() && (!process.env.REACT_APP_BILLING_MESSAGE || process.env.REACT_APP_BILLING_MESSAGE === '')) {
      return this.menuLink(
        "/iframe_page/MSP_PAYMENT",
        icons.billing,
        "Billing"
      );
    } else return null;
  };
  ipTrunkingLink = () => {
    if (this.state.hasIpTrunk && this.state.hasIpTrunk === true) {
      // custom icon?
      return this.menuLink('/ip_trunking', icons.trunking, 'IP Trunking');
    }
  }

  ticketingLink = () => {
    if (this.state.hasTicketing && this.state.hasTicketing !== 'NONE') {
      return this.menuLink('/tickets_all', icons.ticketing, 'Support Tickets')
    }
  }

  documentsLink = () => {
    if (sessionStorage.IsWholesale === "true") {
      return this.menuLink('/documents', icons.documents, 'Documents')
    } else return;
  }

  addressBookLink = () => {
    if (sessionStorage.IsWholesale === "true" && this.state.permissionsRetrieved && !sessionStorage.isIpTrunkingOnly) {
      return this.menuLink('/enterprise_contact_directory', icons.addressBook, 'Contact Directory')
    } else return;
  }

  entTrunkLink = () => {
    if (sessionStorage.hasEntTrunking === "true") {
      return this.menuLink('/enterprise-trunking', icons.entTrunk, 'Enterprise Trunking')
    } else return;
  }

  adLink = () => {
    //if (sessionStorage.hasActiveDirectory) {
    return this.menuLink(`/active-directory`, icons.addressBook, 'Single Sign On')
    //} else return;
  }

  msTeamsLink = () => {
    if (sessionStorage.hasTeams) {
      return this.menuLink(`/microsoft-teams`, icons.addressBook, 'Microsoft Teams')
    } else return;
  }

  callRecordingLink = () => {   
    if(sessionStorage.hasCallRecording==="true")
        return this.menuLink(`/call-recording`, icons.addressBook, 'Call Recording')
   
  }

  render() {
    if (userIsAdmin()) {
      if (checkBillingAccount()) {
        return (
          <div>
            <div className="nav-head" style={{ display: this.state.hasAtLeastOneAdminLink === true ? 'block' : 'none' }}>
              {this.state.Header}
            </div>
            {this.menuLink("/admin", icons.dashboard, "Dashboard")}
            {this.billingLink()}
          </div>
        )
      } else {
        return (
          <div>
            <div className="nav-head" style={{ display: this.state.hasAtLeastOneAdminLink === true ? 'block' : 'none' }}>
              {this.state.Header}
            </div>
            {this.menuLink("/admin", icons.dashboard, "Dashboard")}
            {this.LocationsLink(
              { pathname: "/locations", state: {} },
              icons.locations,
              "Locations & Group Settings"
            )}
            {this.menuLink(
              "/services",
              icons.servicesAndUsers,
              "Services & Users"
            )}
            {this.devicesLink()}
            {this.trunkingLink()}
            {this.menuLink(
              "/iframe_page/MSP_ENTERPRISE",
              icons.enterpriseSettings,
              "Enterprise Settings"
            )}
            {this.contactCenterLink()}
            {this.billingLink()}
            {this.ipTrunkingLink()}
            {this.ticketingLink()}
            {this.documentsLink()}
            {this.addressBookLink()}
            {this.entTrunkLink()}
            {this.adLink()}
            {this.msTeamsLink()}
            {this.callRecordingLink()}
            {this.serviceChangesLink()}
          </div>
        )
      }
    } else {
      // test w/ non-admin user
      return (
        <div>
          <div className="nav-head" style={{ display: this.state.hasAtLeastOneAdminLink === true ? 'block' : 'none' }}>{this.state.Header}</div>
          {this.contactCenterLink()}
          {this.ticketingLink()}
          {this.addressBookLink()}
          {this.documentsLink()}
          {this.callRecordingLink()}
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    LocationAccountNumber: state.reducer.AccountNumber,
    hasTrunking: state.reducer.hasTrunking,
    hasContactCenter: state.reducer.hasContactCenter,
    hasMacd: state.reducer.hasMacd,
    hasDevices: state.reducer.hasDevices,
    hasIpTrunk: state.reducer.hasIpTrunk,
    hasTicketing: state.reducer.hasTicketing,
    permissionsRetrieved: state.reducer.permissionsRetrieved
  };
};

export default connect(mapStateToProps)(AdminMenu);
