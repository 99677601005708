import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as session from './../scripts/session';
import { userIsAdmin } from './../scripts/helper';
import { training_url } from "./../constants/urls";
import { userRolePopUp } from './ellipsisHeaderTooltip';
import * as settings from '../scripts/settings';
import { checkBillingAccount } from '../utility/utility';

const isWholesale = () => { return session.IsWholesale() };

const profileLinkPath = () => {
  return userIsAdmin() ? '/admin/profile' : '/profile';
}

const supportLink = () => {
  if (!isWholesale())
    return (
      <li>
        <Link to="/iframe_page/MSP_SUPPORT" className="nav-item">Support</Link>
      </li>
    );
  return null;
}
const trainingLink = () => {
  if (!isWholesale())
    return (
      <li>
        <a href={training_url} target="_blank" className="nav-item">Training</a>
      </li>
    );

  return null;
}

const updateMyServices = () => {
  settings.selectService(sessionStorage.userId, null, null);
}

const profileDropDownLinks = () => {
  if (checkBillingAccount()) {
    return;
  } else {
    return (
      <Fragment>
        <li><Link to={profileLinkPath()} onClick={() => { updateMyServices() }}>My Profile</Link></li>
        {supportLink()}
        {trainingLink()}
        <li role="separator" className="divider"></li>
      </Fragment>
    )
  }
}

export const dropDownMenu = () => {
  return (
    <div id="dropdown" className="dropdown">
      <a className="menubtn dropdown-toggle" type="button" id="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {userRolePopUp()} Hi, {session.parsedUsername()}
        <span className="caret"></span>
      </a>
      <ul className="dropdown-menu" aria-labelledby="">
        {profileDropDownLinks()}
        <li><a tabIndex="0" onClick={session.logout}>Log Out</a></li>
      </ul>
    </div>
  )
}

export default dropDownMenu;