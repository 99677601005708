import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import * as settings from './../../scripts/settings';
import * as apiCalls from './../../requests/api_calls';
import { DataLoadingSpinner } from './../../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import serviceSelector from './../../components/service_selector';
import _ from 'lodash';

class FavServices extends Component {

  constructor() {
    super();
    this.state = {
      services: [],
      loading: true,
      sort: [],
      skip: 0,
    };
    this.fetchServices = this.fetchServices.bind(this);
    this.searchServices = this.searchServices.bind(this);
    this.sortChange = this.sortChange.bind(this);
    this.pageChange = this.pageChange.bind(this);
    const starClass = this.starClass.bind(this);
    const toggleFavoriteStatus = this.toggleFavoriteStatus.bind(this);
    const handleEnter = this.handleEnter;

    class FavoriteButton extends GridCell {

      render() {
        // passing data item based on example from Kendo UI example
        // https://www.telerik.com/kendo-react-ui/components/grid/editing/editing
        let item = this.props.dataItem;
        let cannotFavorite = item.ServiceId === "" && item.Extension === "";
        if (cannotFavorite) {
          return <td></td>;
        } else {
          const cursorType = item.isToggingStatus ? 'wait' : 'pointer';
          return (
            <td>
              <a style={{cursor: cursorType}} onClick={(e) => toggleFavoriteStatus(item)} className={starClass(item)}>
                <svg width="15" height="15" viewBox="0 0 24 24" tabIndex="0" onKeyUp={(e) => handleEnter(e, item)}>
                  <path d="m480 192l-157-20-67-140-67 140-157 20 115 104-29 152 138-74 139 74-30-152z" transform="scale(0.046875 0.046875)"></path>
                </svg>
              </a>
            </td>
          )
        }
      }
    }
    this.favBtn = FavoriteButton;

    class FirstAndLastName extends GridCell {
      render() {
        let service = this.props.dataItem
        return <td>{service.FirstName + ' ' + service.LastName}</td>
      }
    }
    this.firstAndLastName = FirstAndLastName;
  }

  componentDidMount() {
    this.fetchServices('admin/FavoriteServices');
  }

  fetchServices = (apiPath, resultsMsg) => {
    this.setState({loading: true})
    apiCalls.fetchData(apiPath).then((data) => {
      if (data !== null) {
        this.setState({services: data, loading: false, resultsMsg: null});
      } else if (data === null) {
        let msg = resultsMsg || 'No matching results found for search criteria.';
        this.setState({resultsMsg: msg, loading: false, services: []});
      };
    });
  }

  sortChange(event) {
    this.setState({
      services: this.sortServices(event.sort),
      sort: event.sort
    });
  }

  pageChange(event) {
    this.setState({
      services: this.state.services,
      skip: event.page.skip
    });
  }

  sortServices(sort) {
    var services = [];
    if (this.state !== undefined) {
      services = this.state.services.slice();
    }
    return orderBy(services, sort);
  }

  searchServices = (event) => {
    let searchText = event.target.value.trim();
    var apiPath = ''
    if (searchText !== '') {
      apiPath = 'admin/Services?term=' + searchText;
    } else { // reset to favorites
      apiPath = 'admin/FavoriteServices';
    }
    this.delayedSearch(apiPath);
  }

  delayedSearch = (apiPath) => {
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.fetchServices(apiPath, settings.noResultsMsg);
    }, settings.searchDelay);
  }

  starClass = (dataItem) => {
    return dataItem.IsFav ? 'star' : 'dim-star';
  }

  // Used for accessibility standards
  handleEnter = (e, item) => {
    e.preventDefault();
    if (e.keyCode === 13)
      this.toggleFavoriteStatus(item);
  } 

  toggleFavoriteStatus = async (item) => {
    // post update to API
    let updatedItem = new Object(item);
    updatedItem.isToggingStatus = true;
    await this.replaceServices(updatedItem);
    if (item.IsFav) {
      await this.unfavorite(updatedItem);
    } else {
      await this.saveFavorite(updatedItem);
    }
    
  }


  updateService = (item, newFavServiceId) => {
    let newIsFavState = item.IsFav ? false : true;
    let updatedItem = new Object(item);
    updatedItem.IsFav = newIsFavState;
    updatedItem.isToggingStatus = false;
    if (newFavServiceId) updatedItem.FavoriteServiceId = newFavServiceId;
    this.replaceServices(updatedItem);
  }

  replaceServices = (updatedItem) => {
    // replace services array with new array with updated service item
    let newServices = this.state.services.slice();
    let index = _.findIndex(newServices,s => s.ServiceId === updatedItem.ServiceId);
    newServices[index] = updatedItem;
    this.setState({services: newServices});
  }
  
  saveFavorite = (item) => {
    apiCalls.saveFavoriteService(item).then((data) => {
      let newFavServiceId = data.FavoriteServiceId;
      this.updateService(item, newFavServiceId);
    }).catch((err) => {
      console.log('error', err);
    });
  }

  unfavorite = (item) => {
    apiCalls.unfavoriteService(item).then((data) => {
      // services that are not favorites have 0 for FavoriteServiceId
      let newFavServiceId = 0; 
      this.updateService(item, newFavServiceId);
    }).catch((err) => {
      console.log('error', err);
    });
  }

  gridContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner relative'/>
    } else if (this.state.services && this.state.services.length > 0) {
      return (
        <Grid 
          scrollable = 'none'
          style={{ height: this.state.gridHeight }}
          data={this.state.services}
          sortable={{
            allowUnsort: true,
            mode: 'single'
          }}
          sort={this.state.sort} 
          onSortChange={this.sortChange} 
        >
          <Column field='FavoriteBtn'  title=' ' width='30px' cell={this.favBtn} />
          <Column cell={this.firstAndLastName} field='FirstName'  title='Name' />
          <Column field='ServiceType' title='Service' />
          <Column cell={serviceSelector} field='ServiceNumber' />
          <Column cell={serviceSelector} field='Extension' />
        </Grid>
      )
    } else return <h6 className='no-results-msg'>{this.state.resultsMsg}</h6>
  }

  favServicesTable = () => {
    return (
      <div>
        <div className="card-header">
          <h2 className="move">Favorite Services</h2>
          <div className="search">
            <label htmlFor="fav_services_srch_tb" className="hidden">favorite services search</label>
            <input id="fav_services_srch_tb" type="text" autoComplete={"off"} className="form-control search-control" onChange={this.searchServices} />
            <svg width="12" height="12" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
          </div>
        </div>
        <div className="card-content">
          {this.gridContent()}
        </div>
        <div className="view-more">
          <Link to="/services" className="no-btn">View All Services</Link>  
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="favorite-services card">
        {this.favServicesTable()}
      </div>
    )
  };
}

export default withRouter(FavServices);