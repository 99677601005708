import moment from "moment";
import * as session from "../scripts/session";
import * as apiCalls from '../requests/api_calls';
import * as config from '../requests/config';
import cssVars from "css-vars-ponyfill";

export const inProduction = () => process.env.NODE_ENV === "production";

export const atRootPath = () => window.location.pathname === "/";

export const atLoginPath = () => window.location.pathname === "/session";

export const atSupportLinksPath = () => window.location.pathname === "/supportLinks";

export const atDevicesPath = () => window.location.pathname === "/devices";

export const redirectHome = () => window.location.replace("/");

export const redirectToLanding = () => window.location.replace(homePageUrl());

export const redirectToLogin = () => window.location.replace("/session");

export const storedUserRole = () => sessionStorage.userRole;

export const urlIncludes = string => {
  return window.location.href.toLowerCase().indexOf(string) > -1;
};

export const getDomain = () => {
  return window.location.hostname.match(/localhost/)
    ? process.env.REACT_APP_TEST_DOMAIN
    : window.location.hostname;
};

export const isRetailDomain = domain => {
  return domain && domain.match(/momentumtelecom\.com/i);
};

export const isDigitalServicesDomain = domain => {
  return domain && domain.match(/mydigitalservices\.com/i);
};

export const IsWholesaleDomain = (domain = null) => {
  if (domain) {
    return !domain.match(/momentumtelecom\.com/);
  }
  return !urlIncludes("momentumtelecom.com");
};

export const shouldApplyCustomizations = () => {
  const isWholesaleDomain = IsWholesaleDomain(getDomain());
  const isWholesaleUser = session.IsWholesale();

  return isWholesaleDomain && isWholesaleUser;
};

export const getJSONFilePrefix = () => {
  const domain = getDomain();
  if (!domain) {
    return null;
  }

  if (domain.match(/driveuc\.com/i)) {
    return 'DriveUC';
  } else if (domain.match(/mydigitalservices.com/i)) {
    return 'MyDigitalServices';
  }

  return null;
}

export const getProductionUrl = () => {
  if (urlIncludes("momentumtelecom"))
    return "https://portal.momentumtelecom.com";
  else return "https://portal.mydigitalservices.com";
};

export const toBoolean = string => {
  if (string) {
    return string.toString() === true.toString();
  } else {
    return null;
  }
};

export const userHasNoServices = (service) => {
  return service.UserId > 0 && (service.ServiceId === 0 || service.ServiceId === null);
}

export const numberWithDelimiter = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const userIsAdmin = () => {
  let role = storedUserRole();
  if (role === 'SUPER_ADMIN' || role === 'ALLGROUP_ADMIN' || role === 'GROUP_ADMIN')
    return true;
  else
    return false
}

export const selectedUserIsAdmin = (role) => {
  if (role === 'SUPER_ADMIN' || role === 'ALLGROUP_ADMIN' || role === 'GROUP_ADMIN')
    return true;
  else
    return false
}

export const userIsContactManager = () => {
  let role = storedUserRole();
  if (role === 'CC_ADMIN')
    return true;
  else
    return false;
}

export const homePageUrl = () => {
  if (userIsAdmin()) {
    return '/admin'
  } else {
    return '/'
  }
};

export const titleCasePhrase = (string) => {
  return string.split('_').map(word => titleCaseWord(word)).join(' ');
}

export const titleCaseWord = (string) => {
  return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
}

export const isBlank = (service, sortField) => {
  let value = service[sortField] ? service[sortField].toString().trim() : null;
  return Boolean(value) == false;
}

export const registrationLastUpdated = () => {
  // sends current time as time of last update
  return 'Registration status updated: ' + moment().format('M-DD-YYYY hh:mm:ss A');
}

export const blanksAfterZ = (sortedSet, sort) => {
  let sortField = sort[0].field;
  let dir = sort[0].dir;
  let fieldBlanks = sortedSet.filter(service => isBlank(service, sortField));
  let fieldNonBlanks = sortedSet.filter(service => isBlank(service, sortField) == false);
  if (dir == 'asc') {
    return fieldNonBlanks.concat(fieldBlanks);
  } else {
    return fieldBlanks.concat(fieldNonBlanks);
  }
}

export const StripHtml = (msg) => {
  if (msg == null || msg == undefined || msg === '') {
    return msg;
  } else {
    var doc = new DOMParser().parseFromString(msg, 'text/html');
    return doc.body.textContent;
  }
}

export const filterNestedObject = (key, param, data) => {
  let filters = {};
  filters[key] = [param];
  let filteredData = data.filter(a => Object.keys(filters).every(b => filters[b].some(c => c == a[b])));
  if (filteredData && filteredData.length > 0) return filteredData;
};

export const onlyUnique = (val, index, self) => {
  return self.indexOf(val) === index;
};
export const devicePerms = () => {
  return sessionStorage.DevicePerms ? JSON.parse(sessionStorage.DevicePerms) : {};
};

export const showEditGroupFqdnBtn = () =>
  devicePerms().Setup_GroupLevelFQDN === 1;

export const showEditGroupVlanBtn = () =>
  devicePerms().Set_GroupVLANID === 1;

export const convertToLocalTime = dateVal => {
  let offset = -5;
  if (isDST(dateVal))
    //if daylight savings time add an hour.  database is eastern time
    offset += 1;
  offset *= -60;
  let offsetDiffMin = parseInt(offset - new Date().getTimezoneOffset());
  let convertedVal = new Date(dateVal);
  //return moment(convertedVal).add(offsetDiffMin, 'm');
  let test = convertedVal.setMinutes(convertedVal.getMinutes() + offsetDiffMin);
  return new Date(test);
};

Date.prototype.stdTimezoneOffset = dateVal => {
  var fy = dateVal.getFullYear();
  if (!Date.prototype.stdTimezoneOffset.cache.hasOwnProperty(fy)) {
    var maxOffset = new Date(fy, 0, 1).getTimezoneOffset();
    var monthsTestOrder = [6, 7, 5, 8, 4, 9, 3, 10, 2, 11, 1];

    for (var mi = 0; mi < 12; mi++) {
      var offset = new Date(fy, monthsTestOrder[mi], 1).getTimezoneOffset();
      if (offset !== maxOffset) {
        maxOffset = Math.max(maxOffset, offset);
        break;
      }
    }
    Date.prototype.stdTimezoneOffset.cache[fy] = maxOffset;
  }
  return Date.prototype.stdTimezoneOffset.cache[fy];
};

Date.prototype.stdTimezoneOffset.cache = {};

export const isDST = dateVal => {
  var dt = new Date(dateVal);
  return dt.getTimezoneOffset() < dt.stdTimezoneOffset(dt);
};

export const StringReplaceAll = (stringValue, str1, str2, ignore) => {
  let test = stringValue.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, "\\$&"), (ignore ? "gi" : "g")), (typeof (str2) == "string") ? str2.replace(/\$/g, "$$$$") : str2);
  return test;
};

export const ValidateIPaddress = (ipaddress) => {
  let regExp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  if (regExp.test(ipaddress)) {
    //console.log('valid');
    return true;
  } else {
    //console.log("You have entered an invalid IP address!");
    return false;
  }
}

export const processWholesaleCustomizations = async (customizations, LogoSmall, LogoLarge) => {

  const { BrowserIcon, VendorId, CSS } = customizations;
  const storeCustomImageResult = await storeCustomImages(LogoSmall, LogoLarge);

  if (!(VendorId === -1 && sessionStorage.getItem('vendorId') !== -1))
    storeVendorId(VendorId);

  updateSiteTheme(CSS);
  updateFavicon(BrowserIcon);
  return storeCustomImageResult;
};

const updateSiteTheme = CSS => {
  if (!CSS || typeof CSS !== "string") {
    return false;
  }

  try {
    const tempReplacementStr = CSS.replace(
      /\"Arial Narrow Bold\"/,
      "Arial Narrow Bold"
    );
    const styles = JSON.parse(tempReplacementStr);
    let variables = {};

    Object.keys(styles).forEach(style => {
      let val = styles[style];
      variables[`--theme-${style}`] = val;
    });

    //added after implementation need to do lookup and set to 'previous' values
    if (!variables["--theme-profile_text"] || variables["--theme-profile_text"] === undefined)
      variables['--theme-profile_text'] = variables['--theme-nav_text'];
    if (!variables["--theme-nav_header_text"] || variables["--theme-nav_header_text"] === undefined)
      variables['--theme-nav_header_text'] = variables['--theme-nav_text'];
    if (!variables["--theme-primary_button_text"] || variables["--theme-primary_button_text"] === undefined)
      variables['--theme-primary_button_text'] = variables['--theme-linktext'];

    cssVars({ variables });
  } catch (e) {
    console.log("Error processing custom styles");
    return false;
  }
};

export const storeCustomImages = async (
  customSmallLogo = null,
  customLargeLogo = null
) => {
  if (customSmallLogo || customLargeLogo) {
    const existingCustomSmallLogo = await session.customLogoSmall();
    const existingCustomLargeLogo = await session.customLogoLarge();

    if (!existingCustomSmallLogo && customSmallLogo) {
      sessionStorage.setItem("customLogoSmall", customSmallLogo);
    }

    if (!existingCustomLargeLogo && customLargeLogo) {
      sessionStorage.setItem("customLogoLarge", customLargeLogo);
    }
    return true;
  } else {
    return false;
  }
};

export const storeVendorId = vendorId => {
  sessionStorage.setItem("vendorId", vendorId);
  return true;
};

export const updateFavicon = logo => {
  if (logo) {
    const favicon = document.querySelector('link[rel="shortcut icon"]');
    if (favicon) {
      favicon.setAttribute("type", "image/png");
      favicon.setAttribute("href", logo);
      return true;
    }
  }
  return false;
};

export const setSiteTitleFromDomain = () => {
  const domain = getDomain();
  let titleBarText = "";
  if (!domain) {
    return;
  }

  if (isRetailDomain(domain)) {
    titleBarText = "Momentum Telecom";
  } else if (isDigitalServicesDomain(domain)) {
    titleBarText = "My Digital Services";
  }

  setTitle(titleBarText);
};

export const checkPageTitle = () => {
  const currentTitle = document.querySelector('title');

  if (!currentTitle || currentTitle === '') {
    setSiteTitleFromDomain();
  }
};

export const setTitle = title => {
  const titleEl = document.querySelector("title");

  if (titleEl) {
    titleEl.text = title || '';
  }
};

export const getB2CConfigResults = async currentUrl => {
  const staticJSONPrefix = getJSONFilePrefix();
  let b2cResults;
  if (staticJSONPrefix) {
    const filename = `${staticJSONPrefix}-UI-${process.env.REACT_APP_ENVIRONMENT}`;
    try {
      b2cResults = await import(`../../public/data/UISettings/${filename}`);
    } catch (e) {
      console.log('Error parsing JSON file');
    }
  } else {
    const apiPath = config.baseApiUrl + "usr/WLGetB2CConfig?HostName=" + encodeURI(currentUrl);
    const auth = "Basic " + btoa("msp" + ":" + process.env.REACT_APP_PRPX_BASIC_PWD);
    const apiHeaders = { "Content-Type": "application/json", Accept: "application/json", Authorization: auth };
    try {
      b2cResults = await apiCalls.customFetch(apiPath, apiHeaders);
    } catch (e) {
      console.log('Pre login customization call failed');
    }
  }
  return b2cResults;
}


// SB 7/22/2021: remove, not used
// export const OKTAConfig = async Username => {
//    let OKTAConfigResults;
//
//     const apiPath = config.baseApiUrl + "usr/OKTAConfig?Username=" + encodeURI(Username);
//     const auth = "Basic " + btoa(Username + ":" + process.env.REACT_APP_PRPX_BASIC_PWD);
//
//     const apiHeaders = {
//    "Accept": "application/json",
//    "Content-Type": "application/json;charset=UTF-8",
//     Authorization: auth };
//     try {
//       OKTAConfigResults = await apiCalls.customFetch(apiPath, apiHeaders);
//     } catch (e) {
//       console.log('Pre login customization call failed');
//     }
//
//   return OKTAConfigResults;
// }

export const ValueOfBuildingTag = "BLDG";
export const ValueOfFloorTag = "FL";
export const streetTypes = ["ACRES", "ALY", "ANX", "ARC", "AVE", "BAY", "BCH", "BLF", "BLVD", "BND",
  "BR", "BRG", "BRK", "BTM", "BYP", "BYU", "C", "CHASE", "CIR", "CLB", "CLF",
  "CMN", "CMNS", "COR", "CORS", "CP", "CPE", "CRES", "CRK", "CRSE", "CRSG",
  "CRST", "CSWY", "CT", "CTR", "CTS", "CURV", "CV", "CYN", "DL", "DM", "DR",
  "Dr.", "DRS", "DV", "E", "END", "EST", "ESTS", "EXPWY", "EXPY", "EXT", "FLD",
  "FLDS", "FLS", "FLTS", "FOX", "FRG", "FRK", "FRST", "FRY", "FWY", "GDN",
  "GDNS", "GLN", "GRN", "GRV", "GTWY", "HBR", "HL", "HLS", "HOLW", "HT", "HTS",
  "HVN", "HWY", "INLT", "IS", "ISL", "ISLE", "JCT", "KNL", "KNLS", "KY", "LA",
  "LAND", "LDG", "LGT", "LK", "LKS", "LN", "LNDG", "LOOP", "LP", "MALL", "MDW",
  "MDWS", "MEWS", "ML", "MNR", "MNRS", "MT", "MTN", "NCK", "NE", "NK", "OAKS",
  "ORCH", "OVAL", "OVERLOOK", "PARK", "PASS", "PATH", "PIKE", "PINEY", "PK",
  "PKE", "PKWY", "PL", "PLAZ", "PLN", "PLZ", "PNE", "PNES", "PR", "PRT", "PT",
  "PTS", "RADL", "RAMP", "RD", "RD EXT", "RDG", "RDGS", "RDS", "RISE", "RIV",
  "RNCH", "ROW", "RPDS", "RST", "RTE", "RUN", "S", "SHL", "SHLS", "SHR", "SHRS",
  "SMT", "SPG", "SPGS", "SPUR", "SQ", "SQS", "ST", "ST EXT", "ST.", "STA",
  "STEET", "STRM", "STS", "TER", "TOP", "TPKE", "TRAK", "TRC", "TRCE", "TRL",
  "TURN", "TURNKPIKE", "UN", "UP", "VIA", "VIS", "VL", "VLG", "VLY", "VW", "W",
  "WALK", "WALL", "WAY", "WAYS", "WK", "WL", "WLS", "WOODS", "WYND", "XING"]

export const CreateFullStreet1 = (address) => {

  let Result = "";
  if (address) {
    if (address.StreetNumber && address.StreetNumber.trim() !== '')
      Result += " " + address.StreetNumber.trim();
    // else if (address.HouseNumber && address.HouseNumber.trim() !== '')
    //   Result += " " + address.HouseNumber.trim();

    if (address.StreetPrefix && address.StreetPrefix.trim())
      Result += " " + address.StreetPrefix.trim();

    if (address.StreetName && address.StreetName.trim())
      Result += " " + address.StreetName.trim();

    if (address.StreetType && address.StreetType.trim())
      Result += " " + address.StreetType.trim();

    if (address.StreetSuffix && address.StreetSuffix.trim())
      Result += " " + address.StreetSuffix.trim();
  }
  //console.log("trim", Result.trim());
  return Result.trim();
}

export const CreateFullStreet2 = (address) => {
  let Result = "";
  if (address) {
    if (address.UnitValue)
      Result = address.Unit.trim() + " " + address.UnitValue.trim();

    if (address.FloorValue && address.FloorValue.trim())
      Result = ValueOfFloorTag + " " + address.FloorValue.trim() + " " + Result;

    if (address.BuildingValue && address.BuildingValue.trim())
      Result = ValueOfBuildingTag + " " + address.BuildingValue.trim() + " " + Result;
  }


  return Result;
}

export function generateUUID() { // Public Domain/MIT
  var d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); //use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

export function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

// SB 08/18/2021: added arg. 'AppName'
export const checkIsUserValid = (Username, AppName) => {
  const apiPath = config.baseApiUrl + "usr/OKTAConfig?Username=" + encodeURI(Username) + "&AppName=" + encodeURI(AppName);
  const auth = "Basic " + btoa(Username + ":" + process.env.REACT_APP_PRPX_BASIC_PWD);

  return apiCalls.customFetch(apiPath, {
    "Accept": "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: auth
  }, true);
}
