import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { fetchData } from './../../requests/api_calls';
import * as settings from './../../scripts/settings';
import moment from 'moment';

class ActivityHistory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      history: []
    };
  };

  componentDidMount() {
    this.fetchHistory();
  }

  fetchHistory = () => {
    fetchData('admin/ActivityHistory').then((data) => {
      if (data) {
        this.setState({
          history: this.sortedHistory(data)
        });
      }
    });
  }

  sortedHistory = (data) => {
    data.sort((a, b) =>  new Date(b.ActivityDate) - new Date(a.ActivityDate) );
    return data.map((item, index) => this.historyItemDiv(item, index))
  }

  setServiceAndRedirect = async (historyItem) => {
    let userId = historyItem.ActivityURL.split('user/')[1].split('/')[0];
    let apiPath = 'usr/MyServices?UserId=' + userId;
    let results = await fetchData(apiPath).then(data => data)
    let service = this.findServiceByHistoryItem(historyItem, results)
    if (service) {
      settings.selectService(service.UserId, service.ServiceNumber, service.Extension);
      this.props.history.push('/dashboard');
    }
    
  }

  findServiceByHistoryItem = (historyItem, results) => {
    let numOrExt = historyItem.ServiceNumberOrExtension;
    let matches = results.filter(service => numOrExt === (service.ServiceNumber || service.Extension))
    return matches[0] || null;
  }

  historyItemDiv = (item, index) => {
    return (
      <div className="history-item" key={index} >
        <a key={item.ServiceId} onClick={(e) => this.setServiceAndRedirect(item)} >
          {item.UserName} ({item.ServiceNumberOrExtension}) &nbsp;
          <small>{moment.utc(item.ActivityDate).fromNow()}</small>
        </a>
      </div>
    
      
      // 
      //   <Link to='/dashboard' onClick={(e) => this.setService(item)} >
      //     {item.UserName} ({item.ServiceNumberOrExtension}) &nbsp;
      //     <small>{moment.utc(item.ActivityDate).fromNow()}</small>
      //   </Link>
      // </div>
    );
  }

  render() {
    return (
      <div className="history card">
        <div className="card-header">
          <h2 className="move">Activity History</h2>
        </div>
        <div className="card-content">
          <div className="history-items">
            {this.state.history}
          </div>
        </div>
      </div>
    )
  }

}

export default withRouter(ActivityHistory);