import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import _ from 'lodash';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { DataLoadingSpinner } from '../components/spinners';
import { connect } from 'react-redux';
import * as apiCalls from '../requests/api_calls';
import * as EmailValidator from 'email-validator';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import PropTypes from 'prop-types';
import WrappedCell from '../components/wordWrappedCell'
import AddEditEnterpriseContact from './modals/AddEditEnterpriseContact';
import { headerToolTip } from '../components/ellipsisHeaderTooltip';
import { filterBy } from '@progress/kendo-data-query';
import * as helper from './../scripts/helper';

class EnterpriseContactDirectory extends Component {

  initialState = (props) => {
    return {
      Contacts: [],
      ContactsCopy: [],
      loading: true,
      showDeletePrompt: false,
      Sort: [{ field: "Name", dir: 'asc' }],
      gridHeight: (window.innerHeight - 180),
      searchTerm: '',
      savingShowSuccess: false,
      savingShowError: false,
      saveResultsMessage: ''
    };
  }

  constructor(props) {
    super(props);
    this.state = this.initialState(props);

    const handleDeleteItem = this.handleDeleteItem.bind(this);
    const updateFromModal = this.updateFromModal.bind(this);

    class ContactEditButton extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        return (
          <td>
            <AddEditEnterpriseContact AddEditMode='Edit' ContactDetails={this.props.dataItem} UpdateParentFromModal={updateFromModal} />
          </td>

        );
      }
    }

    class ContactDeleteButton extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        if (this.props.dataItem.CanEdit === true)
          return (
            <td>
              <Button click={(e) => { handleDeleteItem(this.props.dataItem) }}>Delete</Button>
            </td>
          );
        else
          return (
            <td>

            </td>
          )
      }
    }

    this.sortChange = this.sortChange.bind(this);
    this.searchContacts = this.searchContacts.bind(this);
    this.handleCancelDelete = this.handleCancelDelete.bind(this);

    this.ContactEditButton = ContactEditButton;
    this.ContactDeleteButton = ContactDeleteButton;
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ loading: true });
    let apiPath = 'admin/GetEnterpriseContactBook'
    apiCalls.fetchData(apiPath).then((data) => {
      if (data !== null) {
        this.setState({ Contacts: data, ContactsCopy: data, loading: false });
      } else {
        this.setState({ Contacts: [], ContactsCopy: [], loading: false });
      }
    }).catch(ex => {
      console.log(ex);
      this.setState({ Contacts: [], ContactsCopy: [], loading: false });
    });
  }

  gridContent = () => {
    let EditTitle = sessionStorage.userRole === 'SUPER_ADMIN' ? 'Edit' : 'View';
    let DeleteTitle = sessionStorage.userRole === 'SUPER_ADMIN' ? 'Delete' : ' ';
    return (
      <Grid
        style={{ height: this.state.gridHeight }}
        data={this.state.ContactsCopy}
        sortable={{
          allowUnsort: true,
          mode: 'single'
        }}
        sort={this.state.Sort}
        onSortChange={this.sortChange}
        scrollable={'scrollable'}
      >
        <Column field='Name' title={'Name'} cell={WrappedCell} width="485px" />
        {/* <Column field='LastName'  title={headerToolTip('Last Name')} width="185px" /> */}
        <Column field='TN' title={headerToolTip('Phone Number')} width="430px" />
        {/* <Column field='StreetAddress' cell={WrappedCell} title={headerToolTip('Street Address')} width="185px" />
            <Column field='City' title={'City'} width="185px" />
            <Column field='State' width="90px" title={'State'} />
            <Column field="Email" width="350px" title={'Email'} /> */}
        <Column title={EditTitle} cell={this.ContactEditButton} sortable={false} filterable={false} width="135px" />
        <Column title={DeleteTitle} cell={this.ContactDeleteButton} sortable={false} filterable={false} width="135px" />
        <Column field="spacer" title=" " sortable={false} filterable={false} />
      </Grid>
    )
  }

  sortChange = (event) => {
    let sortedSet = this.sortContacts(event.sort);
    this.setState({
      Contacts: sortedSet,
      ContactsCopy: sortedSet,
      Sort: event.sort
    });
  }

  sortContacts(sort) {

    let contacts = this.state.Contacts.slice();
    let sortedSet = orderBy(contacts, sort);
    if (sort.length) {
      return helper.blanksAfterZ(sortedSet, sort);
    } else {
      return sortedSet;
    }
  }

  searchContacts = (event) => {
    let searchText = '';
    let fullDetails = this.state.Contacts;
    if (event && event.target && event.target.value && event.target.value.trim() !== '') {
      searchText = event.target.value;
      let contacts = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'Name', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'TN', operator: 'contains', value: searchText, ignoreCase: true }

        ]
      });

      this.setState({ ContactsCopy: contacts, searchTerm: searchText })
    }
    else {
      this.setState({ ContactsCopy: fullDetails, searchTerm: '' });
    }
  }

  handleDeleteItem = (productItem) => {
    this.setState({ showDeletePrompt: true, selectedDeleteItem: productItem });
  }

  handleCancelDelete = () => {
    this.setState({ showDeletePrompt: false, selectedDeleteItem: null });
  }

  handleConfirmedDelete = () => {
    this.setState({ saving: true });
    let contact = this.state.selectedDeleteItem;
    let apiPath = 'admin/RemoveContactInContactBook';
    let verb = 'POST';
    let requestData = contact.Name;
    apiCalls.post(apiPath, verb, JSON.stringify(requestData)).then((data) => {
      console.log(data);
      if (data.ok) {
        let Contacts = this.state.Contacts.slice();
        let ContactsCopy = this.state.ContactsCopy.slice();

        let newContacts = Contacts.filter(resp => resp.Name !== contact.Name);
        let newContactsCopy = ContactsCopy.filter(resp => resp.Name !== contact.Name);
        this.setState({
          saving: false,
          savingShowSuccess: true,
          saveResultsMessage: 'Contact Removed Successfully!',
          Contacts: newContacts,
          ContactsCopy: newContactsCopy,
          showDeletePrompt: false
        });
      } else {
        this.setState({ savingShowError: true, saveResultsMessage: data, saving: false, showDeletePrompt: false })
      }
    })
      .catch(message => {
        this.setState({
          saving: false,
          savingShowError: true,
          saveResultsMessage: 'An error occurred while attempting to remove contact from contact directory!',
          showDeletePrompt: false
        });
      });
  }

  updateFromModal = (contact, isAdd) => {
    let Contacts = this.state.Contacts.slice();
    let ContactsCopy = this.state.ContactsCopy.slice();
    if (!isAdd) {
      let oldContact = Contacts.filter(resp => resp.Name === contact.OldName)[0];
      let oldContactCopy = ContactsCopy.filter(resp => resp.Name === contact.OldName)[0];
      let idxOldContact = _.indexOf(Contacts, oldContact);
      let idxOldContactCopy = _.indexOf(ContactsCopy, oldContactCopy);

      contact.OldName = contact.Name;
      Contacts[idxOldContact] = contact;
      ContactsCopy[idxOldContactCopy] = contact;
    }
    else {
      Contacts.push(contact);
      ContactsCopy.push(contact);
    }
    this.setState({ Contacts: Contacts, ContactsCopy: ContactsCopy });
  }

  showAddContactButton = () => {
    if (sessionStorage.userRole && sessionStorage.userRole === 'SUPER_ADMIN') {
      return (
        <AddEditEnterpriseContact AddEditMode={'Add'} UpdateParentFromModal={this.updateFromModal} />
      );
    }
    else
      return null;
  }

  uploadBtn = () => {
    return (
      <div id="contact-upload" className='pull-right' style={{ marginRight: 10, paddingLeft: "10px" }}>
        <input
          type="file"
          id="file"
          accept=".csv"
          ref='fileUploader'
          style={{ display: "none" }}
          onChange={this.addAttachment}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <button className="orange btn" name="btnuploadBtn" onClick={(e) => this.openAttachments()}>Upload Contacts</button>
      </div>)
  }

  openAttachments = () => {
    this.refs.fileUploader.click();
  }

  addAttachment = async (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.addEventListener('loadend', () => {
      let body = reader.result.split(',')[1];
      let name = file.name;
      this.createAttachment(body, name);
    }, false);
    if (file) {
      reader.readAsDataURL(file);
    }
  }
  createAttachment = async (body, name, ticketId) => {
    let apiPath = 'admin/UploadEnterpriseLevelContacts';
    let attachmentDTO = {
      TicketId: this.state.TicketId,
      AttachmentBody: body,
      FileName: name,
    };
    await this.setState({ attachmentSubmitted: true });
    let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(attachmentDTO)).then(res => res).catch(err => console.error(err));
    if (response.ok) {
      await this.setState({
        Submission: true,
        submitError: false,
      });
      this.fetchData();
    } else {
      await this.setState({
        Submission: true,
        submitError: true,
      });
    }
  }


  render() {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      let displayTitle = () => {
        if (sessionStorage.serviceProviderName === "Vyve_Broadband") {
          if (window.location.href.indexOf("mydigitalservices") != -1 || window.location.href.indexOf("driveuc") != -1) {
            return "Enterprise Common Directory";
          } else {
            return "Enterprise Contact Directory";
          }
        } else {
          return "Enterprise Contact Directory";
        }
      }
      return (
        <div className="full-card container">
          <div className="enterprise-directory card">
            <div>
              <div className="card-header">
                <h1>{displayTitle()} <small>{this.state.ContactsCopy.length}</small></h1>
                <div className="card-actions" style={{ textAlign: 'right' }}>
                  <div className="search">
                    <label htmlFor="enterprise_contact_srch_tb" className="hidden">contact search</label>
                    <input id="enterprise_contact_srch_tb" type="text" className="form-control search-control" autoComplete={"off"} onChange={(e) => this.searchContacts(e)} value={this.state.searchTerm} />
                    <svg width="14" height="14" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
                  </div>
                  <div className="card-header">
                  {this.uploadBtn()}
                  {this.showAddContactButton()}
                  </div>
                  
                </div>
              </div>
              <div>
                {this.gridContent()}
                <Modal dialogClassName="confirm-cancel-macd" show={this.state.showDeletePrompt} onHide={this.handleCancelDelete}>
                  <Modal.Header closeButton>
                    <Modal.Title>Remove Contact?</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div><p><span style={{ fontSize: "medium" }}>Are you sure you wish to remove this contact from Enterprise Directory?</span></p></div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button click={(evt) => { this.handleConfirmedDelete() }}>Yes</Button>
                    <Button click={(evt) => { this.handleCancelDelete() }}>No</Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

class SavingMessageControl extends Component {
  render() {
    let successMessage = "";
    if (this.props.showSuccess) {
      successMessage = (<ReactCSSTransitionGroup
        transitionName={this.props.SuccessTransitionClass}
        transitionAppear={true}
        transitionAppearTimeout={4000}
        transitionEnter={true}
        transitionEnterTimeout={4000}
        transitionLeave={false}
      >
        <div className="inline-success text-center">{this.props.SuccessMessage}</div>
      </ReactCSSTransitionGroup>)
    }

    let loadingMessage = "";
    if (this.props.showLoading) {
      loadingMessage = (<DataLoadingSpinner className='load spinner' />)
    }

    let errorMessage = "";
    if (this.props.showError) {
      errorMessage = (<div className="inline inline-fail text-center">{this.props.ErrorMessage}</div>)
    }

    return (
      <div>
        {successMessage}
        {loadingMessage}
        {errorMessage}
      </div>
    );
  }
};

SavingMessageControl.propTypes = {
  showSuccess: PropTypes.bool.isRequired,
  showLoading: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
}

export default EnterpriseContactDirectory;