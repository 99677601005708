import React, { Component, Fragment } from 'react';
import AdConfig from './ad_config';
import SyncMenu from './active_directory_menu';
import AppList from './ad_app_list';
import * as apiCalls from '../../requests/api_calls';
import { DataLoadingSpinner } from '../../components/spinners';

export default class AdMain extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      tab: "config",
    }

  }

  componentDidMount() {
    this.fetchIdPConnector();
  }

  fetchIdPConnector = async () => {
    await this.setState({ loading: true });
    let path = `admin/GetCustomerIdp`;
    await apiCalls.fetchData(path).then(data => {
      if (data) {
        this.setState({
          ConnectorInfo: data,
          hasConnector: true,
          loading: false,
        });
      } else {
        this.setState({
          ConnectorInfo: [],
          loading: false,
        });
      }
    }).catch(err => console.error(err));
  }

  tabDisplay = () => {
    return (
      <div className="tabs" style={{ float: "left", width: "100%" }}>
        <a
          style={{ display: "inline-block" }}
          className={this.state.tab === "config" ? "active tab" : "tab"}
          data-act="config"
          onClick={this.changeTab}
        >
          Configuration
        </a>
        <a
          // style={{
          //   display: this.state.hasIpTrunking && this.state.hasIpTrunking.toString() === 'true' ? "inline-block" : "none"
          // }}
          className={this.state.tab === "appList" ? "active tab" : "tab"}
          data-act="appList"
          onClick={this.changeTab}
        >
          App List
        </a>
        <a
          // style={{
          //   display: this.state.hasIpTrunking && this.state.hasIpTrunking.toString() === 'true' ? "inline-block" : "none"
          // }}
          className={this.state.tab === "data" ? "active tab" : "tab"}
          data-act="data"
          onClick={this.changeTab}
        >
          Data
        </a>
      </div>
    );
  };

  changeTab = event => {
    if (event.target.dataset.act !== this.state.tab) {
      this.setState({ tab: event.target.dataset.act });
    }
  };

  getTab() {
    let { tab } = this.state;
    switch (tab) {
      case "config":
        return <AdConfig
          ConnectorInfo={this.state.ConnectorInfo}
          //Loading={this.state.loading}
        />;
      case "appList":
        return (
          <AppList />
        );
      case "data":
        return (
          <SyncMenu
            UserList={this.state.UserList}
          />
        );
      default:
        console.log("Sorry there isn't a tab named " + tab + ".");
    }
  }

  cardContent = () => {
    if (!this.state.loading) {
      return (
        <Fragment>
          <div className="card-content">
            <div className="tab-body">
              {this.getTab()}
            </div>
          </div>
        </Fragment>
      )
    } else {
      return <div>
        <DataLoadingSpinner style={{ marginTop: "3%" }} className='load spinner' />
      </div>
    }
  }

  render() {
    return (
      <div className="sync-menu-container">
        <div className="container full-card">
          <div className="sync-menu card">
            <div className="card-header">
              <h1>Single Sign On</h1>
            </div>
            {this.tabDisplay()}
            {this.cardContent()}
          </div>
        </div >
      </div>
    )
  }
}