import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { fetchData } from './../../requests/api_calls';
import { DataLoadingSpinner } from './../../components/spinners';

class QuickView extends Component {

  constructor() {
    super();
    this.state = {loading: true};
    this.fetchStats = this.fetchStats.bind(this);
  };

  componentDidMount() {
    this.fetchStats('admin/QuickView');
  }

  fetchStats = (apiPath, errorMsg) => {
    fetchData(apiPath).then((data) => {
      if (data) { 
        this.setState({
          CountLocations: data.CountLocations,
          CountServices: data.CountServices,
          CountUsers: data.CountUsers,
          CountDevices: data.CountDevices,
          CountServicesUsers: data.CountServicesUsers,
          loading: false
        });
      }
    });
  }

  quickViewContent = () => {
    if (this.state.loading) {
      return (
        <div>
          <div className="card-header">
            <h2 className="move">Quick View</h2>
          </div>
          <div className="card-content">
          <DataLoadingSpinner className='load spinner' />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="card-header">
            <h2 className="move">Quick View</h2>
          </div>
          <div className="card-content">
            <div className="quick-items">
              <div className="quick-item">
                <Link to='/locations' className="btn">Manage</Link>
                <label>Locations </label>
                <h3>{this.state.CountLocations}</h3>
              </div>
              <div className="quick-item">
                <Link to='/services' className="btn">Manage</Link>
                <label>Services & Users</label>
                <h3>{ this.state.CountServicesUsers || 0 }</h3>
              </div>
              <div className="quick-item">
                <label>Devices</label>
                <h3>{this.state.CountDevices}</h3>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="quick-view card">
        {this.quickViewContent()}
      </div>
    )
  }

}

export default QuickView;

