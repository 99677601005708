import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import * as EmailValidator from 'email-validator';
import { DataLoadingSpinner } from './../../components/spinners';

class VoicemailSettingsModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.changeForward = this.changeForward.bind(this);

    this.state = { 
      showModal: false
    };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  changeForward(event){
    this.setState({forwardValue: event.target.value})
  }

	componentDidMount() {	
	}


  render() {
    let model = this.props.model;

    let loading = model.loading;
    if(loading){
      return ( <DataLoadingSpinner className='load spinner'/>);
    }
      

    let loading_error = model.loading_error;
    if(loading_error){
      return (<div className="inline inline-fail text-center">{loading_error}</div>);
    }

    return (
      <div id="voicemailSettingsModal">
        <div className="form-group">
          <label className="right switch">
            <input type="checkbox" 
            checked={model.voice_messaging.selected} 
            onChange={(e)=>{this.props.onHandleChangeOnOffValue(model.voice_messaging,e)}}/>
            <span className="slider round"></span>
          </label>
          Voice Messaging
        </div>
        <hr />
        <div className="form-group">
          <label className="right switch">
            <input type="checkbox" 
            checked={model.send_all_calls_to_vm.selected} 
            onChange={(e)=>{this.props.onHandleChangeOnOffValue(model.send_all_calls_to_vm,e)}}/>
            <span className="slider round" ></span>
          </label>
          Send All Calls to Voicemail
        </div>
        <div className="form-group">
          <label className="right switch">
            <input type="checkbox" 
            checked={model.send_busy_calls_to_vm.selected} 
            onChange={(e)=>{this.props.onHandleChangeOnOffValue(model.send_busy_calls_to_vm,e)}}/>
            <span className="slider round"></span>
          </label>
          Send Busy Calls to Voicemail
        </div>
        <div className="form-group">
          <label className="right switch">
            <input type="checkbox" 
            checked={model.send_unanswered_calls_to_vm.selected} 
            onChange={(e)=>{this.props.onHandleChangeOnOffValue(model.send_unanswered_calls_to_vm,e)}}/>
            <span className="slider round"></span>
          </label>
          Send Unanswered Calls to Voicemail
        </div>
        <hr />
        <div className="forward">
          <div className="form-group">
            <label>
              <input type="radio" name="radio" id="one" defaultValue="0" 
                checked={model.forward_to_email.processing === "0"} 
                onChange={this.props.onHandleChangeForward} /> Forward to multiple emails</label>
            <label>
              <input type="radio" name="radio" id="two" defaultValue="1" 
                checked={model.forward_to_email.processing === "1"} 
                onChange={this.props.onHandleChangeForward} /> Forward to one email</label>
            {this.getForwardOption()}
          </div>
        </div>
        <hr />
        <div className="form-group">
          <label className="right switch">
            <input type="checkbox" 
            checked={model.notify_me_email_when_new_messages_arrive.selected} 
            onChange={(e)=>{this.props.onHandleChangeOnOffValue(model.notify_me_email_when_new_messages_arrive,e)}} data-toggle="collapse" data-target="#hide1" />
            <span className="slider round"></span>
          </label>
          Notify me by Email when new messages arrive
          <div className={model.notify_me_email_when_new_messages_arrive.selected? "hidden-group collapse in": "hidden-group collapse"} id="hide1">
            <label >Email Address</label>
            <input type="text" className="form-control" 
              value={model.notify_me_email_when_new_messages_arrive.email}
              onChange={(e)=>{this.props.onHandleChangeEmail(model.notify_me_email_when_new_messages_arrive,e)}}
            />
            {this.validateEmails(model.notify_me_email_when_new_messages_arrive)}
          </div>
        </div>
        <div className="form-group">
          <label className="right switch">
            <input type="checkbox" 
            checked={model.email_carbon_copy_message.selected} 
            onChange={(e)=>{this.props.onHandleChangeOnOffValue(model.email_carbon_copy_message,e)}}
            data-toggle="collapse" data-target="#hide2" />
            <span className="slider round"></span>
          </label>
          Email a carbon copy of the message
          <div className={model.email_carbon_copy_message.selected? "hidden-group collapse in": "hidden-group collapse"} id="hide2">
            <label >Email Address</label>
            <input type="text" className="form-control" 
             value={model.email_carbon_copy_message.email}
            onChange={(e)=>{this.props.onHandleChangeEmail(model.email_carbon_copy_message,e)}}/>
            {this.validateEmails(model.email_carbon_copy_message)}
          </div>
        </div>
        <div className="form-group">
          <label className="right switch">
            <input type="checkbox"  
            checked={model.transfer_on_0_to_phone_number.selected} 
            onChange={(e)=>{this.props.onHandleChangeOnOffValue(model.transfer_on_0_to_phone_number,e)}} 
             />
            <span className="slider round"></span>
          </label>
          Transfer on '0' to Phone Number
          <div className={model.transfer_on_0_to_phone_number.selected? "hidden-group collapse in": "hidden-group collapse"}>
            <label >Phone Number</label>
            <input type="text" className="form-control" 
             value={model.transfer_on_0_to_phone_number.phone_number}
            onChange={(e)=>{this.props.onHandleChangePhone(model.transfer_on_0_to_phone_number,e)}}/>
          </div>
        </div>
      </div>
    );
  }

  validateEmail=(setting)=>{
    if(!setting.email_is_valid)
      return <div className="inline inline-fail">'{setting.email}' Not a valid email</div>
    if(!EmailValidator.validate(setting.email))
      return <div className="inline inline-fail">'{setting.email}' Not a valid email</div>
  }
  validateEmails=(setting)=>{
    if(!setting.email_is_valid){
      if(setting.email){
        let emailsArray = setting.email.split(',');
        for(let cnt = 0; cnt < emailsArray.length; cnt ++){
          if(!EmailValidator.validate(emailsArray[cnt].trim()))
            return <div className="inline inline-fail">'{emailsArray[cnt].trim()}' Not a valid email</div>
        }
      }
      else{
        return <div className="inline inline-fail">Not a valid email</div>
      }
     
    }
  }
  getForwardOption(){
    //console.log(this.props)
    let model = this.props.model;
    switch(model.forward_to_email.processing){
      case "0":
        return (
          <div className="hidden-group">
            <div className="multiple">
              <div className="form-group">
                <label>Add up to five email addresses: <small>Seperate each email by a comma</small></label>
                <textarea name="" id="" className="form-control" placeholder="example1@momemtum.com, example2@momemtum.com"
                  value={model.forward_to_email.email}
                  onChange={(e)=>{this.props.onHandleChangeEmail(model.forward_to_email,e)}}
                ></textarea>
                {this.validateEmails(model.forward_to_email)}
              </div>
              <div className="form-group">
                <label><input type="checkbox" id="waitingindicator" 
                  checked={model.forward_to_email.waiting_indicator}
                  onChange={(e)=>{this.props.onHandleChangeWaitingIndicator(model.forward_to_email,e)}}
                /> Phone Message Waiting Indicator</label>
              </div>
            </div>
          </div>
        );
      case "1":
        return (
          <div className="hidden-group">
            <div className="single">
              <label>Forward to this email:</label>
              <input type="text" 
              value={model.forward_to_email.email}
              onChange={(e)=>{this.props.onHandleChangeEmail(model.forward_to_email,e)}}
              className="form-control" placeholder="add an email address" />
              {this.validateEmails(model.forward_to_email)}
              <div className="form-group">
                <label><input type="checkbox" id="waitingindicator" 
                  checked={model.forward_to_email.waiting_indicator}
                  onChange={(e)=>{this.props.onHandleChangeWaitingIndicator(model.forward_to_email,e)}}
                /> Phone Message Waiting Indicator</label>
              </div>
            </div>
          </div>
        );
    }
  }
};



export default VoicemailSettingsModal;
