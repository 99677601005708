import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import SaveStatus from '../../../components/save_status';



export default class messageDeposit extends Component {
    constructor() {
        super();
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        console.log(this);
        //initial state values
        this.state = {
            loading: false,
            showModal: false,
        }


    }
    componentDidMount() {
        // here executes code when component is loaded
    }

    modalBody = () => {
        // checkbox html?
    }

    openModal() {
        this.setState({ showModal: true });
    }

    closeModal() {
        this.setState({ showModal: false });
    }



    render() {
        let model = this.props.Model || {};

        return (
            <span className='trunk-modal-wrapper'>
                <a className="btn" onClick={this.openModal}>Message Deposit</a>
                <Modal dialogClassName="ip-trunk-modal"
                    show={this.state.showModal}
                    onHide={this.closeModal}
                >
                    <Modal.Header>
                        <Modal.Title>Message Deposit</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="form-group">
                                <label>General</label>
                                <div className="form-control">
                                    <label className="custom-control">
                                        <input id="radio11" name="radio11" type="checkbox" value="0"
                                           
                                            onChange={(e) => {  }} />
                                        <span >Disable Message Deposit</span>
                                    </label>
                                   
                                </div>
                             
                            </div>
                            <div className="form-group">
                                <label>No Answer Greeting</label>
                                <div className="form-control">
                                    <label className="custom-control">
                                        <input id="radio1" name="radio1" type="checkbox" value="0"
                                            
                                            onChange={(e) => { }} />
                                        <span >Disable Message Deposit</span>
                                    </label>
                                    
                                </div>
                               
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ textAlign: 'left' }}>
                        <hr />
                        <div className="btns">
                            <span><Button className="btn" click={() => console.log('Submitted')}>Submit</Button></span>
                            <span><Button className="btn" click={() => this.closeModal()}>Cancel</Button></span>
                        </div>
                        <SaveStatus
                            saving={this.state.saving}
                            statusMessage={this.state.statusMessage}
                            error={this.state.error}
                            parentCallBack={this.ClearSaveStatusModal}
                        />
                    </Modal.Footer>
                </Modal>
            </span>
        )
    }
}

