import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import Voicemail from './voicemail';
import VoicemailSettingsButton from './../voicemailSettingsButton'; //move file and change import
import ManageGreetingsButton from './../manageGreetingsButton';
import ChangePinModal from './../modals/changePinModal';
import VoicemailBlockModal from './../modals/voicemailBlockModal';
import VoicemailDeleteModal from './../modals/voicemailDeleteModal';
import PinResetButton from './components/pinResetButton';
import PinResetModal from './modals/pinResetModal';
import { DataLoadingSpinner } from './../../components/spinners';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import LongSwitch from './../../components/long_switch';
import SuperLink from './../../components/super_link';
import { fetchData, postData, postDataWithCustomHeader, post } from './../../requests/api_calls';
import * as settings from './../../scripts/session';
import * as config from './../../requests/config'
import format from 'string-format';
import { noResultsMsg } from './../../scripts/settings';
import { Link } from 'react-router-dom';

const VMsConfirmationMsg = "Are you sure you want to block all calls and voicemails from these numbers?";
const VMConfirmationMsg = "Are you sure you want to block all calls and voicemails from this number?";

class VoiceMailAll extends Component {

  constructor() {
    super();

    this.state = this.initialState();

    this.handleHideSendModal = this.handleHideSendModal.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.handleChangeEmailText = this.handleChangeEmailText.bind(this);

  }

  initialState = () => {
    return {
      enable: false,
      voicemail_switch: {
        visible: false,
        isActive: true,
        show_success: false,
        show_error: false,
        loading: false,
        vm_settings: null
      },
      voicemails: [],
      voicemail_count: 0,
      voicemail_fetch_error: "",
      loading: true,
      activevm: {
        account_number: "",
        directory_number: "",
        filename: ""
      },
      sendModal: {
        show: false,
        emailText: "",
        emailTextValid: true,
        errorMessage: "",
        loading: false,
      },
      delete: {
        voicemails: [],
        error_message: [],
        loading: false
      },
      block: {
        voicemails: [],
        error_message: [],
        show_dialog: false,
        show_success: false,
        loading: false,
        save_result: []
      }
    };
  }

  componentDidMount() {
    this.enableUserMessaging(this.props);
  }

  enableUserMessaging = (props) => {
    this.setState(this.initialState());
    let basicData = { ServiceNumber: this.props.ServiceNumber, PBXUserID: this.props.PBXUserID };
    let apiUrl = "";
    if (this.props.ServiceNumber && this.props.PBXUserID)
      apiUrl = format('usr/AssignedServices?ServiceNumber={ServiceNumber}&PBXUserID={PBXUserID}', basicData);
    else if (this.props.ServiceNumber)
      apiUrl = format('usr/AssignedServices?ServiceNumber={ServiceNumber}', basicData);
    else if (this.props.PBXUserID)
      apiUrl = format('usr/AssignedServices?PBXUserID={PBXUserID}', basicData);

    // let state = {...this.state};
    // state.enable = true;
    // state.loading = false;
    // this.loadSettings(props);
    fetchData(apiUrl).then((data) => {
      //console.log('--- ARRAY WITH 52 OBJECTS (line 389) ---', data);

      let state = { ...this.state };
      state.enable = false;
      state.loading = false;
      if (data) {
        //console.log("user/AssignedServices");
        //console.log(data);
        let filterService = data.filter((item) => { return item.ServiceName === "VoiceMessagingUser" && item.IsActiveSpecified })
        //console.log(filterService);
        if (filterService.length > 0) {
          let service = filterService[0];
          state.enable = true;
          state.loading = true;
          // if(service.IsActive){
          //   state.enable = true;
          //   state.loading = true;
          //   console.log("enableUserMessaging.props")
          //   console.log(props);
          //   this.loadSettings(props);
          // }
          this.loadSettings(props);
        }
      }
      this.setState(state);
    }).catch((message) => {
      let state = { ...this.state };
      state.enable = false;
      state.loading = false;
      this.setState(state);
    });
  }

  loadSettings = (props) => {
    let vmOnOffApi = format('usr/VMSettings?ServiceNumber={ServiceNumber}&PBXUserID={PBXUserID}&AccountNumber={AccountNumber}&DirectoryNumber={DirectoryNumber}', props);
    fetchData(vmOnOffApi).then((data) => {
      let state = { ...this.state };
      state.voicemail_switch.visible = true;
      state.voicemail_switch.vm_settings = data;
      if (data !== null) {
        // console.log("loadSettings")
        // console.log(data);
        state.voicemail_switch.isActive = data.isActive;
        state.voicemails = [];
        state.voicemail_count = 0;
        if (state.voicemail_switch.isActive) {
          state.loading = true;
          let apiUrl = format('vm/VMList?AccountNumber={AccountNumber}&DirectoryNumber={DirectoryNumber}', props);
          //console.log('--- PERSONAL ACCOUNT INFO ---', data);
          this.fetchVoiceMails(apiUrl);
        }
        else {
          state.loading = false;
        }
      }
      else {
        state.voicemails = [];
        state.voicemail_count = 0;

        state.loading = true;
        let apiUrl = format('vm/VMList?AccountNumber={AccountNumber}&DirectoryNumber={DirectoryNumber}', props);
        this.fetchVoiceMails(apiUrl);
      }

      this.setState(state);
    }).catch((message) => {
      let state = { ...this.state };
      state.voicemail_switch.show_error = true;
      this.setState(state);
    });
  }

  fetchVoiceMails = (apiPath, errorMsg) => {
    fetchData(apiPath).then((data) => {
      //console.log('--- LIST OF VOICEMAILS ---', data);


      if (data) {
        this.loadData(data);
      }
      else {
        let state = { ...this.state };
        state.voicemails = [];
        state.voicemail_count = 0;
        state.loading = 0;
        this.setState(state);
      }
    }).catch((message) => {
      let state = { ...this.state };
      state.voicemails = [];
      state.voicemail_count = 0;
      state.loading = 0;
      state.voicemail_fetch_error = message.toString()
    });
  }

  loadData = (data) => {
    // individual VMs, removed the limit of 5
    let state = { ...this.state };

    let voicemails_data = this.sortedByDate(data);
    // if (data.length > 5) {
    //   voicemails_data = data.slice(0, 5);
    // }
    voicemails_data = voicemails_data.map((vm, index) => {
      vm.selected = false;
      //console.log(vm);
      return vm;
    });

    state.voicemails = voicemails_data;
    state.voicemail_count = data.length;
    state.loading = false;

    this.setState(state);
  }

  sortedByDate = (data) => {
    data.sort((a, b) => new Date(b.DateTimeLocal) - new Date(a.DateTimeLocal));
    return data;
  }

  handleDeleteModalShow() {
    this.setState({ ...{ delete: { voicemails: [], error_message: [], show_dialog: true } } });
  }

  handleDeleteVM() {
    let selected_voicemails = this.state.voicemails.filter((vmLocal, index) => {
      return vmLocal.selected;
    });

    this.setState({ ...{ delete: { voicemails: selected_voicemails, error_message: [] } } });
    selected_voicemails.forEach(element => {
      let body = {
        AccountNumber: element.AccountNumber,
        DirectoryNumber: element.DirectoryNumber,
        FileName: element.Location,
        access_token: settings.storedAccessToken(),
        x_policy: settings.storedXPolicy(),
      };
      let headers = {
        'Content-Type': 'application/x-www-form-urlencoded', 
        'Accept': 'text/html'
      }
      postDataWithCustomHeader("vm/VMDelete", body, headers)
        .then(response => {

          let newdeletedvms = this.state.delete.voicemails.filter((vmLocal, index) => {
            return vmLocal.Location != element.Location;
          });

          if (response.ok) {
            let newvoicemails = this.state.voicemails.filter((vmLocal, index) => {
              return vmLocal.Location != element.Location;
            });
            this.setState({ ...{ voicemails: newvoicemails, voicemail_count: this.state.voicemail_count - 1, delete: { voicemails: newdeletedvms } } });
          }
          else {
            let error_message = this.state.delete.error_message;
            error_message.push(response.message);
            this.setState({ ...{ delete: { error_message: error_message, voicemails: newdeletedvms } } });
          }

        })
        .catch((message) => {
          let newdeletedvms = this.state.delete.voicemails.filter((vmLocal, index) => {
            return vmLocal.Location != element.Location;
          });

          let error_message = this.state.delete.error_message;
          error_message.push(message.toString());
          this.setState({ ...{ delete: { error_message: error_message, voicemails: newdeletedvms } } });
        });
    });
  }

  handleBlockModalShow() {
    this.setState({ ...{ block: { voicemails: [], error_message: [], show_dialog: true, show_success: false, loading: false, save_result: [] } } });
  }

  handleBlockModalClose() {
    let state = { ...this.state };
    state.voicemails.forEach((vmLocal, index) => {
      vmLocal.selected = false;
    });
    state.block.voicemails = [];
    state.block.error_message = [];
    state.block.show_dialog = false;
    state.block.show_success = false;
    state.block.loading = false;
    state.block.save_result = [];
    this.setState(state);
  }

  handleBlockVM() {
    let state = { ...this.state };
    let selected_block_numbers = state.voicemails.filter((vmLocal, index) => {
      return vmLocal.selected;
    }).map(e => e.FromPhone);
    state.block.voicemails = selected_block_numbers;
    state.block.error_message = [];
    state.block.loading = true;
    this.setState(state);

    let body = {
      BlockNumbers: selected_block_numbers,
      AccountNumber: this.props.AccountNumber,
      DirectoryNumber: this.props.DirectoryNumber,
      PBXUserID: this.props.PBXUserID,
    };

    body = JSON.stringify(body);
    let header = {
      'Authorization': 'Bearer ' + config.storedAccessToken(),
      'X-Policy': config.storedXPolicy(),
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    post("vm/VMBlock", "POST", body, header)
      .then(response => {
        let state = { ...this.state };
        state.block.loading = false;
        state.block.show_success = response.ok;
        if (!response.ok)
          state.block.error_message.push("Failed to save");
        else {
          state.block.save_result = JSON.parse(response.message);
        }
        this.setState(state);
      })
      .catch((message) => {
        let state = { ...this.state };
        state.block.loading = false;
        state.block.error_message.push("Failed to save");
        state.block.show_success = false;
        this.setState(state);
      });

  }

  handleChangeEmailText(event) {
    let sendModal = this.state.sendModal;
    sendModal.emailText = event.target.value;
    sendModal.errorMessage = "";
    sendModal.emailTextValid = true;
    this.setState({ ...{ sendModal: sendModal } });
  }

  validateEmail() {
    return this.state.sendModal.emailText.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  }

  handleSend() {
    let sendModal = this.state.sendModal;

    if (!this.validateEmail()) {
      sendModal.emailTextValid = false;
      this.setState({ ...{ sendModal: sendModal } });
      return;
    }

    sendModal.emailTextValid = true;
    sendModal.loading = true;
    sendModal.errorMessage = null;
    this.setState({ ...{ sendModal: sendModal } });

    let body = {
      AccountNumber: this.state.activevm.account_number,
      DirectoryNumber: this.state.activevm.directory_number,
      FileName: this.state.activevm.filename,
      ToEmail: this.state.sendModal.emailText,
      access_token: settings.storedAccessToken(),
      x_policy: settings.storedXPolicy(),
    };

    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded', 
      'Accept': 'text/html'
    }

    postDataWithCustomHeader("vm/VMForward", body, headers)
      .then(response => {
        if (response.ok) {
          this.setState({ ...{ sendModal: { show: false, emailText: "", errorMessage: "" } } });
        }
        else {
          this.setState({ ...{ sendModal: { show: true, emailText: this.state.sendModal.emailText, errorMessage: "Error: Please contact administrator" } } });
        }
      }
      ).catch((message) => {
        this.setState({ ...{ sendModal: { show: true, emailText: this.state.sendModal.emailText, errorMessage: "Error: Please contact administrator" } } });

      });
  }

  handleHideSendModal() {

    let sendModal = this.state.sendModal;
    sendModal.show = false;
    sendModal.emailText = "";
    this.setState({ ...{ sendModal: sendModal } });
  }

  handledSelectChanged = (vm, event) => {
    let voicemails_data = this.state.voicemails.map((vmLocal, index) => {
      if (vmLocal.Location === vm.Location) {
        vmLocal.selected = !vmLocal.selected;
      }
      return vmLocal;
    });
    let state = { ...this.state };
    state.voicemails = voicemails_data;
    console.log("handledSelectChanged");
    console.log(state);
    this.setState(state);
  }

  handleShowSendModal = (vm, event) => {
    let activevm =
    {
      account_number: vm.AccountNumber,
      directory_number: vm.DirectoryNumber,
      filename: vm.Location
    };
    let sendModal = this.state.sendModal;
    sendModal.show = true;
    sendModal.emailText = "";
    sendModal.emailTextValid = true;
    sendModal.errorMessage = "";
    this.setState({ ...{ sendModal: sendModal, activevm: activevm } });
  }

  handleDownload = (vm, event) => {
    let activevm = {
      account_number: vm.AccountNumber,
      directory_number: vm.DirectoryNumber,
      filename: vm.Location
    };
    this.setState({ ...{ activevm: activevm } });

    setTimeout(() => {
      this.refs.downloadSubmit.submit();
    }, 500);
  }

  handlePlay = (vm, event) => {
    let activevm = {
      account_number: vm.AccountNumber,
      directory_number: vm.DirectoryNumber,
      filename: vm.Location
    };
    this.setState({ ...{ activevm: activevm } });

    setTimeout(() => {
      this.refs.playSubmit.submit();
    }, 500);
  }

  onHandleVoicemail = (event) => {
    let state = { ...this.state };
    state.voicemail_switch.isActive = event.target.checked;
    state.voicemail_switch.loading = true;
    state.voicemail_switch.show_success = false;
    state.voicemail_switch.show_error = false;

    state.voicemail_switch.vm_settings.isActive = event.target.checked
    this.setState(state);

    let body = JSON.stringify(state.voicemail_switch.vm_settings);
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + config.storedAccessToken(),
      'X-Policy': config.storedXPolicy()
    };
    post("usr/VMSettings", "POST", body)
      .then(response => {
        let state = { ...state };
        state.voicemail_switch.loading = false;
        if (response.ok) {
          state.voicemail_switch.show_success = true;
          state.voicemail_switch.show_error = false;
          state.voicemails = [];
          state.voicemail_count = 0;

          if (state.voicemail_switch.isActive) {
            state.loading = true;
            let apiUrl = format('vm/VMList?AccountNumber={AccountNumber}&DirectoryNumber={DirectoryNumber}', this.props);
            this.fetchVoiceMails(apiUrl);
          }
          else {
            state.loading = false;
          }
        }
        else {
          state.voicemail_switch.show_success = true;
          state.voicemail_switch.show_error = false;
        }
        this.setState(state);
      })
      .catch((message) => {
        let state = { ...this.state };
        state.voicemail_switch.loading = false;
        state.voicemail_switch.show_success = true;
        state.voicemail_switch.show_error = false;
        this.setState(state);
      });
  }

  handleVMSettingChange = (vmSetting) => {
    let state = { ...this.state };
    state.voicemail_switch.isActive = vmSetting.isActive;
    this.setState(state);
  }

  errorMessage() {
    if (!this.state.sendModal.loading && this.state.sendModal.errorMessage && this.state.sendModal.errorMessage !== "")
      return <div className="alert alert-danger">{this.state.sendModal.errorMessage}</div>;

    if (!this.state.sendModal.emailTextValid)
      return <div className="alert alert-danger">Invalid Email</div>;

    return "";
  }

  sendModalLoading() {
    if (this.state.sendModal.loading)
      return <DataLoadingSpinner className='load spinner pull-left' />;

    return "";
  }

  getVoiceMails() {
    let voicemails_data = this.state.voicemails;
    let voicemails = voicemails_data.map((vm, index) => {
      return (
        <Voicemail key={index}
          selected={vm.selected}
          phone={vm.FromPhone}
          datetime={vm.DateTimeLocal}
          account_number={vm.AccountNumber}
          directory_number={vm.DirectoryNumber}
          filename={vm.Location}
          handledSelectChanged={this.handledSelectChanged.bind(null, vm)}
          handlePlay={this.handlePlay.bind(null, vm)}
          handleDownload={this.handleDownload.bind(null, vm)}
          handleShowSendModal={this.handleShowSendModal.bind(null, vm)}
        />
      )
    });

    if (voicemails.length < 5) {
      let vmLength = 5 - voicemails.length;
      voicemails.push(<tr key={voicemails.length + 2}><td colSpan="6" style={{ height: (vmLength * 37) }}></td></tr>);
    }

    return voicemails;
  }

  getBlockAndDeleteRow() {
    let selectedVMcount = this.state.voicemails.filter((vm, index) => { return vm.selected }).length;
    if (selectedVMcount > 0) {
      let vm_delete_error_body = null;
      if (this.state.delete.error_message && this.state.delete.error_message.length && this.state.delete.error_message.length > 0)
        this.state.delete.error_message.map((message, index) => {
          return <div key={index} className="alert alert-danger">{message}</div>
        });

      // let vm_block_error_body = 
      // (<div className="alert alert-danger">
      //   {
      //     this.state.block.error_message.map((message, index)=>{ 
      //       return <div>{message}</div>
      //     })
      //   }
      //   </div>);

      // let vm_block_error_body = this.state.block.error_message.map((message, index)=>{ 
      //   return <div key={index} >{message}</div>
      // });

      // Decide which confirmation message will be displayed on the block calls modal
      let confirmationMsg = selectedVMcount > 1 ? VMsConfirmationMsg : VMConfirmationMsg;

      return (<tr>
        <td colSpan="6">
          <VoicemailBlockModal
            confirmationMsg={confirmationMsg}
            errorMessage={this.state.block.error_message}
            showError={this.state.block.error_message.length > 0}
            saveResult={this.state.block.save_result}
            loading={this.state.block.loading}
            show={this.state.block.show_dialog}
            showSuccess={this.state.block.show_success}
            handleYes={this.handleBlockVM.bind(this)}
            handleShow={this.handleBlockModalShow.bind(this)}
            handleClose={this.handleBlockModalClose.bind(this)}
          />  |
          <VoicemailDeleteModal body={vm_delete_error_body}
            loading={this.state.delete.voicemails.length > 0}
            handleYes={this.handleDeleteVM.bind(this)}
            handleShow={this.handleDeleteModalShow.bind(this)} />
        </td>
      </tr>);
    }
    else {
      return null;
    }
  }

  getFetchVoiceMailError() {
    if (this.state.voicemail_fetch_error.length > 0)
      return (
        (<tr>
          <td colSpan="6">
            {this.state.voicemail_fetch_error}
          </td>
        </tr>)
      )

  }

  getVoiceMailOnOff() {

    let vm_switch = this.state.voicemail_switch;
    let showSuccess = "";
    if (!vm_switch.vm_settings) {
      return (<div></div>)
    }

    if (vm_switch.show_success) {
      showSuccess = <ReactCSSTransitionGroup
        transitionName="vm-action"
        transitionAppear={true}
        transitionAppearTimeout={4000}
        transitionEnter={true}
        transitionEnterTimeout={4000}
        transitionLeave={false}
      >
        <div className="inline inline-success">Success!</div>
      </ReactCSSTransitionGroup>
    }

    let showError = "";
    if (vm_switch.show_error) {
      showError = <div className="inline inline-fail">The switch failed! Please try again.</div>
    }

    let showLoading = ""
    if (vm_switch.loading) {
      showLoading = <div className="loading">
        <DataLoadingSpinner className='load spinner' />
      </div>;
    }
    return (
      <div className="vm-action">
        <div className="feature">
          <LongSwitch
            label="Voicemail"
            on={vm_switch.isActive}
            onChange={this.onHandleVoicemail} />
          {showSuccess}
          {showError}
          {showLoading}
        </div>
      </div>
    );
  }

  getVoiceMailAction = () => {
    if (!this.state.enable)
      return "";
    return (<div className="actions">
      <VoicemailSettingsButton
        ServiceNumber={this.props.ServiceNumber}
        PBXUserID={this.props.PBXUserID}
        AccountNumber={this.props.AccountNumber}
        DirectoryNumber={this.props.DirectoryNumber}
        OnVMSettingChange={this.handleVMSettingChange}
      />
      <ManageGreetingsButton
        ServiceNumber={this.props.ServiceNumber}
        PBXUserID={this.props.PBXUserID}
      />
      {/* <PinResetButton /> */}
      <PinResetModal
        AccountNumber={this.props.AccountNumber}
        DirectoryNumber={this.props.DirectoryNumber}
        Extension={this.props.Extension}
      />
    </div>);
  }

  voicemailsTable = () => {
    if (this.state.voicemail_switch.visible && !this.state.voicemail_switch.isActive)
      return (<div></div>);

    if (this.state.voicemails.length > 0) {
      return (
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th className="check"></th>
              <th>Play</th>
              <th>Phone</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.getBlockAndDeleteRow()}
            {this.getVoiceMails()}
            {this.getFetchVoiceMailError()}
          </tbody>
        </table>
      )
    }
    else if (!this.state.enable) {
      return (
        <h4 className='empty'>
          Voicemail is a not a feature of this service
          </h4>
      )
    }
    else {
      return (
        <h4 className='empty'>
          No recent voicemails
          </h4>
      )
    }
  }

  vmTable() {
    return (
      <div>Table Here</div>
    )
  }

  render() {
    let playUrl = config.baseApiUrl + "vm/VMPlay";
    let saveUrl = config.baseApiUrl + "vm/VMSave";
    if (this.state.loading) {
      return (
        <div className='full-card container'>
          <div className="voicemail card">
            <div className="card-header">
              <div className='card-actions'><div className='move'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m512 256c0 5-2 9-5 13l-74 73c-3 4-7 5-12 5-5 0-10-1-13-5-4-4-6-8-6-13l0-36-109 0 0 109 36 0c5 0 9 2 13 6 4 3 5 8 5 13 0 5-1 9-5 12l-73 74c-4 3-8 5-13 5-5 0-9-2-13-5l-73-74c-4-3-5-7-5-12 0-5 1-10 5-13 4-4 8-6 13-6l36 0 0-109-109 0 0 36c0 5-2 9-6 13-3 4-8 5-13 5-5 0-9-1-12-5l-74-73c-3-4-5-8-5-13 0-5 2-9 5-13l74-73c3-4 7-5 12-5 5 0 10 1 13 5 4 4 6 8 6 13l0 36 109 0 0-109-36 0c-5 0-9-2-13-6-4-3-5-8-5-13 0-5 1-9 5-12l73-74c4-3 8-5 13-5 5 0 9 2 13 5l73 74c4 3 5 7 5 12 0 5-1 10-5 13-4 4-8 6-13 6l-36 0 0 109 109 0 0-36c0-5 2-9 6-13 3-4 8-5 13-5 5 0 9 1 12 5l74 73c3 4 5 8 5 13z' transform='scale(0.046875 0.046875)'></path></svg></div><div className='expand'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m26 344l-26 158c0 2 1 5 3 7c0 0 0 0 0 0c2 2 5 3 7 3l158-27c3 0 6-3 7-6c1-3 0-7-3-9l-30-31l82-82c4-4 4-9 0-13l-57-57c-3-3-9-3-12 0l-83 83l-31-31c-2-2-5-3-9-2c-3 1-5 4-6 7z m460-176l26-158c0-2-1-5-3-7c0 0 0 0 0 0c-2-2-5-3-7-3l-158 27c-3 0-6 3-7 6c-1 3 0 7 3 9l30 31l-82 82c-4 4-4 9 0 13l57 57c3 3 9 3 12 0l83-83l31 31c2 2 5 3 9 2c3-1 5-4 6-7z' transform='scale(0.046875 0.046875)'></path></svg></div></div>
              <h2 className="move">Voicemail</h2>
            </div>
            <div className="vm-actions card-content">
              <DataLoadingSpinner className='load spinner pull-left' />
            </div>
          </div>
        </div>
      );
    }

    let voiceCount = ""
    if (this.state.enable)
      voiceCount = this.state.voicemail_count;

    return (
      <div className='full-card container'>
        <div className="voicemail card">
          <div className="card-header">
            <div className='card-actions'><div className='move'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m512 256c0 5-2 9-5 13l-74 73c-3 4-7 5-12 5-5 0-10-1-13-5-4-4-6-8-6-13l0-36-109 0 0 109 36 0c5 0 9 2 13 6 4 3 5 8 5 13 0 5-1 9-5 12l-73 74c-4 3-8 5-13 5-5 0-9-2-13-5l-73-74c-4-3-5-7-5-12 0-5 1-10 5-13 4-4 8-6 13-6l36 0 0-109-109 0 0 36c0 5-2 9-6 13-3 4-8 5-13 5-5 0-9-1-12-5l-74-73c-3-4-5-8-5-13 0-5 2-9 5-13l74-73c3-4 7-5 12-5 5 0 10 1 13 5 4 4 6 8 6 13l0 36 109 0 0-109-36 0c-5 0-9-2-13-6-4-3-5-8-5-13 0-5 1-9 5-12l73-74c4-3 8-5 13-5 5 0 9 2 13 5l73 74c4 3 5 7 5 12 0 5-1 10-5 13-4 4-8 6-13 6l-36 0 0 109 109 0 0-36c0-5 2-9 6-13 3-4 8-5 13-5 5 0 9 1 12 5l74 73c3 4 5 8 5 13z' transform='scale(0.046875 0.046875)'></path></svg></div><div className='expand'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m26 344l-26 158c0 2 1 5 3 7c0 0 0 0 0 0c2 2 5 3 7 3l158-27c3 0 6-3 7-6c1-3 0-7-3-9l-30-31l82-82c4-4 4-9 0-13l-57-57c-3-3-9-3-12 0l-83 83l-31-31c-2-2-5-3-9-2c-3 1-5 4-6 7z m460-176l26-158c0-2-1-5-3-7c0 0 0 0 0 0c-2-2-5-3-7-3l-158 27c-3 0-6 3-7 6c-1 3 0 7 3 9l30 31l-82 82c-4 4-4 9 0 13l57 57c3 3 9 3 12 0l83-83l31 31c2 2 5 3 9 2c3-1 5-4 6-7z' transform='scale(0.046875 0.046875)'></path></svg></div></div>
            <h2 className="move">Voicemail <small>{voiceCount}</small></h2>
          </div>
          <div className="vm-actions card-content">
            {this.getVoiceMailOnOff()}
          </div>
          <div>
            {this.voicemailsTable()}
            <div className="selected-action">
              <a href="">Delete Selected</a> |
          <a href="">Forward Selected</a>
            </div>
            {/*<div className="view-more">
          <SuperLink label="View All Voicemails" href="" />
        </div>*/}
          </div>
          <br />
          {this.getVoiceMailAction()}

          <div className="hidden">
            <iframe title="ignore this frame" name="PlayIFrame" ref="iframe" className="iframe-card iframe-settings"></iframe>
            <form
              ref="playSubmit"
              target="PlayIFrame"
              method="POST"
              action={playUrl}>
              <input type="hidden" name="AccountNumber" defaultValue={this.state.activevm.account_number} />
              <input type="hidden" name="DirectoryNumber" defaultValue={this.state.activevm.directory_number} />
              <input type="hidden" name="FileName" defaultValue={this.state.activevm.filename} />
              <input type="hidden" name="access_token" defaultValue={settings.storedAccessToken()} />
              <input type="hidden" name="x_policy" defaultValue={settings.storedXPolicy()} />
              <input type="submit" />
            </form>
          </div>
          <div className="hidden">
            <iframe title="ignore this frame" name="DownloadIFrame" ></iframe>
            <form
              ref="downloadSubmit"
              target="DownloadIFrame"
              method="POST"
              action={saveUrl}>
              <input type="hidden" name="AccountNumber" defaultValue={this.state.activevm.account_number} />
              <input type="hidden" name="DirectoryNumber" defaultValue={this.state.activevm.directory_number} />
              <input type="hidden" name="FileName" defaultValue={this.state.activevm.filename} />
              <input type="hidden" name="access_token" defaultValue={settings.storedAccessToken()} />
              <input type="hidden" name="x_policy" defaultValue={settings.storedXPolicy()} />
              <input type="submit" />
            </form>
          </div>
          <Modal show={this.state.sendModal.show} onHide={this.handleHideSendModal}>
            <Modal.Header closeButton>
              <Modal.Title> Send Voicemail </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>

                <div className="form-group">
                  <label>Email</label>
                  <input className="form-control" default={this.state.sendModal.emailText} onChange={this.handleChangeEmailText} />
                </div>
                {this.errorMessage()}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <hr />
              {this.sendModalLoading()}
              <div className="btns">
                <button className="btn" onClick={this.handleSend} disabled={this.state.sendModal.loading}>Send Voicemail</button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    AccountNumber: state.reducer.AccountNumber,
    DirectoryNumber: state.reducer.DirectoryNumber,
    ServiceNumber: state.reducer.ServiceNumber,
    PBXUserID: state.reducer.PBXUserID
  }
}

export default connect(mapStateToProps)(VoiceMailAll);