import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DataLoadingSpinner } from '../../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { Modal } from 'react-bootstrap';
import { headerToolTip } from '../../components/ellipsisHeaderTooltip';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import * as apiCalls from '../../requests/api_calls';
import * as config from '../../requests/config';
import * as settings from '../../scripts/settings';
import * as utility from '../../utility/utility';

export default class AdConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      EnterpriseId: sessionStorage.enterpriseId,
      Submission: false,
      submitError: false,
      hasConnector: false,
      FeatureEnabled: sessionStorage.hasActiveDirectory == "true" ? true : false, // find way to discern value (tbl.Enterprise)... hardcoded for now 9/24/20
      CurrentAdSystem: this.props.AdSystem, // find way to discern value (tbl.Enterprise)... hardcoded for now 9/24/20
      //ConnectorInfo: {},
      ConnectorInfo: this.props.ConnectorInfo,
      EmptyConnectorInfo: {},
      showConfirmationModal: false,
      //
      hasConnector: true,
      //Loading: this.props.Loading,
    };
  }

  componentWillReceiveProps() {
    if (this.props.ConnectorInfo !== this.state.ConnectorInfo) {
      this.setState({ ConnectorInfo: this.props.ConnectorInfo });
    }
  }

  handleInputChange = async (e, key) => {
    let { value } = e.target;
    
    // let ConnectorInfo = { ...this.state.ConnectorInfo };
    // ConnectorInfo[key] = value;
    // await this.setState({ ConnectorInfo });
    //console.log("ConnectorInfo", ConnectorInfo); // inserted AD Information
  }

  userCreds = () => {
    let { ConnectorInfo, EmptyConnectorInfo, CurrentAdSystem, hasConnector } = this.state;
    if (ConnectorInfo[0].IdPType === "Okta") {
      let {
        APIToken,
        CustomerIdPID,
        EnterpriseId,
        IdPAuthServer,
        IdPDomain,
        IdPName,
        IdPProtocol,
        IdPType,
        IdPUserName,
        IdPUserPwd,
      } = ConnectorInfo[0];
      return (
        <div>
          {this.dataRow("Domain", this.inputDataField(IdPDomain, "Domain", this.handleInputChange))}
          {this.dataRow("Token", this.inputDataField(APIToken, "Token", this.handleInputChange))}
        </div>
      );
    } else {
      let userField = ConnectorInfo.UserPrincipalName;
      let passField = ConnectorInfo.Password;
      return (
        <div>
          {this.dataRow("User Principal Name", this.inputDataField(userField, "UserPrincipalName", this.handleInputChange))}
          {this.dataRow("Password", this.inputDataField(passField, "Password", this.handleInputChange, false, "password"))}
        </div>
      );
    }
  }

  inputDataField = (val, key, onChangeFunc, readOnly = false, type = 'text') => {
    return (
      <input
        //style={inputStyle}
        style={{ width: '30%', display: 'inline-block', }}
        type={type}
        name={key}
        value={val}
        className="form-control"
        onChange={(e) => onChangeFunc(e, key)}
        disabled={readOnly}
      />
    )
  }

  dataRow = (label, field) => {
    return (
      <div className="form-group-wrapper"
        //style={divStyleObj}
        style={{ margin: '1%', }}
      >
        <label
          //style={labelStyleObj}
          style={{ width: '30%', display: 'inline-block', }}
        >{label}: </label>
        {field}
      </div >
    )
  }

  cardContent = () => {
    let { Loading, ConnectorInfo, FeatureEnabled, CurrentAdSystem } = this.state;

      if (ConnectorInfo && ConnectorInfo.length > 0) {
        //FeatureEnabled = 
        return (
          <div className="card-content"
            style={{
              paddingTop: "20px"
            }}
          >
            <small>* Please provide relevant information to enable / disable active directory</small>
            <div className="section-one">
              {/* {this.dataRow("Identity Provider", this.selectAdSystem(CurrentAdSystem))}
            {this.userCreds()}
            {this.dataRow("Enable Acitve Directory Feature", this.enableFeatureInput(FeatureEnabled))}
            {this.dataRow("Authentication Method", this.selectAuthenticationSystem(CurrentAdSystem))} */}
              {this.userCreds()}
              <button className="a btn"
                onClick={() => this.setState({ showConfirmationModal: true })}
              //disabled={ConnectorInfo != null && FeatureEnabled ? false : true}
              >
                {"Save"}
              </button>
            </div>
            {this.submissionMsg()}
          </div >
        );
      } else {
        return (
          <p style={{ textAlign: 'center', paddingTop: '15px' }}>No Available Data</p>
        )
      }
  }

  enableFeatureInput = (val) => {
    // params? (val, key, type = 'text')
    return (
      <input
        style={{
          display: 'inline-block',
        }}
        type="checkbox"
        checked={val}
        className='check-box'
        //onChange={(e) => this.handleInputChange(e, key, type)}
        onChange={(e) => {
          this.setState({ FeatureEnabled: e.target.checked });
          //sessionStorage.setItem("hasActiveDirectory", e.target.checked);
        }}
      />
    );
  }

  selectAdSystem = (val) => {
    // params? (val, key, options = [], readOnly = false)
    return (
      <div
        style={{
          display: 'inline-block',
        }}
      >
        <select className="form-control"
          onChange={async (e) => {
            let { value } = e.target;
            await this.setState({ CurrentAdSystem: value, ConnectorInfo: {} }); // clear out obj on change
            if (this.state.CurrentAdSystem == "Okta") {
              this.fetchOktaConnector();
            } else {
              this.fetchAzureAdConnector();
            }
            sessionStorage.setItem("AdSourceSystem", value);
          }}
          value={val}
        //disabled={readOnly === true ? true : false}
        >
          {/* <option key={"1"} value={"MicrosoftAD"}>Microsoft AD</option> */}
          <option key={"1"} value={"Okta"}>OKTA</option>
        </select>
      </div>
    )
  }

  selectAuthenticationSystem = (val) => {
    // params? (val, key, options = [], readOnly = false)
    return (
      <div
        style={{
          display: 'inline-block',
        }}
      >
        <select className="form-control"
          onChange={async (e) => {
            let { value } = e.target;
            await this.setState({ CurrentAdSystem: value, ConnectorInfo: {} }); // clear out obj on change
            if (this.state.CurrentAdSystem == "Okta") {
              this.fetchOktaConnector();
            } else {
              this.fetchAzureAdConnector();
            }
            sessionStorage.setItem("AdSourceSystem", value);
          }}
          value={val}
        //disabled={readOnly === true ? true : false}
        >
          <option key={"1"} value={"OIDC"}>OIDC</option>
          <option key={"2"} value={"SMAL"}>SAML</option>
        </select>
      </div>
    )
  }

  handleTransitionToMenu = () => {
    this.props.history.push(`/ad-menu/${sessionStorage.enterpriseId}`);
  }

  handleSubmit = async () => {
    let { ConnectorInfo, FeatureEnabled, CurrentAdSystem } = this.state;
    if (!FeatureEnabled) {
      sessionStorage.setItem("AdSourceSystem", CurrentAdSystem);
      sessionStorage.setItem("hasActiveDirectory", FeatureEnabled);
      this.updateFeatureStatus();
      await this.setState({ showConfirmationModal: false });
    } else {
      let path = "admin/UpdateAzureADConnector";
      if (CurrentAdSystem == "Okta") {
        path = "admin/UpdateOktaADConnector";
      }
      ConnectorInfo.EnterpriseId = parseInt(sessionStorage.enterpriseId);
      //console.log("DTO", ConnectorInfo);
      let response = await apiCalls.post(path, "POST", JSON.stringify(ConnectorInfo)).then(data => data).catch(err => console.error(err));
      if (response.ok) {
        //this.handleTransitionToMenu();
        sessionStorage.setItem("AdSourceSystem", CurrentAdSystem);
        sessionStorage.setItem("hasActiveDirectory", FeatureEnabled);
        await this.setState({
          showConfirmationModal: false,
          Submission: true,
          submitError: false,
        });
      } else {
        await this.setState({
          showConfirmationModal: false,
          Submission: true,
          submitError: true,
        });
      }
    }
  }

  updateFeatureStatus = async () => {
    let path = "admin/RemoveEnterpriseADSync";
    let dto = parseInt(sessionStorage.enterpriseId);
    //console.log("DTO", dto);
    let response = await apiCalls.post(path, "PUT", JSON.stringify(dto)).then(data => data).catch(err => console.error(err));
    if (response.ok) {
      //this.handleTransitionToMenu();
      await this.setState({
        Submission: true,
        submitError: false,
      });
    } else {
      await this.setState({
        Submission: true,
        submitError: true,
      });
    }
  }

  submissionMsg = () => {
    if (this.state.Submission) {
      if (this.state.submitError) {
        return <small style={{
          color: 'red',
          //fontSize: '50%',
          //marginRight: '20px'
        }}>{this.state.errorMsg && this.state.errorMsg != "" ? this.state.errorMsg : "Submission Failure: Changes were not processed!"}</small>
      } else {
        return <small style={{
          color: 'green',
          //fontSize: '50%',
          //marginRight: '20px'
        }}>Submission Sucess: Changes were processed!</small>
      }
    }
  };


  render() {
    return (
      <div>
        <Modal show={this.state.showConfirmationModal} onHide={this.handleClose} backdrop='static'>
          <Modal.Header>
            <Modal.Title>Are you sure you want to save changes?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <hr />
            <div className="btns">
              <span>
                <button type="button" className="btn" onClick={this.handleSubmit}>Yes</button>
                <button className="btn" onClick={() => this.setState({ showConfirmationModal: false })}>No</button>
              </span>
            </div>
          </Modal.Footer>
        </Modal>
        {this.cardContent()}
      </div>
    );
  };
}