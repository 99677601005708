import React from 'react';

export const dashboard = <svg width="12" height="12" viewBox="0 0 23 23" alt="dashboard icon"><path d="m96 176l80 0 0-80-80 0z m120 240l80 0 0-80-80 0z m-120 0l80 0 0-80-80 0z m0-120l80 0 0-80-80 0z m120 0l80 0 0-80-80 0z m120-200l0 80 80 0 0-80z m-120 80l80 0 0-80-80 0z m120 120l80 0 0-80-80 0z m0 120l80 0 0-80-80 0z" transform="scale(0.046875 0.046875)"></path></svg>

export const locations = <svg width="12" height="12" viewBox="0 0 23 23" alt="locations icon"><path d="m329 183c0-20-7-38-21-52c-15-14-32-21-52-21c-20 0-37 7-52 21c-14 14-21 32-21 52c0 20 7 37 21 52c15 14 32 21 52 21c20 0 37-7 52-21c14-15 21-32 21-52z m73 0c0 21-3 38-9 51l-104 221c-3 6-8 11-14 15c-6 4-12 5-19 5c-7 0-13-1-19-5c-6-4-11-9-14-15l-104-221c-6-13-9-30-9-51c0-41 14-75 43-104c28-28 63-42 103-42c40 0 75 14 103 42c29 29 43 63 43 104z" transform="scale(0.046875 0.046875)"></path></svg>

export const servicesAndUsers = <svg width="12" height="12" viewBox="0 0 23 23" alt="services & users icon"><path d="m448 192l-149 0l0 43l149 0l0 170l-149 0c0 24-22 43-22 43l171 0c23 0 43-19 43-43l0-170c0-24-20-43-43-43z m-235-128l-170 0c-24 0-43 19-43 43l0 298c0 24 19 43 43 43l170 0c24 0 43-19 43-43l0-298c0-24-19-43-43-43z m-64 363l-42 0l0-43l42 0z m64-64l-170 0l0-256l170 0z" transform="scale(0.046875 0.046875)"></path></svg>

export const devices = <svg width="12" height="12" viewBox="0 0 23 23" alt="devices icon"><path d="m457 354c0 5-1 12-3 20-2 9-4 15-6 20-4 10-15 20-35 30-17 10-35 15-53 15-5 0-10 0-15-1-5-1-10-2-16-4-6-1-11-3-14-4-3-1-8-3-16-6-7-3-12-4-14-5-18-7-35-14-50-24-24-15-49-35-75-61-26-26-47-51-62-76-9-14-17-31-23-50-1-1-3-6-6-14-2-7-4-13-5-16-1-3-3-7-5-13-1-6-2-12-3-17-1-4-1-9-1-15 0-17 5-35 14-53 11-19 21-31 31-35 4-2 11-4 19-6 9-1 15-2 20-2 3 0 5 0 6 0 4 2 9 9 16 22 2 4 5 9 8 16 4 6 7 12 10 18 3 5 6 10 9 15 1 1 2 3 5 7 3 4 5 8 6 10 1 3 2 6 2 8 0 4-3 9-8 15-5 5-11 11-18 15-6 5-12 10-17 16-6 5-9 9-9 13 0 1 1 4 2 6 1 3 2 5 2 6 1 1 2 4 4 7 2 3 3 5 4 5 14 26 31 49 49 67 19 19 41 36 67 50 1 0 3 1 6 3 3 2 5 4 7 4 1 1 3 2 6 3 2 1 4 1 6 1 3 0 8-2 13-8 5-5 11-11 15-18 5-6 10-12 16-17 6-6 10-8 14-8 3 0 6 0 8 2 3 1 7 3 11 6 4 2 6 4 7 5 4 3 10 6 15 9 5 3 11 6 18 10 7 3 12 6 16 8 13 7 20 12 21 15 1 2 1 4 1 6z" transform="scale(0.046875 0.046875)"></path></svg>

export const trunking = <svg width="12" height="12" viewBox="0 0 23 23" alt="trunking icon"><path d="m416 192l-320 0c-17 0-32 16-32 32l0 64c0 16 16 32 32 32l320 0c16 0 32-16 32-32l0-64c0-16-16-32-32-32z m-288 96l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m96 64l-320 0c-17 0-32 16-32 32l0 64c0 16 16 32 32 32l320 0c16 0 32-16 32-32l0-64c0-16-16-32-32-32z m-288 96l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m96-416l-320 0c-17 0-32 16-32 32l0 64c0 16 16 32 32 32l320 0c16 0 32-16 32-32l0-64c0-16-16-32-32-32z m-288 96l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m96-32l-32 0 0-32 32 0z" transform="scale(0.046875 0.046875)"></path></svg>

export const enterpriseSettings = <svg width="12" height="12" viewBox="0 0 23 23" alt="enterprise settings icon"><path d="m460 378c24 11 37 31 37 57c0 17-6 32-18 44c-12 12-27 18-44 18c-17 0-31-6-43-18c-12-12-18-27-18-44c0-26 12-46 37-57c0 0 0-59 0-59c0-26-13-39-39-39c0 0-51 0-51 0c-16 0-29-2-40-7c0 0 0 105 0 105c24 11 36 31 36 57c0 17-6 32-17 44c-12 12-27 18-44 18c-17 0-32-6-44-18c-11-12-17-27-17-44c0-26 12-46 36-57c0 0 0-105 0-105c-10 5-23 7-38 7c0 0-52 0-52 0c-11 0-20 3-27 9c-6 7-10 12-11 17c-1 5-2 9-2 13c0 0 0 59 0 59c25 11 37 31 37 57c0 17-6 32-18 44c-12 12-26 18-43 18c-17 0-32-6-44-18c-12-12-18-27-18-44c0-26 13-46 37-57c0 0 0-59 0-59c0-21 8-41 22-60c15-18 37-28 67-28c0 0 52 0 52 0c25 0 38-8 38-26c0 0 0-72 0-72c-24-11-36-30-36-56c0-17 6-32 17-44c12-12 27-18 44-18c17 0 32 6 44 18c11 12 17 27 17 44c0 26-12 45-36 56c0 0 0 72 0 72c0 18 13 26 40 26c0 0 51 0 51 0c29 0 51 10 66 28c14 19 22 39 22 60c0 0 0 59 0 59m-347 57c0-10-4-19-11-25c-7-7-16-11-25-11c-10 0-18 4-25 11c-7 6-10 15-10 25c0 10 3 18 10 25c7 7 15 10 25 10c9 0 18-3 25-10c7-7 11-15 11-25m108-358c0 9 4 18 10 24c7 7 15 11 25 11c10 0 18-4 25-11c7-6 11-15 11-24c0-10-4-19-11-26c-7-7-15-10-25-10c-10 0-18 3-25 10c-6 7-10 16-10 26m71 358c0-10-4-19-11-25c-7-7-15-11-25-11c-10 0-18 4-25 11c-6 6-10 15-10 25c0 10 4 18 10 25c7 7 15 10 25 10c10 0 18-3 25-10c7-7 11-15 11-25m143 35c10 0 18-3 25-10c7-7 11-15 11-25c0-10-4-19-11-25c-7-7-15-11-25-11c-9 0-18 4-24 11c-7 6-11 15-11 25c0 10 4 18 11 25c6 7 15 10 24 10" transform="scale(0.046875 0.046875)"></path></svg>

export const contactCenter = <svg width="12" height="12" viewBox="0 0 25 25" alt="contact center icon"><path d="m461 51c14 0 26 5 36 15c10 10 15 22 15 36c0 0 0 308 0 308c0 13-5 25-15 35c-10 11-22 16-36 16c0 0-410 0-410 0c-13 0-25-5-36-16c-10-10-15-22-15-35c0 0 0-308 0-308c0-14 5-26 15-36c11-10 23-15 36-15c0 0 410 0 410 0m0 359c0 0 0-308 0-308c0 0-410 0-410 0c0 0 0 308 0 308c0 0 410 0 410 0m-231-101c0 0 0 46 0 46c0 0-128 0-128 0c0 0 0-46 0-46c0 0 128 0 128 0m0-77c0 0 0 47 0 47c0 0-128 0-128 0c0 0 0-47 0-47c0 0 128 0 128 0m0-76c0 0 0 46 0 46c0 0-128 0-128 0c0 0 0-46 0-46c0 0 128 0 128 0m178 163c0 0 2 36 2 36c0 0-128 0-128 0c0-24 1-36 3-36c28-7 43-18 43-33c0-6-5-15-14-29c-9-14-14-29-14-45c0-38 15-56 46-56c31 0 46 18 46 56c0 16-5 31-14 45c-10 14-14 23-14 29c0 7 3 13 10 18c7 6 15 9 22 11c0 0 12 4 12 4" transform="scale(0.046875 0.046875)"></path></svg>

export const billing = <svg width="12" height="12" viewBox="0 0 23 23" alt="billing icon"><path d="m469 51c12 0 22 4 30 13c9 8 13 18 13 30l0 324c0 12-4 22-13 30c-8 9-18 13-30 13l-426 0c-12 0-22-4-30-13c-9-8-13-18-13-30l0-324c0-12 4-22 13-30c8-9 18-13 30-13z m-426 34c-3 0-5 1-6 3c-2 2-3 4-3 6l0 60l444 0l0-60c0-2-1-4-3-6c-1-2-3-3-6-3z m426 342c3 0 5-1 6-3c2-2 3-4 3-6l0-162l-444 0l0 162c0 2 1 4 3 6c1 2 3 3 6 3z m-401-34l0-35l69 0l0 35z m103 0l0-35l102 0l0 35z" transform="scale(0.046875 0.046875)"></path></svg>

export const star = <svg tabIndex="0" width="15" height="15" viewBox="0 0 24 24" alt="star icon"><path d="m480 192l-157-20-67-140-67 140-157 20 115 104-29 152 138-74 139 74-30-152z" transform="scale(0.046875 0.046875)"></path></svg>

export const historyBtn = <svg width="14" height="14" viewBox="0 0 24 24"><path d="m475 256c0 30-5 58-17 85-12 27-27 51-47 70-19 20-43 35-70 47-27 12-55 17-85 17-33 0-64-6-93-20-30-14-55-34-76-59-1-2-2-4-2-6 0-3 1-4 3-6l39-39c2-2 4-3 7-3 3 0 5 2 7 3 13 19 31 33 51 42 20 10 41 15 64 15 20 0 39-4 57-11 18-8 33-18 46-32 14-13 24-28 32-46 7-18 11-37 11-57 0-20-4-39-11-57-8-18-18-33-32-46-13-14-28-24-46-32-18-7-37-11-57-11-19 0-37 3-54 10-17 7-32 16-45 29l39 39c6 6 7 13 4 20-4 8-9 11-17 11l-128 0c-5 0-9-1-13-5-4-4-5-8-5-13l0-128c0-8 3-13 11-17 7-3 14-2 20 4l37 37c20-19 44-34 70-45 26-10 53-15 81-15 30 0 58 5 85 17 27 12 51 27 70 47 20 19 35 43 47 70 12 27 17 55 17 85z m-182-82l0 128c0 2-1 5-3 6-2 2-4 3-7 3l-91 0c-3 0-5-1-7-3-1-1-2-4-2-6l0-19c0-2 1-4 2-6 2-2 4-3 7-3l64 0 0-100c0-3 1-5 3-7 1-2 3-2 6-2l18 0c3 0 5 0 7 2 2 2 3 4 3 7z" transform="scale(0.046875 0.046875)"></path></svg>

export const ticketing = <svg width="12" height="12" viewBox="0 0 24 24"><path d="M15.137 3.945c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.667 2.712-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.195-10.594-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm3 20c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6zm5.778-11.679c.18.721.05 1.446-.304 2.035l.97.584c.504-.838.688-1.869.433-2.892-.255-1.024-.9-1.848-1.739-2.351l-.582.97c.589.355 1.043.934 1.222 1.654zm.396-4.346l-.597.995c1.023.616 1.812 1.623 2.125 2.874.311 1.251.085 2.51-.53 3.534l.994.598c.536-.892.835-1.926.835-3-.001-1.98-1.01-3.909-2.827-5.001zm-16.73 2.692l-.582-.97c-.839.504-1.484 1.327-1.739 2.351-.255 1.023-.071 2.053.433 2.892l.97-.584c-.354-.588-.484-1.314-.304-2.035.179-.72.633-1.299 1.222-1.654zm-4.444 2.308c0 1.074.299 2.108.835 3l.994-.598c-.615-1.024-.841-2.283-.53-3.534.312-1.251 1.101-2.258 2.124-2.873l-.597-.995c-1.817 1.092-2.826 3.021-2.826 5z" /></svg>

export const documents = <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12" height="12" viewBox="0 0 128 128"><path d="M 24 1 C 16.8 1 11 6.8 11 14 L 11 91 C 11 92.7 12.3 94 14 94 C 15.7 94 17 92.7 17 91 L 17 14 C 17 10.1 20.1 7 24 7 L 103 7 C 101.7 9 101 11.4 101 14 L 101 114 C 101 117.9 97.9 121 94 121 C 90.1 121 87 117.9 87 114 L 87 104 C 87 102.3 85.7 101 84 101 L 4 101 C 2.3 101 1 102.3 1 104 L 1 114 C 1 121.2 6.8 127 14 127 L 94 127 C 101.2 127 107 121.2 107 114 L 107 14 C 107 10.1 110.1 7 114 7 C 117.9 7 121 10.1 121 14 L 121 21 L 114 21 C 112.3 21 111 22.3 111 24 C 111 25.7 112.3 27 114 27 L 124 27 C 125.7 27 127 25.7 127 24 L 127 14 C 127 6.8 121.2 1 114 1 L 24 1 z M 47 21 C 45.3 21 44 22.3 44 24 C 44 25.7 45.3 27 47 27 L 71 27 C 72.7 27 74 25.7 74 24 C 74 22.3 72.7 21 71 21 L 47 21 z M 34 51 C 32.3 51 31 52.3 31 54 C 31 55.7 32.3 57 34 57 L 84 57 C 85.7 57 87 55.7 87 54 C 87 52.3 85.7 51 84 51 L 34 51 z M 34.199219 66 C 32.499219 66 31.199219 67.3 31.199219 69 C 31.199219 70.7 32.499219 72 34.199219 72 L 69.199219 72 C 70.899219 72 72.199219 70.7 72.199219 69 C 72.199219 67.3 70.899219 66 69.199219 66 L 34.199219 66 z M 84 66 A 3 3 0 0 0 81 69 A 3 3 0 0 0 84 72 A 3 3 0 0 0 87 69 A 3 3 0 0 0 84 66 z M 7 107 L 81 107 L 81 114 C 81 116.6 81.8 119 83 121 L 14 121 C 10.1 121 7 117.9 7 114 L 7 107 z"></path></svg>

export const addressBook = <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12" height="12" viewBox="0 0 23 23"><path xmlns="http://www.w3.org/2000/svg" d="M21,2H6C4.896,2,4,2.896,4,4v3H2v2h2v2H2v2h2v2H2v2h2v3c0,1.104,0.896,2,2,2h15c0.553,0,1-0.447,1-1V3 C22,2.447,21.553,2,21,2z M13,4.999c1.648,0,3,1.351,3,3C16,9.647,14.648,11,13,11c-1.647,0-3-1.353-3-3.001 C10,6.35,11.353,4.999,13,4.999z M19,18H7v-0.75c0-2.219,2.705-4.5,6-4.5s6,2.281,6,4.5V18z" /></svg>

export const entTrunk = <svg class="bi bi-cloud-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" d="M3.5 13a3.5 3.5 0 1 1 .59-6.95 5.002 5.002 0 1 1 9.804 1.98A2.5 2.5 0 0 1 13.5 13h-10z" />
</svg>

