import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { fetchData, post } from '../../../requests/api_calls';
import format from 'string-format';
import { DataLoadingSpinner } from '../../../components/spinners';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as config from '../../../requests/config';
import * as EmailValidator from 'email-validator';

const ServiceNumber = "ServiceNumber",
      PBXUserID = "PBXUserID",
      EnterpriseId = "EnterpriseId",
      UserId = "UserId",
      IMAPID  = "IMAPID",
      DialingFirstName = "DialingFirstName",
      DialingLastName = "DialingLastName",
      FirstName = "FirstName ",
      LastName = "LastName ",
      Title = "Title",
      Department = "Department",
      Language= "Language",
      Location="Location",
      Timezone= "Timezone",
      NetworkClassOfService = "NetworkClassofService",
      AlternatePhone = "AlternatePhone",
      Email = "Email",
      Address1  = "Address1",
      Address2 = "Address2",
      City = "City",
      State = "State",
      Country  = "Country",
      Zip  = "Zip";

class EditProfileModal extends Component {

  initialState=(props)=>{
    return {
      visible: false,
      showModal: false,
      loading: true, 
      loading_error: "",
      saving_loading: false,
      saving_show_success: false,
      saving_error_message: "",
      saving_show_error: false,
      currentUser: props.CurrentUserProfile,
      admin: props.isAdmin,
      GroupName: props.GroupName,
      ServiceNumber: props.ServiceNumber,
      hasE911: props.HasE911,
      isIPTrunking: sessionStorage.isSelectedServiceIpTrunking && sessionStorage.isSelectedServiceIpTrunking.toString() === 'true' ? true : false,
      profile:{
        PBXUserID: props.PBXUserID,
        EnterpriseId: props.ParentAccountNumber,        
        UserId: props.PBXUserID,
        IMAPID: "",
        CallingLineFirstName:  "",
        CallingLineLastName: "",
        DialingFirstName: "",
        DialingLastName: "",
        YahooId: "",
        FirstName: "",
        LastName: "",
        Title: "",
        Department: {
          ServiceProviderId: "",
          GroupId: "",
          Name: ""
        },
        Language: {
          ID: "",
          Name: ""
        },
        Timezone: {
          ID: "",
          Name: ""
        },
        NetworkClassOfService:  {
          ID: "",
          Name: ""
        },
        AlternatePhone: "",
        Email: "",
        Address1: "",
        Address2: "",
        City: "",
        State: {
          ID: "",
          Name: ""
        },
        Country: "",
        Zip: "Zip",
        States:[],
        Languages:[],
        Timezones:[],
        Departments:[],
        NetworkClassOfServices:[]
      },
      valid: true
    }
  }

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.updateByProps = this.updateByProps.bind(this);
    this.state = this.initialState(props);
  }

  handleClose() {
    let state = {...this.state};
    state.showModal = false;
    this.setState(state);
  }

  handleShow() {
    let state = {...this.state};
    state.showModal = true;
    state.loading = true;
    state.saving_show_error = false;
    state.saving_loading= false;
    state.saving_show_success= false;
    state.saving_error_message= "";
    this.setState(state);
    this.fetchProfileInfo();
  }

  fetchProfileInfo=()=>{
    var apiUrl = ''
    if(this.state.currentUser && this.state.admin){
      apiUrl = "admin/GetAdminProfileEdit";
      this.fetchAdminProfileInfo(apiUrl);
    }
    else{
      apiUrl = format("usr/GetUserProfileEdit?ServiceNumber={ServiceNumber}&PBXUserID={PBXUserID}", this.props);
      this.fetchUserProfileInfo(apiUrl);
    }
  }

  fetchUserProfileInfo=(apiPath, errorMsg)=>{
    fetchData(apiPath).then((data) => {
      let state = {...this.state};
      state.loading = false;
      if (data) {
        state.admin = false;
        state.loading_error = "";
        state.profile = data;
        let blankState = { ID: "", Name: ""};
        //TODO: create a separate path for Broadsoft v Other Users so that properties are loaded properly
        //if (this.props.CurrentUserProfile && this.props.CurrentUserProfile.PBXUserID && this.props.CurrentUserProfile.PBXUserID !== ''){ //TODO: else if (user's service is broadsoft)
          state.profile.States.splice(0,0, blankState);         

          state.profile.CallingLineFirstName = state.profile.CallingLineFirstName || state.profile.FirstName; //in BWOCI this is how these fields are set
          state.profile.CallingLineLastName = state.profile.CallingLineLastName || state.profile.LastName;

          if(!state.profile.Department.ServiceProviderId){
            if(state.profile.Departments.length > 0){
              state.profile.Department.ServiceProviderId = state.profile.Departments[0].ServiceProviderId;
              state.profile.Department.GroupId = state.profile.Departments[0].GroupId;
              state.profile.Department.Name = state.profile.Departments[0].Name;
            }
          }
          if(!state.profile.Language.ID){
            if(state.profile.Languages.length > 0){
              state.profile.Language.ID = state.profile.Languages[0].ID;
              state.profile.Language.Name = state.profile.Languages[0].Name;
            }
              
          }
          if(!state.profile.Timezone.ID){
            if(state.profile.Timezones.length > 0){
              state.profile.Timezone.ID = state.profile.Timezones[0].ID;
              state.profile.Timezone.Name = state.profile.Timezones[0].Name;
            }
          }
          if(!state.profile.State.ID){
            if(state.profile.States.length > 0){
              state.profile.State.ID = state.profile.States[0].ID;
              state.profile.State.Name = state.profile.States[0].Name;
            }
          }
        //}
      }
      else{
        state.loading_error = "Failed to Load"
      }
      this.setState(state);
    }).catch((message)=>{
      this.setState({...{loading:false, fetch_error: message.toString()}});
    });
  }

  fetchAdminProfileInfo=(apiPath, errorMsg)=>{
    fetchData(apiPath).then((data) => {
      let state = {...this.state};
      state.loading = false;
      if (data) {
        state.admin = true;
        state.loading_error = "";
        state.profile = data;
      }
      else{
        state.loading_error = "Failed to Load"
      }
      this.setState(state);
    }).catch((message)=>{
      this.setState({...{loading:false, fetch_error: message.toString()}});
    });
  }

  getSaveBody = (profile) => {
    return {
      ServiceNumber: this.state.ServiceNumber,
      PBXUserID: profile.PBXUserID,
      CustomerProductId: 0,
      FirstName: profile.FirstName,
      LastName: profile.LastName,
      Title: profile.Title,
      DepartmentName: profile.Department.Name,
      Language: profile.Language.Name,
      TimeZone: profile.Timezone.ID,
      NCOS: profile.NetworkClassOfService.ID,
      Email: profile.Email,
      Location: profile.Location,
      Address1: profile.Address1,
      Address2: profile.Address2,
      City: profile.City,
      State: profile.State.ID,
      Zip: profile.Zip,
      IMAP_ID: profile.IMAPID,
      YahooId: profile.YahooId,
      Mobile: profile.Mobile,
      Pager: profile.Pager,
      CallingLineFirstName: profile.CallingLineFirstName,
      CallingLineLastName: profile.CallingLineLastName,
      DialingFirstName: profile.DialingFirstName,
      DialingLastName: profile.DialingLastName,
      Country: profile.Country
    };
  }

  handleSaveNew=()=> {
    let state = {...this.state};
    state.saving_loading = true;
    state.saving_show_success = false;
    this.setState(state);
    let body = this.state.currentUser && this.state.admin? JSON.stringify(state.profile) : JSON.stringify(this.getSaveBody(state.profile));
		let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + config.storedAccessToken(),
      'X-Policy': config.storedXPolicy()
    };

    let api = '';
    if (this.state.currentUser && this.state.admin && !this.state.ServiceId) 
      api = "admin/SetAdminProfileEdit"
    else
      api = "usr/UpdateUserProfile";
    post(api,"POST", body, headers)
		.then(response =>{
      let state = {...this.state};
			if(response.ok){
        state.saving_show_success = true;
        state.saving_loading = false;
        state.saving_show_error = false;
        this.props.updateProfilePage(this.state.profile.FirstName, 
          this.state.profile.LastName, 
          this.state.profile.Title ? this.state.profile.Title : '',
          this.state.profile.Department ? this.state.profile.Department.Name : '',
          this.state.profile.Email,
          this.state.profile.Language ? this.state.profile.Language.Name : ''
        );
			}
			else{
        state.saving_loading = false;
        state.saving_show_error = true;
				state.saving_error_message = "Failed Save";
      }
      this.setState(state);
		})
		.catch((message)=>{
      console.log(message);
      let state = {...this.state};
      state.saving_loading = false;
      state.saving_show_error = true;
      state.saving_error_message = "Failed Save";
      this.setState(state);
		});
  }


  handleSave=()=>{
    let state = {...this.state};
    state.saving_loading = true;
    state.saving_show_success = false;
    this.setState(state);
    let body = JSON.stringify(state.profile);
		let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + config.storedAccessToken(),
      'X-Policy': config.storedXPolicy()
    };

    let api = this.state.currentUser && this.state.admin? "admin/SetAdminProfileEdit": "usr/SetUserProfileEdit";
    post(api,"POST", body, headers)
		.then(response =>{
      let state = {...this.state};
			if(response.ok){
        state.saving_show_success = true;
        state.saving_loading = false;
        state.saving_show_error = false;
			}
			else{
        state.saving_loading = false;
        state.saving_show_error = true;
				state.saving_error_message = "Failed Save";
      }
      this.setState(state);
		})
		.catch((message)=>{
      console.log(message);
      let state = {...this.state};
      state.saving_loading = false;
      state.saving_show_error = true;
      state.saving_error_message = "Failed Save";
      this.setState(state);
		});
  }

  handleOnChange=(field, event)=>{

    let state = {...this.state};
    switch(field){
      case FirstName:
        state.profile.FirstName = event.target.value;
        state.profile.CallingLineFirstName = state.profile.FirstName;
        break;
      case LastName:
        state.profile.LastName = event.target.value;
        state.profile.CallingLineLastName = state.profile.LastName;
        break;
      case Department:
        let department = event.target.value.split("|");
        state.profile.Department.ServiceProviderId = department[0];
        state.profile.Department.GroupId = department[1];
        state.profile.Department.Name = department[2];
        break;
      case Language:
        state.profile.Language.ID = event.target.value;
        state.profile.Language.Name = state.profile.Languages.filter(e=> e.ID === state.profile.Language.ID)[0].Name;
        break;
      case Timezone:
        state.profile.Timezone.ID = event.target.value;
        state.profile.Timezone.Name = state.profile.Timezones.filter(e=> e.ID === state.profile.Timezone.ID)[0].Name;
        break;
      case NetworkClassOfService:
        state.profile.NetworkClassOfService.ID = event.target.value;
        break;
      case DialingFirstName:
      case DialingLastName:
      case Title:
      case AlternatePhone:
      case Email:
      case Location:
      case Address1:
      case Address2:
      case City:
      case Zip:
      case Country:
        state.profile[field] = event.target.value;
        break;
      case State:
        state.profile.State.ID = event.target.value;
        state.profile.State.Name = state.profile.States.filter(e=> e.ID === state.profile.State.ID)[0].Name;
        break;
    }

    if(state.profile.Email && !EmailValidator.validate(state.profile.Email))
      state.valid = false;
    else
      state.valid = true;
      
    this.setState(state);
  }

  componentWillReceiveProps(nextProps){
    this.updateByProps(nextProps);
  }

  componentDidMount() {
    this.updateByProps(this.props);
  }
  
  updateByProps=(props)=>{
    let state = {...this.state};    
    state.admin = props.isAdmin;
    state.profile = props.CurrentUserProfile;
    if(props.CurrentUserProfile && props.isAdmin){      
      state.visible = true;
      this.setState(state);
    }
    else{
      let state = {...this.state};
      state.visible = false;
      this.setState(state);
      let ServiceNumber = '';
      let PBXUserID = '';
      if ((!props.ServiceNumber || props.ServiceNumber === '') && (!props.PBXUserID || props.PBXUserID === ''))
      {
        console.log('ServiceNumber: ' + state.ServiceNumber)
        console.log('PBXUserID: ' + state.PBXUserID)
      } else {
        ServiceNumber = props.ServiceNumber
        PBXUserID = props.PBXUserID;
      }

      let editAvailableApi = format('usr/ProfileEditAvailable?ServiceNumber={ServiceNumber}&PBXUserID={PBXUserID}',props);
      fetchData(editAvailableApi).then((data) => {
        let state = {...this.state};
        if (data) { 
          state.visible = true;
          this.setState(state);
        }
      }).catch((message)=>{
        console.log(message)
      });
    }
    
  }  

  getModalBodyContent=()=>{
    let loading =  this.state.loading;
    if(loading){
      return ( <DataLoadingSpinner className='load spinner'/>);
    }
    let loading_error =  this.state.loading_error;
    if(loading_error){
      return (<div className="inline inline-fail text-center">{loading_error}</div>);
    }

    if(this.props.CurrentUserProfile && this.state.admin){
      return this.getAdminForm();
    }
    else if (this.props.CurrentUserProfile && this.props.CurrentUserProfile.PBXUserId && this.props.CurrentUserProfile.PBXUserId !== '' && this.state.isIPTrunking === false){ //TODO: else if (user's service is broadsoft)
      return this.getBroadsoftUserForm();
    } else
      return this.getUserForm(); 
  }

  getBroadsoftUserForm=()=>{
    let profile = this.state.profile;
    let headerValues = this.props.GetHeaderValues();
    let blankState = { ID: "", Name: ""};
    let stateDropDownOptions = profile.States.map((x,index)=>{
      return {id:x.ID, name:x.Name};
    });
    stateDropDownOptions.splice(0,0, blankState);
    return (
      <div>
        <p>Profile allows you to view and maintain your profile information. The information filled in specifies your primary phone number, extension, and device that are used for handling calls. Filling in the additional information section allows your mobile phone, pager, and other information to be visible to other group members in the group phone list. Some of this information can only be modified by your administrator.</p>
        <hr />
        <p><b>Enterprise ID:</b> {headerValues.EnterpriseId}<br /><b>Group:</b> {headerValues.GroupId}  <br /><b>User ID:</b>  {profile.PBXUserID ? profile.PBXUserID : profile.UserId} <br /> <b>IMAP ID:</b> {profile.ImapID}</p>
        <p><b>Calling Line ID First Name:</b> {profile.CallingLineFirstName} <br /><b>Calling Line ID Last Name:</b> {profile.CallingLineLastName}</p>
        <hr />
        <div className="halves">
          <div className="half">
            <ProfileTextField id={FirstName} label="First Name" value={profile.FirstName} onChange={this.handleOnChange} enabled={true} />
          </div>
          <div className="half">
            <ProfileTextField id={LastName} label="Last Name" value={profile.LastName} onChange={this.handleOnChange} enabled={true} />
          </div>
        </div>
        <div className="halves">
          <div className="half">
            <ProfileTextField id={DialingFirstName} label="Name Dialing First Name" value={profile.DialingFirstName} onChange={this.handleOnChange} enabled={true} />
          </div>
          <div className="half">
            <ProfileTextField id={DialingLastName} label="Name Dialing Last Name" value={profile.DialingLastName} onChange={this.handleOnChange} enabled={true} />
          </div>
        </div>
        <div className="halves">
          <div className="half">
            <ProfileTextField id={Title} label="Title" value={profile.Title} onChange={this.handleOnChange} enabled={true} />
          </div>
          <div className="half">
            
          </div>
        </div>
        <hr />
        <div className="halves">
          <div className="half">
          <ProfileDropdownField id={Department} label="Department" value={profile.Department.ServiceProviderId+"|"+profile.Department.GroupId+"|"+profile.Department.Name} 
              enabled={true}
              values={
                profile.Departments.map(x=> { return {id: x.ServiceProviderId +"|"+ x.GroupId + "|" + x.Name, name: x.Name}} )
              }
              onChange={this.handleOnChange}
            />
          </div>
          <div className="half">
            <ProfileDropdownField id={Language} label="Language"  value={profile.Language.ID}
              enabled={true} 
              values={
                profile.Languages.map((x,index)=>{
                  return {id:x.ID, name:x.Name};
                })
              }
              onChange={this.handleOnChange}
            />
            </div>
        </div>
        <div className="halves">
          <div className="half">
            <ProfileDropdownField id={Timezone} label="Time Zone"  value={profile.Timezone.ID}
              enabled={true} 
              values={
                profile.Timezones.map((x,index)=>{
                  return {id:x.ID, name:x.Name};
                })
              }
              onChange={this.handleOnChange}
            />
          </div>
          <div className="half">
            <ProfileDropdownField id={NetworkClassOfService} label="Network Class of Service"  value={profile.NetworkClassOfService.ID} 
              enabled={true}
              values={
                profile.NetworkClassOfServices.map((x,index)=>{
                  return {id:x.ID, name:x.Name};
                })
              }
              onChange={this.handleOnChange}
            />
          </div>
        </div>
        <hr />
        <div className="halves">
          <div className="half">
            <ProfileTextField id={Email} label="Email" value={profile.Email} onChange={this.handleOnChange} enabled={true} />
            {this.validateEmail(profile.Email)}
          </div>
          <div className="half">
          </div>
        </div>
        <ProfileTextField id={Location} label="Location" value={profile.Location} onChange={this.handleOnChange} enabled={true} />

        <ProfileTextField id={Address1} label="Address" value={profile.Address1} onChange={this.handleOnChange} enabled={true} />
        <div className="form-group">
          <input type="type" className="form-control" defaultValue={profile.Address2} 
            onChange={e=> this.handleOnChange(Address2,e)}/>
        </div>
        <div className="halves">
          <div className="half">
            <ProfileTextField id={City} label="City" value={profile.City} onChange={this.handleOnChange} enabled={true} />
          </div>
          <div className="half">
            <ProfileDropdownField id={State} label="State"  value={profile.State.ID} 
              values={ stateDropDownOptions }
              onChange={this.handleOnChange}
              enabled={true}
            />
          </div>
        </div>
        <div className="halves">
          <div className="half">
            <ProfileTextField id={Zip} label="Zip" value={profile.Zip} onChange={this.handleOnChange} enabled={true} />
          </div>
          <div className="half">
            <ProfileTextField id={Country} label="Country" value={profile.Country} onChange={this.handleOnChange} enabled={true} />
          </div>
        </div>
      </div>);
  }
  
  getAdminForm=()=>{
    let profile = this.state.profile;
    let headerValues = this.props.GetHeaderValues();
    let blankState = { ID: "", Name: ""};
    let stateDropDownOptions = profile.States.map((x,index)=>{
      return {id:x.ID, name:x.Name};
    });
    stateDropDownOptions.splice(0,0, blankState);
    return (
      <div>
        <p>Profile allows you to view and maintain your profile information. The information filled in specifies your primary phone number, extension, and device that are used for handling calls. Filling in the additional information section allows your mobile phone, pager, and other information to be visible to other group members in the group phone list. Some of this information can only be modified by your administrator.</p>
        <hr />
        <p><b>Enterprise ID:</b> {headerValues.EnterpriseId}
          <br /><b>Group:</b>  {headerValues.GroupId}
        </p> 
        <hr />
        
        <div className="halves">
          <div className="half">
            <ProfileTextField id={Title} label="Title" value={profile.Title} onChange={this.handleOnChange} enabled={true} />
          </div>
          <div className="half">
            <ProfileTextField id={Email} label="Email" value={profile.Email} onChange={this.handleOnChange} enabled={true} />
          </div>
        </div>
        <hr />

        <ProfileTextField id={Address1} label="Address" value={profile.Address1} onChange={this.handleOnChange} enabled={true} />
        <div className="form-group">
          <input type="type" className="form-control" defaultValue={profile.Address2} 
            onChange={e=> this.handleOnChange(Address2,e)}/>
        </div>
        <div className="halves">
          <div className="half">
            <ProfileTextField id={City} label="City" value={profile.City} onChange={this.handleOnChange} enabled={true} />
          </div>
          <div className="half">
            <label>State</label>
            <select className="form-control"  
              value={profile.State.ID}
              enabled={true}
              onChange={x=>{ this.handleOnChange(State, x) }}>
              <option value=""></option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DC">D.C.</option>
              <option value="DE">Delaware</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="Ohio">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
        </div>
        <div className="halves">
          <div className="half">
            <ProfileTextField id={Zip} label="Zip" value={profile.Zip} onChange={this.handleOnChange} enabled={true} />
          </div>
          <div className="half">
            {/* <ProfileTextField id={Country} label="Country" value={profile.Country} onChange={this.handleOnChange} /> */}
          </div>
        </div>
      </div>);
  }

  getUserForm = () => {
    let profile = this.state.profile;
    let headerValues = this.props.GetHeaderValues();
    let blankState = { ID: "", Name: ""};
    let stateDropDownOptions = profile.States.map((x,index)=>{
      return {id:x.ID, name:x.Name};
    });
    stateDropDownOptions.splice(0,0, blankState);
    return (
      <div>
        <p>Profile allows you to view and maintain your profile information. The information filled in specifies your primary phone number, extension, and device that are used for handling calls. Filling in the additional information section allows your mobile phone, pager, and other information to be visible to other group members in the group phone list. Some of this information can only be modified by your administrator.</p>
        <hr />
        <p><b>Enterprise ID:</b> {headerValues.EnterpriseId}<br /><b>Group:</b> {headerValues.GroupId}  <br /><b>User ID:</b>  {profile.PBXUserID ? profile.PBXUserID : profile.UserId}</p>
        <hr />
        <div className="halves">
          <div className="half">
            <ProfileTextField id={FirstName} label="First Name" value={profile.FirstName} onChange={this.handleOnChange} enabled={true} />
          </div>
          <div className="half">
            <ProfileTextField id={LastName} label="Last Name" value={profile.LastName} onChange={this.handleOnChange} enabled={true} />
          </div>
        </div>        
        <div className="halves">
          <div className="half">
            <ProfileTextField id={Title} label="Title" value={profile.Title} onChange={this.handleOnChange} enabled={true} />
          </div>
          <div className="half">  
            <ProfileTextField id={Email} label="Email" value={profile.Email} onChange={this.handleOnChange} enabled={true} />
            {this.validateEmail(profile.Email)}          
          </div>
        </div>
        <hr />
        <div className="halves">
          <div className="half">
            <ProfileDropdownField id={Department} label="Department" value={profile.Department.ServiceProviderId+"|"+profile.Department.GroupId+"|"+profile.Department.Name} 
              values={
                profile.Departments.map(x=> { return {id: x.ServiceProviderId +"|"+ x.GroupId + "|" + x.Name, name: x.Name}} )
              }
              onChange={this.handleOnChange}
              enabled={true}
            />
          </div>
          <div className="half">
            <ProfileDropdownField id={Language} label="Language"  value={profile.Language.ID} disabled={true}
              values={
                profile.Languages.map((x,index)=>{
                  return {id:x.ID, name:x.Name};
                })
              }
              onChange={this.handleOnChange}
              enabled={true}
            />
            </div>
        </div>
        <div className="halves">
          <div className="half">
            <ProfileDropdownField id={Timezone} label="Time Zone"  value={profile.Timezone.ID} disabled={true}
              values={
                profile.Timezones.map((x,index)=>{
                  return {id:x.ID, name:x.Name};
                })
              }
              onChange={this.handleOnChange}
              enabled={true}
            />
          </div>
          <div className="half">
            {/* <ProfileDropdownField id={NetworkClassOfService} label="Network Class of Service"  value={profile.NetworkClassOfService.ID} disabled={true}
              values={
                profile.NetworkClassOfServices.map((x,index)=>{
                  return {id:x.ID, name:x.Name};
                })
              }
              onChange={this.handleOnChange}
            /> */}
          </div>
        </div>
        
        <hr />
        {/* <div style={{display: this.state.hasE911 && this.state.hasE911 === true ? 'block' : 'none'}}> */}
        <div style={{display:'none'}}>
          <h6>E911 Address Information - <span style={{color:'red', fontSize:'x-small'}}>Read-Only</span></h6>
          <ProfileTextField id={Address1} label="Address" value={profile.Address1} enabled={false} />
          <div className="form-group">
            <input type="type" className="form-control" defaultValue={profile.Address2} disabled={true}/>
          </div>
          <div className="halves">
            <div className="half">
              <ProfileTextField id={City} label="City" value={profile.City} enabled={false} />
            </div>
            <div className="half">
              <ProfileDropdownField id={State} label="State"  value={profile.State.ID} 
                values={ stateDropDownOptions }
                onChange={this.handleOnChange}
                enabled={false}
              />
            </div>
          </div>
          <div className="halves">
            <div className="half">
              <ProfileTextField id={Zip} label="Zip" value={profile.Zip} enabled={false} />
            </div>
            <div className="half">
              {/* <ProfileTextField id={Country} label="Country" value={profile.Country} enabled={false} /> */}
            </div>
          </div>
        </div>        
      </div>);
  }

  validateEmail=(email)=>{
    if(email && !EmailValidator.validate(email.trim()))
      return (<div className="inline inline-fail">'{email.trim()}' Not a valid email</div>);
  }

  render() {
    if(!this.state.visible)
      return (<span></span>);

    return (
      <span>
        <a className="btn" onClick={this.handleShow}>Edit Profile</a>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.getModalBodyContent()}
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <SaveButton disabled={this.state.loading || this.state.saving_loading || !this.state.valid } handleSave={this.handleSaveNew} />
                <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
              <SavingMessageControl 
                showSuccess={this.state.saving_show_success} 
                showLoading={this.state.saving_loading} 
                showError={this.state.saving_show_error}
                SuccessMessage="Success"
                SuccessTransitionClass="vm-setting-success"
                ErrorMessage={this.state.saving_error_message}
                />
          </Modal.Footer>
        </Modal>
      </span>
    );
  }

};

class SavingMessageControl extends Component {
  render() {
      let successMessage = "";
      if(this.props.showSuccess){
        successMessage =  (<ReactCSSTransitionGroup
          transitionName={this.props.SuccessTransitionClass}
          transitionAppear={true}
          transitionAppearTimeout={4000}
          transitionEnter={true}
          transitionEnterTimeout={4000}
          transitionLeave={false}
          >
          <div className="inline-success text-center">{this.props.SuccessMessage}</div>
        </ReactCSSTransitionGroup>)
      }

      let loadingMessage = "";
      if(this.props.showLoading){
        loadingMessage = (<DataLoadingSpinner className='load spinner'/>)
      }
      
      let errorMessage = "";
      if(this.props.showError){
        errorMessage = (<div className="inline inline-fail text-center">{this.props.ErrorMessage}</div>)
      }

      return (
        <div>
          {successMessage}
          {loadingMessage}
          {errorMessage}
        </div>
      );
    }
};

class SaveButton extends Component {
  render() {
    if(this.props.disabled)
      return (<Button key="1" className="btn" enable={false}>Save</Button>);
    else
      return (<Button key="2" className="btn" click={this.props.handleSave}>Save</Button>);
  }
};

class ProfileTextField extends Component {
  render() {
    return (
      <div className="form-group">
          <label>{this.props.label}</label>
          <input maxlength={this.props.label === "Name Dialing First Name" || this.props.label === "Name Dialing Last Name" ? "30" : 'none'} type="type" className="form-control" disabled={this.props.enabled === true ? "" : "disabled"} value={(this.props.value ? this.props.value : "") } 
          onChange={e=> { if (this.props.enabled === true) this.props.onChange(this.props.id,e) } }/>
        </div>
    );
  }
};

class ProfileDropdownField extends Component {
  render() {
    return (
      <div className="form-group">
          <label>{this.props.label}</label>
          <select className="form-control" value={(this.props.value? this.props.value:"") }
                disabled={this.props.enabled === true ? "" : "disabled"}
                onChange={e=> this.props.onChange(this.props.id,e)}>
                {
                  this.props.values.map((x, index)=>{
                    return <option key={index} value={x.id}>{x.name}</option>
                  })
                }
              </select>
        </div>
    );
  }
};

ProfileTextField.propTypes = {
  label: PropTypes.string.isRequired,
  textvalue: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func
}

SavingMessageControl.propTypes = {
  showSuccess: PropTypes.bool.isRequired,
  showLoading: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  return {
    ServiceNumber: state.reducer.ServiceNumber, 
    PBXUserID: state.reducer.PBXUserID,
    isIpTrunking: state.reducer.isSelectedServiceIpTrunking
  }
}

export default connect(mapStateToProps)(EditProfileModal);