import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as config from "../requests/config";
import * as helper from "../scripts/helper";
import * as session from "../scripts/session";
import { parse } from "query-string";
import momentumLogoWhite from "../images/momentum_logo_white.svg";
import { DataLoadingSpinner } from "../components/spinners";
import "@progress/kendo-theme-default/dist/all.css";
import "../css/bootstrap.css";
import "../css/app.css";
import "../css/spinner.css";

class Login extends Component {
  constructor() {
    super();
    this.loadingLogo = null;
    this.state = {
      loading: true,
      Logo: momentumLogoWhite,
      auth: null,
      componentMounted: false,
      loginHint: ''
    };
  }

  componentWillMount() {
    const domain = helper.getDomain();
    helper.setSiteTitleFromDomain();
    if (helper.IsWholesaleDomain(domain)) {
      // this.getCustomizations(domain);      
    } else {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    let loginHint = '';
    if (helper.urlIncludes("username=")) {
      loginHint = "&login_hint=" + parse(window.location.search).username;
      sessionStorage.setItem('loginUserName', parse(window.location.search).username);
      // strip params to initiate login from /session with no params (to match Azure whitelist)
      // this.props.history.push("/session");
    }
    if (localStorage.mspLoginData) {
      this.setState({
        loading: false
      })
    }
    this.setState({ loginHint: loginHint, componentMounted: true });
  }

  render() {
    const domain = helper.getDomain();

    // if (localStorage.mspLoginData) {
    //   this.setState({
    //     loading: false
    //   })
    // }

    if (this.state.loading || this.state.componentMounted !== true) {
      return null;
    }
    if (this.props.loginError === "Login_In_Progress: Error during login call - login is already in progress.") {
      return (
        <div className="redirect container">
          <DataLoadingSpinner className="redirect spinner" />
        </div>
      )
    }

    if (this.props.loginError) {
      return (
        <div className="login-error container">
          <div className="summary">
            <div className="error">
              <h3>{this.props.loginError}</h3>
              <div>{this.props.loginErrorDescription}</div>
            </div>
            <a
              href="/session"
              className="btn btn-block btn-primary"
              onClick={session.logout}
              alt="restart login button"
            >
              Restart Login
            </a>
          </div>
        </div>
      );
    }
    else if (this.props.redirecting) {
      session.transitionTimeout();
      // const loadingLogo = helper.IsWholesaleDomain(domain)
      //   ? this.loadingLogo
      //   : momentumLogoWhite;
      // const imageAltText = helper.IsWholesaleDomain(domain)
      //   ? "Logo"
      //   : "Momentum Telecom Logo";

      return (
        <div className="redirect container">
          {/* <img src={loadingLogo} className="App-logo" alt={imageAltText} /> */}
          <DataLoadingSpinner className="redirect spinner" />
        </div>
      );
    }
    else if (helper.atLoginPath()) {
      const { auth: customAuth } = this.state;
      customAuth ? config.loginUser(this.props.mspLoginData, customAuth) : config.loginUser(this.props.mspLoginData);
      return <div />
    }
    else {
      helper.redirectToLogin();
      return <div />
    }
  }
}

const mapStateToProps = state => {
  return {
    redirecting: helper.toBoolean(state.reducer.redirecting),
    restartSession: helper.toBoolean(state.reducer.restartSession),
    loginError: state.reducer.loginError,
    loginErrorDescription: state.reducer.loginErrorDescription,
    mspLoginData: state.reducer.mspLoginData
  };
};

export default connect(mapStateToProps)(withRouter(Login));